import { CollectionTable, FormModal, RecordLink } from "@sciencecorp/helix-components";
import React, { useMemo } from "react";
import { Link, Spinner, Tag } from "@chakra-ui/react";
import { CommittedSpendBreakdownData, useGetCommittedSpendBreakdown } from "../../../../api/budget";
import { MoneyText } from "../../../MoneyText";
import { humanize, titleize } from "inflection";
import { useGetBudgetGroupCommittedSpendBreakdownByExpenditure } from "../../../../api/budget_groups";
import { statusTagColor } from "../../../shared/TimelineEventText";

type CommittedSpendBreakdownModalProps = {
  isOpen: boolean;
  onClose: () => void;
  budgetId?: number;
  budgetGroupId?: number;
};

export const CommittedSpendBreakdownModal = ({
  isOpen,
  onClose,
  budgetId,
  budgetGroupId,
}: CommittedSpendBreakdownModalProps) => {
  const { data: committedSpendBreakdown, isLoading: committedSpendBreakdownLoading } =
    useGetCommittedSpendBreakdown(budgetId ?? null);
  const {
    data: budgetGroupCommittedSpendBreakdown,
    isLoading: budgetGroupCommittedSpendBreakdownLoading,
  } = useGetBudgetGroupCommittedSpendBreakdownByExpenditure(budgetGroupId ?? 0);

  const dataToDisplay = budgetId ? committedSpendBreakdown : budgetGroupCommittedSpendBreakdown;
  const isLoading = budgetId
    ? committedSpendBreakdownLoading
    : budgetGroupCommittedSpendBreakdownLoading;

  const columns = useMemo(
    () => [
      {
        label: "Type",
        render: (item: CommittedSpendBreakdownData) => {
          return (
            <RecordLink
              identifier={item.id}
              type={item.model_type}
              as={Link}
              link={item.app_href}
            />
          );
        },
      },
      {
        label: "Status",
        render: (item: CommittedSpendBreakdownData) => {
          return (
            <Tag colorScheme={statusTagColor(item.status)}>{titleize(humanize(item.status))}</Tag>
          );
        },
      },
      {
        label: "Total Committed",
        render: (item: CommittedSpendBreakdownData) => {
          return (
            <MoneyText
              fontWeight="bold"
              money={item.committed_spend}
              formatOptions={{ compact: "never" }}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <FormModal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      title="Committed Spend Breakdown"
      handleSubmit={onClose}
      closeOnOverlayClick={false}
      submitButtonColorSchema="teal"
      submitButtonTitle="Close">
      {dataToDisplay && !isLoading ? (
        <CollectionTable items={dataToDisplay} columns={columns} />
      ) : (
        <Spinner />
      )}
    </FormModal>
  );
};
