import { AddIcon, ChevronRightIcon, WarningTwoIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Heading,
  Icon,
  Spinner,
  IconButton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  Tooltip,
  VStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  CollectionTable,
  Column,
  ConfirmationButton,
  EditableSelect,
  EditableText,
  EmptyState,
  RecordLink,
} from "@sciencecorp/helix-components";
import { humanize, inflect } from "inflection";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FiArchive, FiEye, FiEyeOff } from "react-icons/fi";
import { TbLetterC, TbLetterM } from "react-icons/tb";
import {
  useBudgetItems,
  useBudgetItemsSummary,
  useGetBudgetExpenditureSpendByModel,
  useGetBudgetSummary,
} from "../../api/budget";
import {
  useGetBudgetGroup,
  useGetBudgetGroupSummary,
  useGetBudgetGroupSummaryStats,
} from "../../api/budget_groups";
import {
  BudgetItemShowData,
  useArchiveBudgetItemsQuery,
  useUnarchiveBudgetItemQuery,
  useUpdateBudgetItemQuery,
} from "../../api/budget_items";
import { AddExpenditureModal } from "../Teams/Team/components/AddBudgetItemModal";
import { ConsolidateModal, useConsolidateModal } from "./Modals/ConsolidateModal";
import { MoveBudgetItemModal, useMoveBudgetItemModal } from "./Modals/MoveBudgetItemModal";
import { SubTeamBudgetItems } from "./SubTeamExpandableBudget";
import { Link } from "react-router-dom";
import { useGeneralLedgerCodeOptions } from "../../api/options";
import { useCurrentUserQuery, userHasRole } from "../../api/user";
import { ManageFundingAllocation } from "../Finance/Budgets/BudgetGroup/components/ManageFundingAllocation";
import { BudgetActivityLinearBar } from "../Finance/Budgets/components/BudgetActivityLinearBar";
import { MoneyText } from "../MoneyText";
import { ReviewBudgetItemModal } from "./Modals/ReviewBudgetItemModal";
import { Money } from "../../helpers/Money";
import { FaUndo } from "react-icons/fa";
import { Select } from "chakra-react-select";
import { TimeRange } from "../Finance/Budgets/BudgetGroup";

const hasArchivedItems = (items) => {
  return items.some((item) => item.archived_at);
};

export const BudgetItemList = ({ budgetItemsQuery, columns, showArchived }) => {
  if (budgetItemsQuery.isSuccess) {
    const filteredItems = showArchived
      ? budgetItemsQuery.data.results
      : budgetItemsQuery.data.results.filter((item) => !item.archived_at);

    if (filteredItems.length === 0) {
      return (
        <EmptyState size="sm" title={`No items added. Create a budget and add items to start`} />
      );
    }

    return <CollectionTable items={filteredItems} columns={columns} order={{ id: "asc" }} />;
  } else if (budgetItemsQuery.isLoading) {
    return <Text>Loading...</Text>;
  } else {
    return <Text>Error loading budget items</Text>;
  }
};
interface BudgetExpenseCardProps {
  budgetId: number;
  editable: boolean;
  teamIds?: number[];
  projectId?: number;
  location?: "team" | "project" | "user" | "capitalEquipment";
  userId?: number;
  budgetableName: string;
}
export const SpendingCard = ({
  budgetId,
  editable,
  location,
  budgetableName,
}: BudgetExpenseCardProps) => {
  const timeFilter = "allTime";
  const currentUserQuery = useCurrentUserQuery();
  const isFinanceAdmin = userHasRole(currentUserQuery, "finance_admin") || false;
  const isAccountant = userHasRole(currentUserQuery, "accountant") || false;
  const canApprove = isFinanceAdmin || isAccountant;
  const [timePeriodSchedule, setTimePeriodSchedule] = useState<TimeRange | null>(null);
  const hasSetInitialTimePeriod = useRef(false);
  const budgetItemsQuery = useBudgetItems({
    budget_id: budgetId,
    budget_group_schedule_id: timePeriodSchedule?.id,
  });

  const budgetQuery = useGetBudgetSummary(budgetId, timePeriodSchedule?.id);
  const { data: budgetGroup, isLoading: budgetGroupLoading } = useGetBudgetGroup(
    budgetQuery?.data?.budget_group_id
  );
  const textColor = useColorModeValue("gray.500", "gray.200");
  const bgColor = useColorModeValue("gray.50", "gray.700");

  const { data: budgetItemsSummary, isLoading: budgetItemsSummaryLoading } = useBudgetItemsSummary(
    budgetId,
    timePeriodSchedule?.id
  );
  const timeRangeOptions = useMemo(
    () =>
      budgetGroup?.time_range_options.map((option) => ({
        label: `${option.start_date.toFormat("LLL dd yyyy")} - ${option.end_date.toFormat(
          "LLL dd yyyy"
        )}`,
        value: option.id,
        start_date: option.start_date,
        end_date: option.end_date,
      })) || [],
    [budgetGroup]
  );
  const { data: budgetGroupStats, isLoading: budgetGroupStatsLoading } =
    useGetBudgetGroupSummaryStats(budgetGroup?.id, timePeriodSchedule?.id);

  const { data: budgetExpenditureSpendByModel, isLoading: budgetExpenditureSpendByModelLoading } =
    useGetBudgetExpenditureSpendByModel(budgetId, timePeriodSchedule?.id);

  const { data: budgetGroupSummaryQuery, isLoading: budgetGroupSummaryQueryLoading } =
    useGetBudgetGroupSummary(budgetGroup?.id, timePeriodSchedule?.id);

  const showBudgetItemTab = budgetItemsSummary?.summary.funding_amount.isPos();
  const { mutate: updateBudgetItem } = useUpdateBudgetItemQuery(budgetId);
  const { mutateAsync: archiveBudgetItems } = useArchiveBudgetItemsQuery();
  const [selectedBudgetItemIds, setSelectedBudgetItemIds] = useState<number[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showArchived, setShowArchived] = useState(false);
  const { mutateAsync: unarchiveBudgetItem, isLoading: unarchiveBudgetItemLoading } =
    useUnarchiveBudgetItemQuery();

  const isDisabled = !editable;

  const canAddItems =
    (location === "user" && editable) || (location !== "user" && location !== "capitalEquipment");
  const consolidateModalProps = useConsolidateModal(
    selectedBudgetItemIds,
    setSelectedBudgetItemIds
  );
  const moveModalProps = useMoveBudgetItemModal(selectedBudgetItemIds, setSelectedBudgetItemIds);

  const handleSelectItem = (itemId) => {
    setSelectedBudgetItemIds((prev) =>
      prev.includes(itemId) ? prev.filter((id) => id !== itemId) : [...prev, itemId]
    );
  };

  const [mostRecentTimePeriod, setMostRecentTimePeriod] = useState<TimeRange | null>(null);
  useEffect(() => {
    if (
      !budgetGroupLoading &&
      budgetGroup?.time_range_options &&
      !hasSetInitialTimePeriod.current
    ) {
      const currentPeriod =
        budgetGroup.time_range_options.find(
          (option) => option.id === budgetGroup.budget_group_schedule?.id
        ) || budgetGroup.time_range_options[0];

      setTimePeriodSchedule(currentPeriod || null);
      setMostRecentTimePeriod(currentPeriod);

      hasSetInitialTimePeriod.current = true;
    }
  }, [budgetGroupLoading, budgetGroup]);

  const glCodeOptions = useGeneralLedgerCodeOptions();
  const columns: Column<BudgetItemShowData>[] = [
    {
      label: "",
      weight: 0.25,
      render: (budgetItem: BudgetItemShowData) =>
        !budgetItem.archived_at && editable ? (
          <Checkbox
            isDisabled={isDisabled || budgetItem.status === "pending"}
            colorScheme="teal"
            isChecked={selectedBudgetItemIds.includes(budgetItem.id)}
            onChange={() => handleSelectItem(budgetItem.id)}
          />
        ) : null,
    },
    {
      label: "Item",
      weight: 2,
      render: (budgetItem: BudgetItemShowData) => {
        if (budgetItem.status === "pending") {
          return (
            <Tooltip label="Pending review by finance admin">
              <HStack>
                <RecordLink
                  buttonVariant="outline"
                  colorScheme="orange"
                  maxWidth="18ch"
                  disabled={true}
                  type=""
                  identifier={budgetItem.item_name}
                />
                <WarningTwoIcon color="orange.500" />
              </HStack>
            </Tooltip>
          );
        } else {
          const nameDisabled = budgetItem.status === "approved" && !isFinanceAdmin;
          return (
            <VStack align="start">
              <EditableText
                disabled={!!budgetItem.archived_at || nameDisabled}
                preview={
                  <RecordLink
                    maxWidth={["20ch", "15ch", "20ch", "30ch"]}
                    link={`/budgets/${budgetItem.budget_id}/budget_item/${budgetItem.id}`}
                    type=""
                    identifier={budgetItem.item_name}
                  />
                }
                defaultValue={budgetItem.item_name}
                isLoading={budgetItemsQuery.isLoading}
                onSubmit={(value) =>
                  updateBudgetItem({ id: budgetItem.id, item_name: value?.toString() })
                }
              />
              {budgetItem.archived_at && (
                <HStack>
                  <Tag colorScheme="red">Archived</Tag>
                  {editable && (
                    <Tooltip label="Unarchive Item">
                      <IconButton
                        icon={<FaUndo />}
                        size="xs"
                        onClick={() => unarchiveBudgetItem(budgetItem.id)}
                        variant="ghost"
                        colorScheme="gray"
                        isLoading={unarchiveBudgetItemLoading}
                        aria-label="Unarchive Item"
                      />
                    </Tooltip>
                  )}
                </HStack>
              )}
            </VStack>
          );
        }
      },
    },
    {
      label: "GL Code",
      weight: 1.5,
      render: (budgetItem: BudgetItemShowData) => {
        if (budgetItem.status === "pending") {
          return <Text>-</Text>;
        } else {
          return (
            <EditableSelect
              options={glCodeOptions}
              selectedValue={budgetItem.general_ledger_code?.id.toString()}
              onSubmit={(value) => {
                if (value)
                  updateBudgetItem({
                    id: budgetItem.id,
                    general_ledger_code_id: Number(value),
                  });
              }}
            />
          );
        }
      },
    },
    {
      label: "Allocated",
      render: (budgetItem: BudgetItemShowData) => {
        if (budgetItem.status !== "pending") {
          return (
            <MoneyText
              fontWeight="bold"
              money={budgetItem.allocated_amount}
              formatOptions={{ compact: "never" }}
            />
          );
        } else {
          return "-";
        }
      },
    },
    {
      label: "Spent",
      render: (budgetItem: BudgetItemShowData) => {
        if (budgetItem.status !== "pending") {
          return (
            <MoneyText
              fontWeight="bold"
              money={budgetItem.summary?.spent_amount}
              formatOptions={{ compact: "never" }}
            />
          );
        } else {
          return "-";
        }
      },
    },
    {
      label: "Allocation Remaining",
      hideOnBreakpoint: "md",
      render: (budgetItem: BudgetItemShowData) => {
        if (budgetItem.status !== "pending") {
          const amount = budgetItem.allocated_amount.subtract(
            budgetItem.summary?.spent_amount || Money.zero(budgetItem.allocated_amount.currency)
          );
          return (
            <MoneyText fontWeight="bold" money={amount} formatOptions={{ compact: "never" }} />
          );
        } else {
          return "-";
        }
      },
    },
    {
      label: "Committed",
      hideOnBreakpoint: "md",
      render: (budgetItem: BudgetItemShowData) => {
        const fontColor =
          (budgetItem.summary?.committed_amount?.cents.toNumber() || 0) > 0 ? "yellow.500" : "auto";
        if (budgetItem.status !== "pending") {
          return (
            <MoneyText
              color={fontColor}
              money={budgetItem.summary?.committed_amount}
              formatOptions={{ compact: "never" }}
            />
          );
        } else return "-";
      },
    },
    {
      label: "Pending Approval",
      hideOnBreakpoint: "md",
      render: (budgetItem: BudgetItemShowData) => {
        const fontColor =
          (budgetItem.summary?.pending_approval_amount?.cents.toNumber() || 0) > 0
            ? "red.500"
            : "auto";
        if (budgetItem.status !== "pending") {
          return (
            <HStack>
              <MoneyText
                color={fontColor}
                money={budgetItem.summary?.pending_approval_amount}
                formatOptions={{ compact: "never" }}
              />
              {(budgetItem.summary?.pending_approval_records_count || 0) > 0 && (
                <Badge colorScheme="red">
                  {budgetItem.summary?.pending_approval_records_count || 0}
                </Badge>
              )}
            </HStack>
          );
        } else return <ReviewBudgetItemModal budgetItem={budgetItem} canApprove={canApprove} />;
      },
    },
  ];

  return (
    <Box>
      <VStack
        spacing={4}
        w={"100%"}
        border="1px"
        borderColor={useColorModeValue("gray.200", "gray.600")}
        p={6}
        borderRadius="md">
        <Stack
          w={"100%"}
          direction={["column", "column", "row", "row"]}
          justifyContent={"space-between"}>
          <Heading size="md">Spending</Heading>
          <Flex direction={["column", "column", "row", "row"]} gap={2}>
            <Box zIndex={2}>
              <Select
                useBasicStyles
                options={timeRangeOptions}
                value={timeRangeOptions.find((option) => option.value === timePeriodSchedule?.id)}
                placeholder="Select a time period"
                onChange={(option) => {
                  if (option) {
                    setTimePeriodSchedule({
                      id: option.value,
                      start_date: option.start_date,
                      end_date: option.end_date,
                    });
                  }
                }}
              />
            </Box>
            {budgetQuery?.data && (editable || isFinanceAdmin) && (
              <ManageFundingAllocation
                fundingAmount={budgetQuery.data.allocated_amount}
                currency={budgetQuery.data.currency}
                data={budgetItemsQuery.data?.results || []}
                location="items"
              />
            )}
            {canAddItems && (
              <Button
                colorScheme={"teal"}
                leftIcon={<AddIcon />}
                isDisabled={!budgetQuery.isSuccess || !editable}
                onClick={onOpen}>
                Add Item
              </Button>
            )}
          </Flex>
        </Stack>

        {budgetItemsSummaryLoading ||
        budgetGroupStatsLoading ||
        budgetExpenditureSpendByModelLoading ||
        budgetGroupSummaryQueryLoading ? (
          <Spinner />
        ) : (
          <Tabs colorScheme="teal" w="100%" borderRadius={"md"} borderWidth={"1px"}>
            <HStack
              w={"100%"}
              alignItems={"center"}
              direction="row"
              p={2}
              bgColor={bgColor}
              justifyContent={"space-between"}>
              <TabList>
                {showBudgetItemTab && (
                  <Tab>
                    {humanize(budgetableName)} {humanize(location || "")} Allocation
                  </Tab>
                )}
                {budgetGroup && <Tab>{budgetGroup.name} Budget Group Funding</Tab>}
              </TabList>
              {budgetGroup && (
                <Link to={`/finance/budgets/budget_group/${budgetGroup.id}`}>
                  <ChevronRightIcon m={4} />
                </Link>
              )}
            </HStack>

            <TabPanels>
              <TabPanel>
                <Box p={4}>
                  <BudgetActivityLinearBar
                    summary={budgetItemsSummary?.summary}
                    spendByModel={budgetExpenditureSpendByModel}
                    showingPreviousPeriod={timePeriodSchedule?.id !== mostRecentTimePeriod?.id}
                    budgetId={budgetId}
                    endDate={budgetGroup?.budget_group_schedule?.end_date}
                  />
                </Box>
              </TabPanel>
              <TabPanel>
                <Box p={4}>
                  <BudgetActivityLinearBar
                    summary={budgetGroupSummaryQuery}
                    spendByModel={budgetGroupStats?.expenditure_spend_summary}
                    showingPreviousPeriod={timePeriodSchedule?.id !== mostRecentTimePeriod?.id}
                    budgetGroupId={budgetGroup?.id}
                    endDate={budgetGroup?.budget_group_schedule?.end_date}
                  />
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
        {editable && (
          <Stack
            w={"100%"}
            direction={{ base: "column", md: "row" }}
            alignItems={"center"}
            p={4}
            spacing={4}
            borderRadius={"md"}
            borderWidth={"1px"}
            justifyContent={"space-between"}>
            <Stack
              w={"100%"}
              direction={{ base: "column", md: "row" }}
              alignItems={"center"}
              p={2}
              spacing={4}
              borderRightWidth={["0", "1px"]}
              borderBottomWidth={["1px", "0"]}>
              <Box>
                {selectedBudgetItemIds.length > 0 ? (
                  <Text fontWeight="semibold">
                    {selectedBudgetItemIds.length} {inflect("Item", selectedBudgetItemIds.length)}{" "}
                    Selected
                  </Text>
                ) : (
                  <Text color={textColor}>Select a Budget Item</Text>
                )}
              </Box>
              {location !== "user" && location !== "capitalEquipment" && (
                <>
                  <Tooltip
                    label={!selectedBudgetItemIds.length ? "Select items to consolidate" : ""}>
                    <Button
                      leftIcon={<TbLetterC />}
                      variant="outline"
                      colorScheme={selectedBudgetItemIds.length ? "orange" : "gray"}
                      color={selectedBudgetItemIds.length ? "orange.500" : "gray.500"}
                      size="sm"
                      isDisabled={selectedBudgetItemIds.length === 0}
                      onClick={consolidateModalProps.onOpen}>
                      Consolidate
                    </Button>
                  </Tooltip>
                  <Tooltip label={!selectedBudgetItemIds.length ? "Select items to move" : ""}>
                    <Button
                      leftIcon={<TbLetterM />}
                      isLoading={moveModalProps.isLoading}
                      variant={"outline"}
                      colorScheme={selectedBudgetItemIds.length ? "teal" : "gray"}
                      color={selectedBudgetItemIds.length ? "teal.500" : "gray.500"}
                      size="sm"
                      isDisabled={selectedBudgetItemIds.length === 0}
                      onClick={moveModalProps.onOpen}>
                      Move
                    </Button>
                  </Tooltip>
                </>
              )}
              {location !== "capitalEquipment" && (
                <Tooltip label={!selectedBudgetItemIds.length ? "Select items to archive" : ""}>
                  <Box display="inline-block">
                    <ConfirmationButton
                      aria-label="archive-budgetItem-record"
                      leftIcon={<FiArchive />}
                      label="Archive"
                      confirmationButtonLabel="Archive"
                      isDisabled={selectedBudgetItemIds.length === 0}
                      children="Are you sure you want to archive this budget item? You will not be able to undo this action."
                      onConfirm={() => {
                        archiveBudgetItems({
                          budget_item_ids: selectedBudgetItemIds,
                          archived_at: DateTime.now().toISO(),
                        })
                          .then(() => setSelectedBudgetItemIds([]))
                          .catch((e) => console.error(e));
                      }}
                      variant="Button"
                      buttonVariant="outline"
                      colorScheme={selectedBudgetItemIds.length ? "red" : "gray"}
                      color={selectedBudgetItemIds.length ? "red.500" : "gray.500"}
                      size="sm"
                    />
                  </Box>
                </Tooltip>
              )}
            </Stack>
            <Button
              leftIcon={<Icon as={showArchived ? FiEyeOff : FiEye} />}
              variant="ghost"
              size="sm"
              colorScheme="gray"
              isDisabled={!hasArchivedItems(budgetItemsQuery.data?.results || [])}
              onClick={() => setShowArchived(!showArchived)}>
              {showArchived ? "Hide archived" : "Show archived"}
            </Button>
          </Stack>
        )}

        <BudgetItemList
          budgetItemsQuery={budgetItemsQuery}
          columns={columns}
          showArchived={showArchived}
        />
        {editable && budgetQuery?.data?.sub_team_budgets?.length && timePeriodSchedule ? (
          <Flex
            direction="column"
            w="100%"
            bg={useColorModeValue("gray.50", "gray.700")}
            border="1px"
            p={2}
            borderColor="chakra-border-color"
            borderRadius="md">
            {budgetQuery.data?.sub_team_budgets?.map((budgetWithSummary) => (
              <SubTeamBudgetItems
                key={budgetWithSummary.budget.id}
                selectedBudgetItemIds={selectedBudgetItemIds}
                handleSelectItem={handleSelectItem}
                budgetId={budgetWithSummary.budget.id}
                parentTeamBudget={budgetQuery.data?.id}
                editable={editable}
                timeFilter={timeFilter}
                timePeriodSchedule={timePeriodSchedule}
              />
            ))}
          </Flex>
        ) : null}
      </VStack>

      <AddExpenditureModal isOpen={isOpen} onClose={onClose} budgetId={budgetId} />
      <ConsolidateModal
        isOpen={consolidateModalProps.isOpen}
        onClose={consolidateModalProps.onClose}
        onSubmit={consolidateModalProps.onSubmit}
        control={consolidateModalProps.control}
        setValue={consolidateModalProps.setValue}
        isLoading={consolidateModalProps.isLoading}
      />

      <MoveBudgetItemModal
        isOpen={moveModalProps.isOpen}
        onClose={moveModalProps.onClose}
        onSubmit={moveModalProps.onSubmit}
        control={moveModalProps.control}
        setValue={moveModalProps.setValue}
        isLoading={moveModalProps.isLoading}
      />
    </Box>
  );
};
