import { RecordLink } from "@sciencecorp/helix-components";
import { DateTime } from "luxon";
import React from "react";
import { useSearchPurchasePayments } from "../../../../../../api/purchase_payment";
import { MoneyText } from "../../../../../MoneyText";
import { buildTabComponent } from "../../../../../TabbedTable";
import { Alert } from "@chakra-ui/react";

export const PaymentsTab = buildTabComponent({
  label: "Purchase Payments",
  columns: [
    {
      label: "Paid at",
      orderOptions: { orderKey: "paid_at" },
      render: (purchasePayment) =>
        purchasePayment.paid_at ? purchasePayment.paid_at.toFormat("LLL dd, yyyy") : "-",
    },
    {
      label: "Record",
      orderOptions: { orderKey: "vendor_name" },
      render: (purchasePayment) => (
        <RecordLink
          type={purchasePayment.payable.type}
          maxWidth="15ch"
          identifier={purchasePayment.payable.id}
          link={purchasePayment.payable.app_href}
        />
      ),
    },
    {
      label: "Vendor",
      orderOptions: { orderKey: "vendor_name" },
      render: (purchasePayment) =>
        purchasePayment.vendor ? (
          <RecordLink
            type=""
            maxWidth="15ch"
            identifier={purchasePayment.vendor.name}
            link={`/services/vendors/${purchasePayment.vendor.id}`}
          />
        ) : (
          <Alert status="warning" variant="subtle">
            No Vendor
          </Alert>
        ),
    },
    {
      label: "Cost",
      orderOptions: { orderKey: "amount_cents" },
      render: (purchasePayment) => (
        <MoneyText money={purchasePayment.amount} formatOptions={{ compact: "never" }} />
      ),
    },
  ],
  query: useSearchPurchasePayments,
});
