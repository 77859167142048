import React from "react";
import {
  Box,
  Text,
  HStack,
  Button,
  Avatar,
  Tag,
  useDisclosure,
  Link,
  Tooltip,
} from "@chakra-ui/react";
import { HrWarning } from "../warning";
import {
  buildFacets,
  Collection,
  Column,
  Facets,
  Header,
  RecordLink,
  SplitPage,
  useCollection,
} from "@sciencecorp/helix-components";
import { SmallAddIcon } from "@chakra-ui/icons";
import { NewPollModal } from "./NewPollModal";
import { PollData, useSearchPolls } from "../../../api/polls";
import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";
import { Link as RouterLink } from "react-router-dom";
import { pollStatusColors } from "./PollDetailsPage";
import { humanize } from "inflection";
import { BackgroundReportButton } from "../../shared/BackgroundReportButton";

export const Polls = () => {
  const { pagination, order, onPagination, onOrder, facets, onFacets, filters } = useCollection();

  const { search, debouncedSearch } = useDebouncedSearch();
  const { data, isLoading, error } = useSearchPolls({
    term: search || "*",
    aggs: ["category", "status", "created_by"],
    bodyOptions: {},
    filters,
    pagination,
    order,
  });

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);

  const columns: Column<PollData>[] = [
    {
      label: "Poll Id",
      render: (item) => <RecordLink identifier={item.id} link={`/hr/polls/${item.id}`} />,
    },
    {
      label: "Question",
      render: (item) => (
        <Tooltip label={item.form.active_template?.fields[0]?.label || "-"}>
          <Text noOfLines={1} flexWrap="wrap" maxWidth="300px">
            {item.form.active_template?.fields[0]?.label || "-"}
          </Text>
        </Tooltip>
      ),
    },
    {
      label: "Start Date",
      render: (item) => (
        <Text>{item.start_date ? new Date(item.start_date).toLocaleDateString() : "-"}</Text>
      ),
    },
    {
      label: "End Date",
      render: (item) => (
        <Text>{item.end_date ? new Date(item.end_date).toLocaleDateString() : "-"}</Text>
      ),
    },
    {
      label: "Created By",
      render: (item) => (
        <Link as={RouterLink} to={`/users/${item.user.id}`}>
          <HStack>
            <Avatar size="xs" name={item.user.name} src={item.user.picture_uri} />
            <Text fontSize="sm">{item.user.name}</Text>
          </HStack>
        </Link>
      ),
    },
    {
      label: "Status",
      render: (item) => (
        <Tag colorScheme={pollStatusColors(item.status)}>{humanize(item.status)}</Tag>
      ),
    },
  ];

  const {
    isOpen: isOpenCreatePoll,
    onOpen: onOpenCreatePoll,
    onClose: onCloseCreatePoll,
  } = useDisclosure();

  return (
    <>
      <NewPollModal isOpen={isOpenCreatePoll} onClose={onCloseCreatePoll} />
      <HrWarning />
      <Header
        title="Polls"
        crumbs={[{ label: "HR", url: "/hr" }]}
        crumbsColor="teal"
        actions={[
          // <BackgroundReportButton
          //   fontWeight="medium"
          //   taskName="Reports::PollResponses"
          //   args={[]}
          //   buttonName="Poll Responses"
          //   title="poll-responses"
          //   loadingText="Generating Report..."
          //   p={3}
          //   pl={6}
          // />,
          <Button colorScheme="teal" onClick={onOpenCreatePoll}>
            <SmallAddIcon mr={2} />
            Create Poll
          </Button>,
        ]}
      />
      <SplitPage
        sidebarWidth="max-content"
        sidebarWidthXL="max-content"
        sidebar={
          <>
            <Box display={["flex", "flex", "none"]} alignSelf="end">
              <Facets variant="button" facets={populatedFacets} onChange={onFacets} background />
            </Box>
            <Box display={["none", "none", "inline"]} width="max-content" alignSelf={"start"}>
              <Facets facets={populatedFacets} onChange={onFacets} background />
            </Box>
          </>
        }
        main={
          <Box
            border="1px solid"
            borderColor="chakra-border-color"
            borderRadius="md"
            p={4}
            width="100%">
            <Collection
              columns={columns}
              items={data?.results || []}
              isLoading={isLoading}
              pagination={data?.pagination || pagination}
              onPagination={onPagination}
              onOrder={onOrder}
              order={order}
            />
          </Box>
        }
      />
    </>
  );
};
