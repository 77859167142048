import _ from "lodash";
import { z } from "zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { api } from ".";
import { zodParse } from "./zodParse";
import { dateTimeSchema } from "../helpers/dateTime";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";

const BASE_URL = "background_tasks";

export const backgroundTaskSchema = z.object({
  id: z.number(),
  task_name: z.string(),
  args: z.array(z.any()),
  status: z.enum(["pending", "running", "completed", "failed"]),
  error: z.string().nullable(),
  progress: z.number().nullable(),
  created_at: dateTimeSchema,
  started_at: dateTimeSchema.nullable(),
  completed_at: dateTimeSchema.nullable(),
  failed_at: dateTimeSchema.nullable(),
});

export type BackgroundTask = z.infer<typeof backgroundTaskSchema>;
export type BackgroundTaskScheduleParams = Pick<BackgroundTask, "task_name" | "args">;

export const useScheduleTask = () => {
  return useMutation({
    mutationFn: async (data: BackgroundTaskScheduleParams): Promise<BackgroundTask> => {
      const result = await api.post(BASE_URL + "/schedule", data);
      return zodParse(backgroundTaskSchema, result.data);
    },
  });
};

const getBackgroundTask = async (id: number): Promise<BackgroundTask> => {
  const result = await api.get(`${BASE_URL}/${id}`);
  return zodParse(backgroundTaskSchema, result.data);
};

export const useTask = (id: number | null) => {
  return useQuery({
    queryKey: ["background_task", id],
    queryFn: () => getBackgroundTask(id!),
    enabled: !!id,
  });
};

export const downloadBackgroundTaskCsv = async (id: number) => {
  const result = await api.get(`${BASE_URL}/${id}/download_csv`, {
    responseType: "blob",
  });
  return result.data;
};

export type TaskPollerOptions = {
  maxAttempts?: number;
  waitTime?: number;
  backoff?: boolean;
  onUpdate?: (result: BackgroundTask) => void;
};

export const useTaskPoller = (options?: TaskPollerOptions) => {
  const waitTime = options?.waitTime ?? 1000;
  const backoff = options?.backoff ?? false;
  const maxAttempts = options?.maxAttempts || (backoff ? 15 : 300);
  const onUpdate = options?.onUpdate;
  const [taskId, setTaskId] = useState<number | null>(null);

  const taskQuery = useTask(taskId || null);
  const [isLoading, setIsLoading] = useState(false);

  const pollFn = async (
    taskId: number,
    attempt: number,
    status: BackgroundTask["status"] | "unknown",
    resolve: (result: BackgroundTask) => void,
    reject: (reason?: { message: string; task: BackgroundTask; exception?: Error }) => void
  ) => {
    if (attempt >= maxAttempts) {
      reject({ message: "Task timed out", task: taskQuery.data! });
      setIsLoading(false);
    } else if (status === "completed") {
      resolve(taskQuery.data!);
      setIsLoading(false);
    } else if (status === "failed") {
      reject({ message: "Task failed", task: taskQuery.data! });
      setIsLoading(false);
    } else {
      const result = await taskQuery.refetch();
      onUpdate?.(result.data!);
      setTimeout(
        pollFn,
        waitTime * (backoff ? attempt : 1),
        taskId,
        attempt + 1,
        result.data?.status || "unknown",
        resolve,
        reject
      );
    }
  };

  return {
    pollTask: async (taskId: number) => {
      return new Promise(async (resolve, reject) => {
        setTaskId(taskId);
        setIsLoading(true);
        setTimeout(pollFn, 0, taskId, 0, "unknown", resolve, reject);
      });
    },
    isLoading,
  };
};
