import { z } from "zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { zodParse } from "../zodParse";
import { api } from "..";

export const PROJECT_MEETING_NOTES_BASE_URL = `/project_meeting_notes`;

export const projectMeetingNoteSchema = z.object({
  id: z.number(),
  project_id: z.number(),
  project_name: z.string(),
  meeting_note_id: z.number(),
});

export const projectMeetingNoteCreateParamsSchema = z.object({
  project_id: z.number(),
  meeting_note_id: z.number(),
});

export type ProjectMeetingNoteData = z.infer<typeof projectMeetingNoteSchema>;
export type ProjectMeetingNoteCreateParams = z.infer<typeof projectMeetingNoteCreateParamsSchema>;

export const createProjectMeetingNote = async (
  projectMeetingNote: ProjectMeetingNoteCreateParams
) => {
  const result = await api.post(PROJECT_MEETING_NOTES_BASE_URL, {
    project_meeting_note: projectMeetingNote,
  });
  return zodParse(projectMeetingNoteSchema, result.data);
};

export const deleteProjectMeetingNote = async (projectMeetingNoteId: number) => {
  const result = await api.delete(`${PROJECT_MEETING_NOTES_BASE_URL}/${projectMeetingNoteId}`);
  return result.data;
};

export const useCreateProjectMeetingNote = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createProjectMeetingNote,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["project_meeting_notes"] }),
  });
};

export const useDeleteProjectMeetingNote = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteProjectMeetingNote,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["project_meeting_notes"] }),
  });
};
