import { z } from "zod";
import { zodParse } from "./zodParse";
import { dateTimeSchema } from "../helpers/dateTime";
import { userMinimalSchema } from "./user";
import { api } from ".";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SERVICES_BASE_URL } from "./services";
import { blobFileSchema } from "./blob_files";
import { POLLS_BASE_URL } from "./polls";

const FORMS_BASE_URL = "forms";
const FORM_TEMPLATES_BASE_URL = "templates";
const FORM_SUBMISSIONS_BASE_URL = "submissions";

export const fieldOptionSchema = z.object({
  id: z.number(),
  field_id: z.number(),
  label: z.string(),
  value: z.string(),
});

export const fieldOptionShowSchema = fieldOptionSchema.omit({
  field_id: true,
});

export const baseFormFieldSchema = z.object({
  id: z.number(),
  label: z.string(),
  description: z.string(),
  position: z.number(),
  is_required: z.boolean(),
});

export type FormFieldTypes =
  | "Form::Field::ShortText"
  | "Form::Field::LongText"
  | "Form::Field::SingleSelect"
  | "Form::Field::MultiSelect"
  | "Form::Field::Number"
  | "Form::Field::Date"
  | "Form::Field::File";

export const formFieldShortTextSchema = baseFormFieldSchema.extend({
  type: z.literal("Form::Field::ShortText"),
});

export const formFieldLongTextSchema = baseFormFieldSchema.extend({
  type: z.literal("Form::Field::LongText"),
});

export const singleSelectFormFieldSchema = baseFormFieldSchema.extend({
  type: z.literal("Form::Field::SingleSelect"),
  options: z.array(fieldOptionShowSchema).optional().nullable(),
});

export const multiSelectFormFieldSchema = baseFormFieldSchema.extend({
  type: z.literal("Form::Field::MultiSelect"),
  options: z.array(fieldOptionShowSchema).optional().nullable(),
});

export const formFieldNumberSchema = baseFormFieldSchema.extend({
  type: z.literal("Form::Field::Number"),
});

export const formFieldDateSchema = baseFormFieldSchema.extend({
  type: z.literal("Form::Field::Date"),
});

export const formFieldFileSchema = baseFormFieldSchema.extend({
  type: z.literal("Form::Field::File"),
});

export const formFieldSchema = z.discriminatedUnion("type", [
  singleSelectFormFieldSchema,
  multiSelectFormFieldSchema,
  formFieldShortTextSchema,
  formFieldLongTextSchema,
  formFieldNumberSchema,
  formFieldDateSchema,
  formFieldFileSchema,
]);

export const formTemplateSchema = z.object({
  id: z.number(),
  form_id: z.number(),
  published_at: dateTimeSchema.nullable(),
  fields: z.array(formFieldSchema),
  created_at: dateTimeSchema,
  updated_at: dateTimeSchema,
});

export const formTemplateShowSchema = formTemplateSchema.extend({
  is_published: z.boolean(),
  published_at: dateTimeSchema.nullable(),
  publishing_errors: z.array(
    z.object({
      field_id: z.number(),
      error: z.string(),
    })
  ),
});

export const formSchema = z.object({
  id: z.number(),
  domain_id: z.number(),
  domain_type: z.string(),
  target: z.string(),
  active_template: formTemplateShowSchema.nullable(),
  draft_template: formTemplateShowSchema.nullable(),
  templates: z.array(formTemplateSchema),
  created_at: dateTimeSchema,
  updated_at: dateTimeSchema,
});

export const baseFormResponseSchema = z.object({
  id: z.number(),
  type: z.string(),
  field_id: z.number(),
  field_type: z.string(),
  field_label: z.string(),
  value: z.any(),
});

export const textFormResponseSchema = baseFormResponseSchema.extend({
  type: z.literal("Form::Submission::Response::Text"),
  value: z.string().nullable(),
});

export const singleSelectFormResponseSchema = baseFormResponseSchema.extend({
  type: z.literal("Form::Submission::Response::SingleSelect"),
  value: z.number().nullable(),
  value_label: z.string().nullable(),
});

export const multiSelectFormResponseSchema = baseFormResponseSchema.extend({
  type: z.literal("Form::Submission::Response::MultiSelect"),
  value: z.array(z.number()),
  value_label: z.array(z.string()),
});

export const numberFormResponseSchema = baseFormResponseSchema.extend({
  type: z.literal("Form::Submission::Response::Number"),
  value: z.number().nullable(),
});

export const dateFormResponseSchema = baseFormResponseSchema.extend({
  type: z.literal("Form::Submission::Response::Date"),
  value: dateTimeSchema.nullable(),
});

export const fileFormResponseSchema = baseFormResponseSchema.extend({
  type: z.literal("Form::Submission::Response::File"),
  value: z.array(blobFileSchema),
});

export const formResponseSchema = z.discriminatedUnion("type", [
  textFormResponseSchema,
  singleSelectFormResponseSchema,
  multiSelectFormResponseSchema,
  numberFormResponseSchema,
  dateFormResponseSchema,
  fileFormResponseSchema,
]);

export const formSubmissionSchema = z.object({
  id: z.number(),
  form: formSchema,
  target_id: z.number(),
  target_type: z.string(),
  user: z.lazy(() => userMinimalSchema),
  responses: z.array(formResponseSchema),
  template: formTemplateSchema,
});

export type FormData = z.infer<typeof formSchema>;
export type FormTemplateData = z.infer<typeof formTemplateSchema>;
export type FormTemplateShowData = z.infer<typeof formTemplateShowSchema>;
export type FormFieldData = z.infer<typeof formFieldSchema>;

export type FieldOptionData = z.infer<typeof fieldOptionSchema>;

export type FormResponseText = z.infer<typeof textFormResponseSchema>;
export type FormResponseSingleSelect = z.infer<typeof singleSelectFormResponseSchema>;
export type FormResponseMultiSelect = z.infer<typeof multiSelectFormResponseSchema>;
export type FormResponseData = z.infer<typeof formResponseSchema>;

export type CreateFormData = Pick<FormData, "domain_id" | "domain_type" | "target">;
export type CreateFormFieldData = Pick<FormFieldData, "label" | "is_required" | "type">;
export type UpdateFormFieldData = Pick<
  FormFieldData,
  "label" | "is_required" | "type" | "description" | "position"
>;
export type CreateFormFieldOptionData = Pick<FieldOptionData, "label" | "value">;

export type CreateFormResponseText = Omit<
  FormResponseText,
  "id" | "type" | "field_type" | "field_label"
>;
export type CreateFormResponseSingleSelect = Omit<
  FormResponseSingleSelect,
  "id" | "type" | "field_type" | "value_label" | "field_label"
>;
export type CreateFormResponseMultiSelect = Omit<
  FormResponseMultiSelect,
  "id" | "type" | "field_type" | "value_label" | "field_label"
>;

export type CreateFormResponseData =
  | CreateFormResponseText
  | CreateFormResponseSingleSelect
  | CreateFormResponseMultiSelect;

export type UpdateResponseData = {
  response_id: number;
  value: any;
};
export interface CreateSubmissionData {
  user_id: number;
  template_id: number;
  target_id?: number;
  responses: CreateFormResponseData[];
}

const invalidateFormsQueries = (serviceId: number, pollId?: number) => {
  const queryClient = useQueryClient();
  return async () => {
    queryClient.invalidateQueries([FORMS_BASE_URL]);
    queryClient.invalidateQueries([SERVICES_BASE_URL, serviceId]);
    queryClient.invalidateQueries([POLLS_BASE_URL, pollId]);
  };
};

export const createTemplateField = async ({
  templateId,
  data,
}: {
  templateId: number;
  data: CreateFormFieldData;
}) => {
  const result = await api.post(`${FORMS_BASE_URL}/templates/${templateId}/fields`, {
    field: data,
  });
  return zodParse(formFieldSchema, result.data);
};

export const useCreateTemplateField = () => {
  return useMutation(({ templateId, data }: { templateId: number; data: CreateFormFieldData }) =>
    createTemplateField({ templateId, data })
  );
};

export const copyTemplateField = async ({ fieldId }: { fieldId: number }) => {
  const result = await api.post(`${FORMS_BASE_URL}/fields/${fieldId}/copy`);
  return zodParse(formFieldSchema, result.data);
};

export const useCopyTemplateField = () => {
  return useMutation(({ fieldId }: { fieldId: number }) => copyTemplateField({ fieldId }), {});
};

export const updateTemplateField = async (fieldId: number, data: Partial<UpdateFormFieldData>) => {
  const result = await api.put(`${FORMS_BASE_URL}/fields/${fieldId}`, { field: data });
  return zodParse(formFieldSchema, result.data);
};

export const useUpdateTemplateField = () => {
  return useMutation(({ fieldId, data }: { fieldId: number; data: Partial<UpdateFormFieldData> }) =>
    updateTemplateField(fieldId, data)
  );
};

export const deleteTemplateField = async (fieldId: number) => {
  await api.delete(`${FORMS_BASE_URL}/fields/${fieldId}`);
};

export const useDeleteTemplateField = () => {
  return useMutation(({ fieldId }: { fieldId: number }) => deleteTemplateField(fieldId), {});
};

export const createTemplateFieldOption = async (
  fieldId: number,
  data: CreateFormFieldOptionData
) => {
  const result = await api.post(`${FORMS_BASE_URL}/fields/${fieldId}/options`, { option: data });
  return zodParse(fieldOptionSchema, result.data);
};

export const useCreateTemplateFieldOption = () => {
  return useMutation(({ fieldId, data }: { fieldId: number; data: CreateFormFieldOptionData }) =>
    createTemplateFieldOption(fieldId, data)
  );
};

//TODO Implement this on backend
export const updateTemplateFieldOption = async (
  optionId: number,
  data: CreateFormFieldOptionData
) => {
  const result = await api.put(`${FORMS_BASE_URL}/options/${optionId}`, { option: data });
  return zodParse(fieldOptionShowSchema, result.data);
};

export const useUpdateTemplateFieldOption = () => {
  return useMutation(({ optionId, data }: { optionId: number; data: CreateFormFieldOptionData }) =>
    updateTemplateFieldOption(optionId, data)
  );
};

//TODO Implement this on backend
export const deleteTemplateFieldOption = async (optionId: number) => {
  await api.delete(`${FORMS_BASE_URL}/options/${optionId}`);
};

export const useDeleteTemplateFieldOption = () => {
  return useMutation(
    ({ optionId }: { optionId: number }) => deleteTemplateFieldOption(optionId),
    {}
  );
};

export const reorderTemplateFields = async (templateId: number, fieldIds: number[]) => {
  await api.put(`${FORMS_BASE_URL}/${FORM_TEMPLATES_BASE_URL}/${templateId}/fields/reorder`, {
    ordered_field_ids: fieldIds,
  });
};

export const useReorderTemplateFields = () => {
  return useMutation(({ templateId, fieldIds }: { templateId: number; fieldIds: number[] }) =>
    reorderTemplateFields(templateId, fieldIds)
  );
};

export const copyActiveTemplate = async (formId: number) => {
  const result = await api.post(`${FORMS_BASE_URL}/${formId}/copy_active_template`);
  return zodParse(formTemplateSchema, result.data);
};

export const useCopyActiveTemplate = () => {
  return useMutation(({ formId }: { formId }) => copyActiveTemplate(formId), {});
};

export const publishTemplate = async (templateId: number) => {
  const result = await api.post(`${FORMS_BASE_URL}/templates/${templateId}/publish`);
  return zodParse(formTemplateSchema, result.data);
};

export const usePublishTemplate = () => {
  return useMutation(({ templateId }: { templateId: number }) => publishTemplate(templateId), {});
};

export const deleteTemplate = async (templateId: number) => {
  await api.delete(`${FORMS_BASE_URL}/templates/${templateId}`);
};

export const useDeleteTemplate = (serviceId: number) => {
  return useMutation(({ templateId }: { templateId: number }) => deleteTemplate(templateId), {
    onSuccess: () => {
      invalidateFormsQueries(serviceId);
    },
  });
};

export const submitForm = async (templateId: number, data: CreateSubmissionData) => {
  await api.post(`${FORM_TEMPLATES_BASE_URL}/${templateId}/submissions`, data);
  return;
};

export const useSubmitForm = () => {
  return useMutation(({ templateId, data }: { templateId: number; data: CreateSubmissionData }) =>
    submitForm(templateId, data)
  );
};

export const getSubmission = async (submissionId: number) => {
  const result = await api.get(`${FORM_SUBMISSIONS_BASE_URL}/${submissionId}`);
  return zodParse(formResponseSchema, result.data);
};
