import {
  ChevronDownIcon,
  DeleteIcon,
  InfoOutlineIcon,
  ViewIcon,
  ViewOffIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  MenuButton,
  Menu,
  Switch,
  Text,
  useColorModeValue,
  MenuList,
  useToast,
  HStack,
  Tooltip,
  Tag,
  VStack,
} from "@chakra-ui/react";
import {
  AttributesTable,
  EditableDate,
  EditableSelect,
  Header,
  SplitPage,
} from "@sciencecorp/helix-components";
import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoPlayOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { invalidatePolls, useDeletePoll, useGetPoll, useUpdatePoll } from "../../../api/polls";
import { humanize } from "inflection";
import { useConfidentialityOptions, usePollCategoryOptions } from "../../../api/options";
import { FormBuilder } from "../../Requests/components/DetailPage/FormBuilder";
import { PollResponses } from "./PollResponses";
import { DateTime } from "luxon";
import _ from "lodash";
import { RiHexagonLine } from "react-icons/ri";
import { BackgroundReportButton } from "../../shared/BackgroundReportButton";

export const pollStatusColors = (status: string) => {
  if (status === "active") return "green";
  if (status === "scheduled") return "blue";
  if (status === "closed") return "gray";
  return "yellow";
};

export const PollDetailsPage = () => {
  const backgroundGray = useColorModeValue("gray.50", "gray.700");
  const { id } = useParams();
  const { data, isLoading } = useGetPoll(Number(id));
  const confidentialityOptions = useConfidentialityOptions();
  const pollCategoryOptions = usePollCategoryOptions();
  const { mutateAsync: updatePoll, isLoading: isLoadingUpdate } = useUpdatePoll(Number(id));
  const { mutateAsync: deletePoll, isLoading: isLoadingDelete } = useDeletePoll(Number(id));
  const navigate = useNavigate();
  const toast = useToast();
  const invalidatePoll = invalidatePolls(Number(id));

  const selectedConfidentiality = confidentialityOptions.find(
    (option) => option.value === data?.confidentiality
  );

  const currentTemplate = _.last(data?.form?.templates);
  const schedulable =
    currentTemplate !== null &&
    (currentTemplate?.fields?.length ?? 0) > 0 &&
    currentTemplate?.published_at !== null;

  const previewDisabled =
    currentTemplate?.fields?.some(
      (field) => field.type === "Form::Field::ShortText" || field.type === "Form::Field::LongText"
    ) || false;

  return (
    <>
      <Header
        title={`Poll ${id}`}
        badge={{
          label: data?.status || "Unscheduled",
          colorScheme: pollStatusColors(data?.status || "Unscheduled"),
        }}
        crumbs={[
          { label: "HR", url: "/hr" },
          { label: "Polls", url: "/hr/polls" },
        ]}
        crumbsColor="teal"
        actions={[
          <Button
            key="preview"
            leftIcon={<ViewIcon />}
            onClick={() => navigate(`/hr/polls/${id}/preview`)}>
            Preview
          </Button>,
          <BackgroundReportButton
            taskName="Reports::PollResponses"
            args={[Number(id)]}
            title={`Poll ${id} responses`}
            buttonName="Export"
          />,
          data?.status === "unscheduled" || data?.status === "scheduled" ? (
            <Button
              key="start-immediately"
              leftIcon={<IoPlayOutline />}
              isLoading={isLoadingUpdate}
              colorScheme="teal"
              isDisabled={!schedulable}
              onClick={() => {
                const startDate = DateTime.now();
                const endDate = startDate.plus({ days: 7 });

                updatePoll({
                  id: Number(id),
                  start_date: startDate.toString(),
                  end_date: endDate.toString(),
                }).then(() => {
                  invalidatePoll();
                });
              }}>
              Start Immediately
            </Button>
          ) : (
            <></>
          ),
          data?.status === "active" ? (
            <Button
              key="end-early"
              leftIcon={<RiHexagonLine />}
              colorScheme="red"
              isLoading={isLoadingUpdate}
              onClick={() => {
                const endDate = new Date();
                endDate.setDate(endDate.getDate() - 1);
                updatePoll({ id: Number(id), end_date: endDate.toISOString() }).then(() => {
                  invalidatePoll();
                });
              }}>
              End Early
            </Button>
          ) : (
            <></>
          ),

          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  as={IconButton}
                  icon={<BsThreeDotsVertical />}
                  size="md"
                  aria-label="More actions"
                  isActive={isOpen}
                />
                <MenuList>
                  <MenuItem
                    isDisabled={data?.status !== "unscheduled"}
                    onClick={() => {
                      deletePoll(Number(id)).then(() => {
                        toast({
                          title: "Poll deleted",
                          description: "The poll has been deleted.",
                          status: "success",
                        });
                        navigate("/hr/polls");
                      });
                    }}>
                    <DeleteIcon marginRight="4" color="red.600" />
                    Delete Poll
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>,
        ]}
      />
      <SplitPage
        sidebar={
          <>
            <Box
              width="100%"
              bg={backgroundGray}
              border="1px"
              borderColor={"chakra-border-color"}
              p={4}
              mb={4}
              borderRadius="md">
              <AttributesTable
                title="Poll Details"
                attributes={[
                  {
                    label: "Category",
                    value: (
                      <EditableSelect
                        isLoading={isLoadingUpdate}
                        options={pollCategoryOptions}
                        preview={humanize(data?.category || "")}
                        onSubmit={(value) => {
                          if (value)
                            updatePoll({ id: Number(id), category: value.toString() }).then(() => {
                              invalidatePoll();
                            });
                        }}
                      />
                    ),
                  },
                  {
                    label: "Confidentiality",
                    value: (
                      <HStack justifyContent="space-between" alignItems="center" width="100%">
                        <Menu>
                          <Tooltip label={selectedConfidentiality?.description}>
                            <MenuButton
                              isDisabled={
                                data?.status !== "unscheduled" && data?.status !== "scheduled"
                              }
                              maxWidth="max-content"
                              size="xs"
                              as={Button}
                              leftIcon={
                                selectedConfidentiality?.value === "hidden" ? (
                                  <ViewOffIcon />
                                ) : (
                                  <ViewIcon />
                                )
                              }
                              colorScheme={selectedConfidentiality?.color}
                              rightIcon={<ChevronDownIcon />}>
                              {selectedConfidentiality?.label}
                            </MenuButton>
                          </Tooltip>
                          <MenuList>
                            {confidentialityOptions.map((option) => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                                onClick={() => {
                                  updatePoll({
                                    id: Number(id),
                                    confidentiality: option.value,
                                  }).then(() => {
                                    invalidatePoll();
                                  });
                                }}>
                                <Tag colorScheme={option.color} gap={2}>
                                  {option.value === "hidden" ? <ViewOffIcon /> : <ViewIcon />}
                                  {option.label}
                                </Tag>
                              </MenuItem>
                            ))}
                          </MenuList>
                        </Menu>
                      </HStack>
                    ),
                  },
                  {
                    label: "Preview Responses",
                    value: (
                      <HStack alignItems="center">
                        <HStack>
                          <Text>{data?.preview_response ? "On" : "Off"}</Text>

                          <Switch
                            colorScheme="teal"
                            isDisabled={
                              isLoadingUpdate ||
                              (data?.status !== "unscheduled" && data?.status !== "scheduled") ||
                              previewDisabled
                            }
                            isChecked={data?.preview_response}
                            onChange={(e) => {
                              updatePoll({
                                id: Number(id),
                                preview_response: e.target.checked,
                              }).then(() => {
                                invalidatePoll();
                              });
                            }}
                          />
                        </HStack>
                        <Box alignItems="start">
                          <Tooltip label="When enabled, users will see a summary of aggregated responses after submitting their answer. Only available for single and multi select polls.">
                            <InfoOutlineIcon />
                          </Tooltip>
                        </Box>
                      </HStack>
                    ),
                  },
                ]}
              />
            </Box>
            <Box
              width="100%"
              bg={backgroundGray}
              border="1px"
              borderColor={"chakra-border-color"}
              p={4}
              borderRadius="md">
              <AttributesTable
                title="Schedule"
                attributes={[
                  {
                    label: "Start Date",
                    value: (
                      <EditableDate
                        placeholder="Select a date"
                        isLoading={isLoadingUpdate}
                        min={DateTime.now().toFormat("yyyy-MM-dd")}
                        disabled={!schedulable}
                        preview={
                          data?.start_date ? (
                            <Text>{DateTime.fromISO(data.start_date).toFormat("MM-dd-yyyy")}</Text>
                          ) : (
                            <Text>No date set </Text>
                          )
                        }
                        onSubmit={(value) => {
                          if (!value) return;
                          const valueDate = value ? value.split("T")[0] : null;
                          if (valueDate && DateTime.fromISO(valueDate) < DateTime.now()) {
                            toast({
                              title: "Start date cannot be in the past",
                              status: "error",
                            });
                            return;
                          }
                          const startDate = DateTime.fromISO(value);
                          let endDate: DateTime | null = data?.end_date
                            ? DateTime.fromISO(data.end_date)
                            : null;
                          if (!endDate) {
                            endDate = startDate.plus({ days: 7 });
                          }
                          updatePoll({
                            id: Number(id),
                            start_date: value.toString(),
                            end_date: endDate.toString(),
                          }).then(() => {
                            invalidatePoll();
                          });
                        }}
                      />
                    ),
                  },
                  {
                    label: "End Date",
                    value: (
                      <EditableDate
                        placeholder="Select a date"
                        isLoading={isLoadingUpdate}
                        min={
                          data?.start_date
                            ? DateTime.fromISO(data.start_date)
                                .plus({ days: 1 })
                                .toFormat("yyyy-MM-dd")
                            : DateTime.now().toFormat("yyyy-MM-dd")
                        }
                        disabled={!data?.start_date}
                        preview={
                          data?.end_date ? (
                            <Text>{DateTime.fromISO(data.end_date).toFormat("MM-dd-yyyy")}</Text>
                          ) : (
                            <Text>No date set </Text>
                          )
                        }
                        onSubmit={(value) => {
                          const valueDate = value ? value.split("T")[0] : null;
                          if (valueDate && data?.start_date && valueDate <= data?.start_date) {
                            toast({
                              title: "End date must be after start date",
                              status: "error",
                            });
                            return;
                          }
                          if (value)
                            updatePoll({ id: Number(id), end_date: value.toString() }).then(() => {
                              invalidatePoll();
                            });
                        }}
                      />
                    ),
                  },
                ]}
              />
            </Box>
          </>
        }
        main={
          <VStack width="100%" spacing={4}>
            <Box width="100%">
              {data && <FormBuilder item={data} type="poll" invalidateFunction={invalidatePoll} />}
            </Box>
            <Box width="100%">{data && <PollResponses poll={data} preview={false} />}</Box>
          </VStack>
        }
      />
    </>
  );
};
