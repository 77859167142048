import { FormModal, Select } from "@sciencecorp/helix-components";
import {
  FormLabel,
  FormControl,
  Text,
  VStack,
  Divider,
  HStack,
  Switch,
  Tooltip,
  MenuItem,
  Button,
  MenuButton,
  Menu,
  MenuList,
  Badge,
  Tag,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { ChevronDownIcon, InfoOutlineIcon, ViewOffIcon, ViewIcon } from "@chakra-ui/icons";
import { Controller, useForm } from "react-hook-form";
import { useConfidentialityOptions, usePollCategoryOptions } from "../../../api/options";
import { useCreatePoll } from "../../../api/polls";
import { useNavigate } from "react-router-dom";

export type NewPollModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

type PollModalFormValuesType = {
  category: string;
  confidentiality: string;
  preview_responses: boolean;
};

export const NewPollModal = ({ isOpen, onClose }: NewPollModalProps) => {
  const { control, watch, handleSubmit } = useForm<PollModalFormValuesType>({
    defaultValues: {
      category: "",
      confidentiality: "",
      preview_responses: false,
    },
  });
  const bgColor = useColorModeValue("gray.50", "gray.600");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const textColor = useColorModeValue("gray.500", "gray.200");
  const confidentialityOptions = useConfidentialityOptions();
  const pollCategoryOptions = usePollCategoryOptions();
  const { mutateAsync: createPoll, isLoading } = useCreatePoll();
  const navigate = useNavigate();

  const onSubmit = (data: PollModalFormValuesType) => {
    const { category, confidentiality, preview_responses } = data;
    createPoll({
      category: category,
      confidentiality: confidentiality,
      preview_response: preview_responses,
    }).then((data) => {
      navigate(`/hr/polls/${data.id}`);
    });
  };

  const disableButton = !watch("category") || !watch("confidentiality");

  return (
    <FormModal
      size="xl"
      title="New Poll"
      isOpen={isOpen}
      closeOnOverlayClick={false}
      submitButtonColorSchema="teal"
      submitButtonTitle="Create"
      isLoading={isLoading}
      submitButtonDisabled={disableButton}
      onClose={onClose}
      handleSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="category"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormControl isRequired>
            <FormLabel>Category</FormLabel>
            <Select options={pollCategoryOptions} onChange={field.onChange} value={field.value} />
          </FormControl>
        )}
      />
      <VStack
        gap={4}
        bgColor={bgColor}
        width="100%"
        p={4}
        borderRadius="md"
        border="1px solid"
        borderColor={borderColor}>
        <Controller
          name="confidentiality"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl isRequired>
              <HStack justifyContent="space-between" alignItems="center">
                <FormLabel>Confidentiality</FormLabel>
                <Menu>
                  <MenuButton
                    size="sm"
                    as={Button}
                    leftIcon={
                      confidentialityOptions.find((option) => option.value === field.value)
                        ?.value === "hidden" ? (
                        <ViewOffIcon />
                      ) : (
                        <ViewIcon />
                      )
                    }
                    colorScheme={
                      confidentialityOptions.find((option) => option.value === field.value)?.color
                    }
                    rightIcon={<ChevronDownIcon />}>
                    {confidentialityOptions.find((option) => option.value === field.value)?.label}
                  </MenuButton>
                  <MenuList>
                    {confidentialityOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        onClick={() => {
                          field.onChange(option.value);
                        }}>
                        <Tag colorScheme={option.color} gap={2}>
                          {option.value === "hidden" ? <ViewOffIcon /> : <ViewIcon />}
                          {option.label}
                        </Tag>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </HStack>
              <Text fontSize="xs" color={textColor} mt={4}>
                {confidentialityOptions.find((option) => option.value === field.value)?.description}
              </Text>
            </FormControl>
          )}
        />
        <Divider />
        <Controller
          name="preview_responses"
          control={control}
          render={({ field }) => (
            <FormControl>
              <HStack justifyContent="space-between" alignItems="center">
                <HStack alignItems="center">
                  <FormLabel>Preview Responses</FormLabel>
                  <Tooltip label="When enabled, users will see a summary of aggregated responses after submitting their answer.  Only available for single and multi select polls.">
                    <InfoOutlineIcon />
                  </Tooltip>
                </HStack>
                <HStack alignItems="center">
                  {field.value ? <Text>Yes</Text> : <Text>No</Text>}
                  <Switch isChecked={field.value} onChange={field.onChange} colorScheme="teal" />
                </HStack>
              </HStack>
            </FormControl>
          )}
        />
      </VStack>
    </FormModal>
  );
};
