import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Textarea,
  Text,
  useToast,
  HStack,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useNavigate } from "react-router-dom";
import { useGetAllSitesQuery } from "../../api/sites";
import { useCreateIncidentReportMutation } from "../../api/incident_reports";
import { DefaultIncidentTypes } from "./DefaultIncidentTypes";

interface NewIncidentReportModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const NewIncidentReportModal: React.FC<NewIncidentReportModalProps> = ({
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { data: sites } = useGetAllSitesQuery();

  const [formData, setFormData] = React.useState({
    type: "",
    site_id: "",
    description: "",
    other_type: "",
  });

  const resetFormData = () => {
    setFormData({
      type: "",
      site_id: "",
      description: "",
      other_type: "",
    });
  };

  const closeModal = () => {
    resetFormData();
    onClose();
  };

  const { mutate: createIncidentReport, isLoading } = useCreateIncidentReportMutation();

  const handleSubmit = () => {
    createIncidentReport(
      {
        incident_report: {
          incident_type: formData.type === "other" ? formData.other_type : formData.type,
          site_id: parseInt(formData.site_id),
          description: formData.description,
        },
      },
      {
        onSuccess: (response) => {
          toast({
            title: "Success",
            description: "Incident report created successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          onClose();
          resetFormData();
          navigate(`/incident-reports/${response.id}`);
        },
        onError: () => {
          toast({
            title: "Error",
            description: "Failed to create incident report",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        },
      }
    );
  };

  const isComplete =
    (formData.type === "other" ? formData.other_type : formData.type) &&
    formData.site_id &&
    formData.description;

  const incidentTypes = [...DefaultIncidentTypes, "Other (specify if other)"];

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New Incident Report</ModalHeader>
        <ModalBody>
          <VStack spacing={4}>
            <HStack width="100%" spacing={4}>
              <FormControl isRequired>
                <FormLabel>Type</FormLabel>
                <Select
                  options={incidentTypes.map((type) => ({ label: type, value: type }))}
                  onChange={(option) => setFormData({ ...formData, type: option?.value || "" })}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Site</FormLabel>
                <Select
                  options={
                    sites?.map((site) => ({
                      label: site.name,
                      value: site.id.toString(),
                    })) as { label: string; value: string }[]
                  }
                  onChange={(option) => setFormData({ ...formData, site_id: option?.value || "" })}
                />
              </FormControl>
            </HStack>

            {formData.type === "other" && (
              <FormControl isRequired>
                <FormLabel>Specify Other</FormLabel>
                <Input
                  value={formData.other_type}
                  onChange={(e) => setFormData({ ...formData, other_type: e.target.value })}
                  placeholder="Enter other type"
                />
              </FormControl>
            )}
            <FormControl isRequired>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                placeholder="Describe the incident..."
              />
            </FormControl>
            <Text>
              Summarize the quality incident by detailing what happened, when and where it occurred,
              who was involved, and why it matters.
            </Text>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={closeModal}>
            Cancel
          </Button>
          <Button
            colorScheme="teal"
            onClick={handleSubmit}
            isLoading={isLoading}
            isDisabled={!isComplete}>
            Create Report
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
