import { ChevronLeftIcon, ChevronRightIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Tag,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { humanize } from "inflection";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Control, useForm } from "react-hook-form";
import { useConfidentialityOptions } from "../../../api/options";
import { useCreatePollResponse } from "../../../api/poll_responses";
import { PollData } from "../../../api/polls";
import { usePollsForUserQuery, UserMinimalData } from "../../../api/user";
import { getDefaultForFieldType } from "../../Requests/components/NewServiceRequestModal";
import { FormFieldSubmission } from "../../Requests/components/ServiceRequest/FormFieldSubmission";
import { PollResponses } from "./PollResponses";

export const PollToaster = ({ currentUser }: { currentUser: UserMinimalData }) => {
  const toast = useToast({ position: "bottom-right" });
  const { data: pollData } = usePollsForUserQuery(currentUser.id);
  const { mutateAsync: createPollResponse } = useCreatePollResponse();
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [answeredPolls, setAnsweredPolls] = useState<Record<number, number>>({});

  const defaultValues = pollData?.reduce((acc, poll) => {
    acc[poll.id.toString()] = poll.form?.active_template?.fields?.reduce((acc, field) => {
      acc[field.id.toString()] = getDefaultForFieldType(field.type);
      return acc;
    }, {});
    return acc;
  }, {});
  const {
    control: formControl,
    watch: formWatch,
    getValues,
  } = useForm({
    defaultValues,
  });
  const formBuilderValues = formWatch();
  const fieldId = pollData?.[currentCardIndex]?.form?.active_template?.fields[0]?.id;
  const disableSubmit = fieldId
    ? formBuilderValues[fieldId] && (formBuilderValues[fieldId].length || 1) > 0
    : true;

  const handlePrevious = () => {
    if (currentCardIndex > 0) {
      setCurrentCardIndex((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentCardIndex < (pollData?.length || 0) - 1) {
      setCurrentCardIndex((prev) => prev + 1);
    }
  };

  const onSubmit = ({ pollId, isDeclined }: { pollId: number; isDeclined: boolean }) => {
    if (!pollData) return;

    const singlePoll = pollData.find((result) => result.id === pollId);
    if (!singlePoll?.form?.active_template?.id) return;

    const fieldIdsToSend = singlePoll.form?.active_template?.fields.map((field) => field.id);

    const currentFormValues = getValues();
    const pollFieldIds = Object.keys(currentFormValues).filter((key) =>
      fieldIdsToSend.includes(Number(key))
    );
    const responses = pollFieldIds.map((key) => ({
      field_id: Number(key),
      value: currentFormValues[key],
    }));

    const payload = {
      poll_id: pollId,
      user_id: currentUser.id,
      form_submission: isDeclined
        ? null
        : {
            template_id: singlePoll.form?.active_template?.id,
            user_id: currentUser.id,
            responses,
          },
    };

    createPollResponse(payload).then((data) => {
      setAnsweredPolls((prev) => ({ ...prev, [pollId]: data.id }));
    });
  };

  const done = pollData?.map((pd) => pd.id).every((id) => answeredPolls[id]) || false;

  const toasterOptions = {
    duration: done ? 10000 : null,
    id: "poll-toaster",
    render: (props) => (
      <PollToasterContent
        onClose={props.onClose}
        pollData={pollData!}
        currentCardIndex={currentCardIndex}
        formControl={formControl}
        onSubmit={onSubmit}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        disableSubmit={disableSubmit}
        answeredPolls={answeredPolls}
      />
    ),
  };
  useEffect(() => {
    if (pollData && pollData?.length > 0 && !toast.isActive("poll-toaster")) {
      toast(toasterOptions);
    } else if (pollData && pollData?.length > 0) {
      toast.update("poll-toaster", toasterOptions);
    } else if (pollData && pollData.length === 0) {
      toast.close("poll-toaster");
    }
  }, [pollData, currentCardIndex, _.keys(answeredPolls).length, disableSubmit]);

  return null;
};

const PollToasterContent = ({
  onClose,
  pollData,
  currentCardIndex,
  formControl,
  onSubmit,
  handlePrevious,
  handleNext,
  disableSubmit,
  answeredPolls,
}: {
  onClose: () => void;
  pollData: PollData[];
  currentCardIndex: number;
  formControl: Control;
  onSubmit: (props: { pollId: number; isDeclined: boolean }) => void;
  handlePrevious: () => void;
  handleNext: () => void;
  disableSubmit: boolean;
  answeredPolls: Record<number, number>;
}) => {
  const confidentialityOptions = useConfidentialityOptions();

  const showCurrentPollResponse = useMemo(() => {
    return (
      pollData?.[currentCardIndex]?.preview_response &&
      answeredPolls[pollData?.[currentCardIndex]?.id]
    );
  }, [pollData, currentCardIndex, answeredPolls]);

  const currentPollDone = useMemo(() => {
    return answeredPolls[pollData?.[currentCardIndex]?.id];
  }, [pollData, answeredPolls, currentCardIndex]);

  const selectedConfidentiality = useMemo(() => {
    return confidentialityOptions.find(
      (option) => option.value === pollData?.[currentCardIndex]?.confidentiality
    );
  }, [confidentialityOptions, pollData, currentCardIndex]);

  return (
    <Box>
      {pollData && (
        <Card variant="elevated" width="xl" marginRight="10px">
          <CardBody>
            <>
              {pollData.map((poll, index) => {
                if (index !== currentCardIndex) {
                  return null;
                }
                if (!currentPollDone) {
                  return poll.form?.active_template?.fields.map((field, idx) => (
                    <Box key={`field-box-${poll.id}-${field.id}`}>
                      <FormFieldSubmission
                        key={`field-${field.id}-${field.id}`}
                        formField={field}
                        control={formControl}
                      />
                    </Box>
                  ));
                } else if (showCurrentPollResponse) {
                  return (
                    <Box key={`field-box-completed-poll-${poll.id}`}>
                      <PollResponses key={`poll-response-${poll.id}`} poll={poll} preview={true} />
                    </Box>
                  );
                } else {
                  return (
                    <Box key={`field-box-completed-poll-${poll.id}`}>
                      <Text key={`thank-you-${poll.id}`}>
                        Thank you for submitting your response!
                      </Text>
                    </Box>
                  );
                }
              })}
            </>
          </CardBody>
          <CardFooter>
            <Flex flex={1}>
              <Tooltip placement="left-start" label={selectedConfidentiality?.description}>
                <Tag colorScheme={selectedConfidentiality?.color}>
                  <ViewIcon mr={2} />
                  {humanize(selectedConfidentiality?.value || "")}
                </Tag>
              </Tooltip>
              {pollData?.length > 1 && (
                <HStack alignItems="center">
                  <IconButton
                    ml={2}
                    aria-label="Previous"
                    icon={<ChevronLeftIcon />}
                    onClick={handlePrevious}
                    isDisabled={currentCardIndex === 0}
                  />
                  <IconButton
                    ml={2}
                    aria-label="Next"
                    icon={<ChevronRightIcon />}
                    onClick={handleNext}
                    isDisabled={currentCardIndex === pollData.length - 1}
                  />
                  <Text ml={2} fontSize="sm" color="gray.500">
                    {currentCardIndex + 1} / {pollData.length}
                  </Text>
                </HStack>
              )}
              <Spacer />
              {!currentPollDone ? (
                <>
                  <Button
                    onClick={() =>
                      onSubmit({ pollId: pollData?.[currentCardIndex]?.id, isDeclined: true })
                    }>
                    Decline
                  </Button>
                  <Button
                    ml={2}
                    colorScheme="teal"
                    isDisabled={!disableSubmit}
                    onClick={() =>
                      onSubmit({ pollId: pollData?.[currentCardIndex]?.id, isDeclined: false })
                    }>
                    Submit
                  </Button>
                </>
              ) : currentCardIndex === pollData.length - 1 ? (
                <Button onClick={() => onClose()}>All Done!</Button>
              ) : (
                <Button onClick={() => handleNext()}>Next</Button>
              )}
            </Flex>
          </CardFooter>
        </Card>
      )}
    </Box>
  );
};
