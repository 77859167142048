import { AddIcon, ArrowForwardIcon, MinusIcon, WarningIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Divider,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Tag,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { initial } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import { InventoryShowData } from "../../../../../api/inventory";
import {
  LocationTreeKeyValue,
  useTreeSelectInventoryLocations,
} from "../../../../../api/inventory_location";
import { useCurrency } from "../../../../../contexts/CurrencyContext";
import { MoneyInput } from "../../../../MoneyInput";
import { findSelectedOption, TreeSelect } from "../../../../shared/TreeSelect";
import { RestockFormValues } from "./util";

type AddStockAndLocationProps = {
  inventory: InventoryShowData;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  control: Control<RestockFormValues, any>;
  formValues: RestockFormValues;
  setValue: UseFormSetValue<RestockFormValues>;
  setSearch: React.Dispatch<React.SetStateAction<string | undefined>>;
  showPreview: boolean;
  setShowPreview: React.Dispatch<React.SetStateAction<boolean>>;
  pathToLocation: LocationTreeKeyValue[] | undefined;
  setPathToLocation: React.Dispatch<React.SetStateAction<LocationTreeKeyValue[] | undefined>>;
  treeSelectInventoryLocationsQuery: ReturnType<typeof useTreeSelectInventoryLocations>;
  purchaseId?: string | number | null;
};

export const AddStockAndLocation = ({
  inventory,
  control,
  formValues,
  setValue,
  setSearch,
  showPreview,
  setShowPreview,
  pathToLocation,
  setPathToLocation,
  treeSelectInventoryLocationsQuery,
  purchaseId,
}: AddStockAndLocationProps) => {
  const currency = useCurrency();
  const [date, setDate] = useState<string | undefined>();

  const disableForm = useMemo(() => {
    return !formValues.is_backfill && !formValues.purchase_line_item;
  }, [formValues.is_backfill, formValues.purchase_line_item]);

  const location = useMemo(() => formValues.option, [formValues.option]);
  useEffect(() => {
    const data = treeSelectInventoryLocationsQuery?.data;
    if (location && data) {
      const path = findSelectedOption(data, location);
      setPathToLocation(initial(path));
      setShowPreview(true);
    }
  }, [treeSelectInventoryLocationsQuery?.data, location]);

  return (
    <Flex direction="column" w="100%" gap={2} p={4}>
      <Box>
        {!formValues.is_backfill && date && (
          <Alert status="warning">
            <AlertIcon />
            <Flex direction="column">
              <AlertTitle>Invalid Date</AlertTitle>
              <AlertDescription>
                Inventory items{" "}
                <Box as="span" fontWeight="bold">
                  purchased after November 2023{" "}
                </Box>{" "}
                must be associated with a Helix purchase line item.
              </AlertDescription>
            </Flex>
          </Alert>
        )}
        {!formValues.purchase_line_item && (
          <FormControl mt={4} isRequired>
            <FormLabel>When was this item purchased?</FormLabel>
            <Input
              data-testid="restock-purchase-date-input"
              type="date"
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                const thresholdDate = new Date("2023-11-30");
                setDate(e.target.value);
                if (selectedDate < thresholdDate) setValue("is_backfill", true);
                else setValue("is_backfill", false);
              }}
            />
          </FormControl>
        )}
      </Box>
      {!inventory.is_consumable ? (
        <Controller
          name="quantity"
          control={control}
          render={({ field }) => (
            <FormControl w="100%" isRequired={inventory.is_consumable}>
              <Flex
                justify="center"
                align="center"
                p={4}
                direction="column"
                gap={4}
                bg={useColorModeValue("gray.50", "gray.600")}
                borderRadius="sm">
                {formValues.purchase_line_item ? (
                  <VStack w={"100%"}>
                    <FormLabel>Quantity</FormLabel>
                    <Heading size="3xl">{field.value}</Heading>
                  </VStack>
                ) : (
                  <Flex align="center" gap={4} justify="space-between" minW={40}>
                    <IconButton
                      icon={<MinusIcon />}
                      aria-label="decrement"
                      colorScheme="red"
                      variant="outline"
                      borderRadius="full"
                      size="xs"
                      isDisabled={field.value <= 0 || disableForm}
                      onClick={() => field.onChange(field.value - 1)}
                    />
                    <Editable
                      defaultValue={field.value.toString()}
                      value={field.value.toString()}
                      isDisabled={disableForm}>
                      <Heading size="3xl">
                        <EditablePreview />
                      </Heading>
                      <Box>
                        <EditableInput
                          fontSize="4xl"
                          type="number"
                          w={32}
                          max={100}
                          onInput={(e) => {
                            const value = e.currentTarget.value;
                            if (value && !isNaN(Number(value)) && Number(value) <= 100) {
                              field.onChange(Number(value));
                            } else if (value === "") {
                              // Allow empty value temporarily
                              field.onChange("");
                            } else {
                              e.currentTarget.value = field.value.toString();
                            }
                          }}
                          onBlur={(e) => {
                            const value = e.target.value;
                            if (value === "" || isNaN(Number(value)) || Number(value) <= 0) {
                              field.onChange(1);
                              e.target.value = "1";
                            }
                          }}
                        />
                      </Box>
                    </Editable>
                    <IconButton
                      icon={<AddIcon />}
                      aria-label="increment"
                      colorScheme="teal"
                      variant="outline"
                      isDisabled={field.value >= 100 || disableForm}
                      borderRadius="full"
                      size="xs"
                      onClick={() => field.onChange(field.value + 1)}
                    />
                  </Flex>
                )}

                <Divider />
                <Text fontSize="sm">
                  Already in stock:{" "}
                  <Box as="span" fontWeight="semibold">
                    {inventory.stock || 0} units
                  </Box>
                </Text>
              </Flex>
            </FormControl>
          )}
        />
      ) : (
        <>
          {purchaseId && (
            <Text fontSize="lg" fontWeight="bold">
              Restocking {inventory.name} from Purchase #{purchaseId}
            </Text>
          )}
          <Box
            bg={useColorModeValue("gray.50", "gray.700")}
            borderRadius="md"
            maxWidth={"100%"}
            border="1px"
            borderColor="chakra-border-color"
            p={4}>
            <Text fontSize="lg" fontWeight="medium" pb={4}>
              Calculate Stock from Purchase Line Item
            </Text>
            <HStack>
              <Controller
                name="quantity"
                control={control}
                render={({ field }) => (
                  <FormControl isRequired>
                    <FormLabel>Qty Purchased</FormLabel>
                    <NumberInput
                      allowMouseWheel={false}
                      bgColor={useColorModeValue("white", "auto")}
                      min={1}
                      isDisabled={!!formValues.purchase_line_item || disableForm}
                      value={field.value}
                      onChange={(value) => field.onChange(parseInt(value))}>
                      <NumberInputField data-testid="restock-quantity-input" />

                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormControl>
                )}
              />
              <Controller
                name="total_quantity"
                control={control}
                rules={{
                  required: "Total quantity is required",
                  validate: (value) => {
                    const parsedValue = parseFloat(value);
                    return (
                      (!isNaN(parsedValue) && parsedValue > 0) ||
                      "Total quantity must be greater than 0"
                    );
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl isInvalid={!!error} isRequired>
                    <FormLabel>{inventory?.unit_of_measurement || "Amount"} per unit</FormLabel>
                    <NumberInput
                      {...field}
                      bgColor={useColorModeValue("white", "auto")}
                      allowMouseWheel={false}
                      isDisabled={disableForm}
                      min={1.0}
                      value={field.value || ""}
                      onChange={(valueString) => field.onChange(valueString)}
                      step={1.0}
                      precision={2}>
                      <NumberInputField data-testid="restock-total-quantity-input" />

                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                  </FormControl>
                )}
              />
              <FormControl>
                <FormLabel>Total Stock</FormLabel>
                <NumberInput
                  bgColor={useColorModeValue("white", "auto")}
                  allowMouseWheel={false}
                  isDisabled
                  value={(
                    Number(formValues.quantity || 0) * Number(formValues.total_quantity || 0)
                  ).toFixed(2)}>
                  <NumberInputField bgColor={useColorModeValue("white", "auto")} />
                </NumberInput>
              </FormControl>
            </HStack>
          </Box>
        </>
      )}

      <FormControl isRequired>
        <FormLabel>Choose Location</FormLabel>
        {showPreview ? (
          <LocationTreePreview pathToLocation={pathToLocation} setShowPreview={setShowPreview} />
        ) : (
          <TreeSelect
            testId="inventory-location-tree-select"
            options={treeSelectInventoryLocationsQuery.data || []}
            isLoading={treeSelectInventoryLocationsQuery.isLoading}
            isDisabled={disableForm}
            onSearchChanged={(value) => {
              setSearch(value);
            }}
            defaultValue={location}
            onChange={(value) => {
              if (value && value?.id) {
                setValue("option", value);
                setValue("inventory_location_id", value.id);
              }
            }}
            renderOption={(option) => defaultOption(option)}
          />
        )}
        <Text fontSize="sm" fontWeight="light" mt={2}>
          <WarningIcon color="orange.500" /> Please ensure that you deliver the items to this
          location.
        </Text>
      </FormControl>
      <Stack direction={{ base: "column", md: "row" }}>
        <Controller
          name="amount"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Unit Price</FormLabel>

              <MoneyInput
                {...field}
                data-testid="restock-unit-price-input"
                onChange={(value) => {
                  field.onChange(value);
                  setValue("total_estimated_cost", value.times(formValues.quantity));
                }}
                placeholder="Type here"
                isDisabled={!!formValues.purchase_line_item || disableForm}
              />
            </FormControl>
          )}
        />
        <Controller
          name="total_estimated_cost"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Total Estimated Value</FormLabel>
              <MoneyInput {...field} isDisabled />
            </FormControl>
          )}
        />
      </Stack>
      <Stack direction={{ base: "column", md: "row" }} spacing={4}>
        {inventory.track_lots && (
          <Controller
            name="lot_number"
            control={control}
            render={({ field }) => (
              <FormControl isRequired={inventory.track_lots}>
                <FormLabel>Lot Number</FormLabel>
                <Input {...field} placeholder="Type here" isDisabled={disableForm} />
              </FormControl>
            )}
          />
        )}
        {inventory.track_expiration_dates && (
          <Controller
            name="expiration_date"
            control={control}
            render={({ field }) => (
              <FormControl isRequired>
                <FormLabel>Expiration Date</FormLabel>
                <Input type="date" {...field} isDisabled={disableForm} />
              </FormControl>
            )}
          />
        )}
      </Stack>
    </Flex>
  );
};

const defaultOption = (option: LocationTreeKeyValue) => {
  const fontWeight = option.value?.storage_item ? "semibold" : "normal";
  return (
    <HStack>
      <Text fontWeight={fontWeight}>{option.label}</Text>
      {option?.value?.capacity_for_storage && <Tag colorScheme="teal">Available</Tag>}
    </HStack>
  );
};

export const LocationTreePreview = ({
  pathToLocation,
  setShowPreview,
}: {
  pathToLocation: LocationTreeKeyValue[] | undefined;
  setShowPreview: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Stack
      onClick={() => setShowPreview(false)}
      _hover={{ cursor: "pointer" }}
      direction="row"
      flexWrap="wrap"
      px={4}
      py={2}
      border="1px"
      borderRadius="md"
      borderColor="chakra-border-color">
      {pathToLocation?.map((location, idx) => {
        const isLast = idx === pathToLocation?.length - 1;
        return (
          <Box key={`destination-${location.label}-${location.value?.id}`}>
            <HStack>
              <Text
                color={isLast ? "teal.500" : "auto"}
                fontWeight={isLast ? "semibold" : "normal"}>
                {location.label}
              </Text>
              {idx < (pathToLocation?.length || 1) - 1 && <ArrowForwardIcon />}
            </HStack>
          </Box>
        );
      })}
    </Stack>
  );
};
