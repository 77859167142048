import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Heading,
  Spinner,
  HStack,
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import {
  invalidateBudgetGroup,
  useArchiveBudgetGroup,
  useGetBudgetGroup,
  useGetBudgetGroupEvents,
  useGetBudgetGroupMonthlySpend,
  useGetBudgetGroupSummary,
  useGetBudgetGroupSummaryStats,
  useUpdateBudgetGroup,
} from "../../../../api/budget_groups";
import { useParams, Link } from "react-router-dom";
import { ConfirmationButton, EditableText, Facets, SplitPage } from "@sciencecorp/helix-components";
import { BudgetGroupSidebar } from "./BudgetGroupSidebar";
import { AssociatedBudgetsTable } from "./components/AssociatedBudgetsTable";
import { TransactionTable } from "./components/TransactionTable";
import { TimelineTable } from "../../../shared/TimelineTable";
import { BudgetActivityLinearBar } from "../components/BudgetActivityLinearBar";
import { DateTime } from "luxon";
import { Select, OptionBase } from "chakra-react-select";

import BudgetActivityBarGraph from "../components/BudgetActivityBarGraph";
import { useCurrentUserQuery, userHasRole } from "../../../../api/user";
import { BsArchive } from "react-icons/bs";
import { TimelineEventData } from "../../../../api/timeline_events";
import { ChevronRightIcon } from "@chakra-ui/icons";
import _ from "lodash";
import { CreateNewFundingPeriod } from "./components/CreateNewFundingPeriod";

export type TimeRange = { start_date: DateTime; end_date: DateTime; id: number };

const defaultTimelineFacets = {
  event_type: {
    options: [
      { key: "created", value: "created", selected: true },
      { key: "funded", value: "funded", selected: true },
      { key: "moved", value: "moved", selected: true },
      { key: "removed", value: "removed", selected: true },
      { key: "comment", value: "comment", selected: true },
      { key: "archived", value: "archived", selected: true },
      { key: "added", value: "added", selected: false },
    ],
  },
};

const inPreviousPeriod = (timePeriodSchedule: TimeRange | null) => {
  if (!timePeriodSchedule) return true;

  const now = DateTime.now().toMillis();
  if (now > timePeriodSchedule.end_date.endOf("day").toMillis()) return true;
  return false;
};

export const BudgetGroup = () => {
  const { id } = useParams();
  const currentUserQuery = useCurrentUserQuery();
  const isFinanceAdmin = userHasRole(currentUserQuery, "finance_admin") || false;
  const { data: budgetGroup, isLoading, isError, error } = useGetBudgetGroup(Number(id));
  const { mutate: archiveBudgetGroup, isLoading: isLoadingArchive } = useArchiveBudgetGroup();
  const { mutate: updateBudgetGroup, isLoading: isLoadingUpdate } = useUpdateBudgetGroup(
    Number(id)
  );

  const isBudgetGroupLead = currentUserQuery.data?.id === budgetGroup?.lead.user.id || false;

  const [timePeriodSchedule, setTimePeriodSchedule] = useState<TimeRange | null>(null);
  const eventsQuery = useGetBudgetGroupEvents(Number(id));
  const { data: summaryStats, isLoading: isLoadingSummaryStats } = useGetBudgetGroupSummaryStats(
    Number(id),
    timePeriodSchedule?.id
  );

  const budgetGroupSummaryQuery = useGetBudgetGroupSummary(Number(id), timePeriodSchedule?.id);

  const timeRangeOptions = useMemo(
    () =>
      budgetGroup?.time_range_options.map((option) => ({
        label: `${option.start_date.toFormat("LLL dd yyyy")} - ${option.end_date.toFormat(
          "LLL dd yyyy"
        )}`,
        value: option.id,
        start_date: option.start_date,
        end_date: option.end_date,
      })) || [],
    [budgetGroup]
  );

  const { data: monthlySpend } = useGetBudgetGroupMonthlySpend(Number(id), timePeriodSchedule?.id);

  const [timelineFacets, setTimelineFacets] = useState<Facets>(defaultTimelineFacets);
  const [timelineEvents, setTimelineEvents] = useState<TimelineEventData[]>([]);

  useEffect(() => {
    if (budgetGroup?.time_range_options) {
      const currentPeriod =
        budgetGroup.time_range_options.find(
          (option) => option.id === budgetGroup.budget_group_schedule?.id
        ) || budgetGroup.time_range_options[0];
      setTimePeriodSchedule(currentPeriod || null);
    }
  }, [budgetGroup]);

  useEffect(() => {
    if (eventsQuery.data) {
      facetOnChange(defaultTimelineFacets);
    }
  }, [eventsQuery.data]);

  const facetOnChange = useCallback(
    (facets) => {
      setTimelineFacets(facets);
      if (eventsQuery.data) {
        let filteredEvents: TimelineEventData[] = eventsQuery.data;
        for (const facet in facets) {
          const selectedOptions = facets[facet]?.options?.filter((option) => option.selected) ?? [];

          if (selectedOptions.length > 0) {
            const selectedValues = selectedOptions.map((option) => option.value);
            filteredEvents = filteredEvents.filter((event) =>
              selectedValues.includes(event[facet])
            );
          }
        }
        setTimelineEvents(filteredEvents);
      }
    },
    [eventsQuery.data]
  );

  if (isLoading) return <Spinner />;

  if (isError)
    return (
      <Text>
        Error loading Budget Group. Error:{" "}
        {error instanceof Error ? error.message : "An error occurred"}{" "}
      </Text>
    );

  return (
    <Flex direction="column" gap={6}>
      {budgetGroup.archived_at && (
        <Alert status="error">
          <AlertIcon />
          This group is archived and can no longer be edited.
        </Alert>
      )}
      {!budgetGroup.budget_group_schedule && (
        <Alert status="error">
          <AlertIcon />
          This group does not have funding period for the current date. Please add a new funding
          period.
        </Alert>
      )}
      <Breadcrumb spacing={2} separator={<ChevronRightIcon />} color="teal">
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/finance/budgets">
            Budgets
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.500">
          <BreadcrumbLink>{budgetGroup.name}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <HStack w="100%" justify="space-between">
        <Heading>
          <Box w="max-content">
            <EditableText
              defaultValue={budgetGroup.name}
              persistentEdit={true}
              isLoading={isLoadingUpdate}
              disabled={!isFinanceAdmin || !!budgetGroup.archived_at}
              onSubmit={(value) => {
                if (value) updateBudgetGroup({ id: budgetGroup.id, name: value.toString() });
              }}
            />
          </Box>
        </Heading>
        <HStack>
          <Box zIndex={2}>
            <Select
              useBasicStyles
              options={timeRangeOptions}
              value={timeRangeOptions.find((option) => option.value === timePeriodSchedule?.id)}
              placeholder="Select a time period"
              onChange={(option) => {
                if (option) {
                  setTimePeriodSchedule({
                    id: option.value,
                    start_date: option.start_date,
                    end_date: option.end_date,
                  });
                } else {
                  setTimePeriodSchedule(null);
                }
              }}
            />
          </Box>
          <CreateNewFundingPeriod budgetGroup={budgetGroup} />
          <ConfirmationButton
            leftIcon={<BsArchive />}
            variant="Button"
            colorScheme="red"
            isLoading={isLoadingUpdate}
            onConfirm={() => archiveBudgetGroup(budgetGroup.id)}
            isDisabled={
              !isFinanceAdmin || !!budgetGroup.archived_at || !budgetGroup.can_be_archived
            }
            buttonVariant="outline"
            label="Archive Group"
            confirmationHeader="Archive Budget Group"
            confirmationButtonLabel="Archive"
            children="Are you sure you want to archive this budget group?"
          />
        </HStack>
      </HStack>
      <SplitPage
        sidebarWidth="350px"
        sidebarWidthXL="400px"
        breakpoint="lg"
        sidebar={
          <BudgetGroupSidebar
            budgetGroup={budgetGroup}
            summaryQuery={budgetGroupSummaryQuery}
            isFinanceAdmin={isFinanceAdmin}
            isBudgetGroupLead={isBudgetGroupLead}
            showingCurrentPeriod={!inPreviousPeriod(timePeriodSchedule)}
            startDate={timePeriodSchedule?.start_date}
            endDate={timePeriodSchedule?.end_date}
          />
        }
        main={
          <Flex direction="column" gap={6}>
            <Box p={6} border="1px" borderRadius="md" borderColor="chakra-border-color">
              <Heading size="md" mb={3}>
                Spend
              </Heading>
              {isLoadingSummaryStats ? (
                <Spinner />
              ) : (
                <BudgetActivityLinearBar
                  summary={budgetGroupSummaryQuery.data}
                  spendByModel={summaryStats?.expenditure_spend_summary}
                  highestRecords={summaryStats?.highest_spend_and_committed_records}
                  showingPreviousPeriod={inPreviousPeriod(timePeriodSchedule)}
                  budgetGroupId={budgetGroup.id}
                  endDate={timePeriodSchedule?.end_date}
                />
              )}

              {monthlySpend && <BudgetActivityBarGraph monthlySpend={monthlySpend || {}} />}
            </Box>
            <AssociatedBudgetsTable
              isFinanceAdmin={isFinanceAdmin}
              budgetGroup={budgetGroup}
              timePeriodId={timePeriodSchedule?.id}
            />
            <TransactionTable
              queryFilters={{ budget_group_id: budgetGroup.id }}
              canDownload={isBudgetGroupLead || isFinanceAdmin}
            />
            <Flex direction="column" gap={3}>
              <HStack justify="space-between">
                <Heading size="md">Timeline</Heading>
                <Box alignSelf="end">
                  <Facets variant="button" facets={timelineFacets} onChange={facetOnChange} />
                </Box>
              </HStack>
              {eventsQuery.isLoading ? (
                <Spinner />
              ) : eventsQuery.isError ? (
                <Text>Error loading budget group events</Text>
              ) : (
                <>
                  <TimelineTable
                    timelineable_id={budgetGroup.id}
                    timelineable_type={"BudgetGroup"}
                    events={timelineEvents}
                    disableCommentBox={!!budgetGroup.archived_at}
                    onComment={invalidateBudgetGroup(Number(id), "events")}
                  />
                </>
              )}
            </Flex>
          </Flex>
        }
      />
    </Flex>
  );
};
