import React, { useState } from "react";
import { SplitPage, Header, Searchbar, Facets, useCollection } from "@sciencecorp/helix-components";
import {
  Center,
  Flex,
  Spinner,
  Text,
  Box,
  VStack,
  Button,
  Grid,
  GridItem,
  IconButton,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  HStack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import {
  useArchiveCapitalEquipment,
  useDeleteCapitalEquipment,
  useGetCapitalEquipment,
  useSearchCapitalEquipments,
  useUpdateCapitalEquipment,
} from "../../../../api/capital_equipment";
import { useParams, useNavigate } from "react-router";
import { SpendingCard } from "../../../Budgets/SpendingCard";
import { ArrowBackIcon, ArrowForwardIcon, ChevronRightIcon, DeleteIcon } from "@chakra-ui/icons";
import { IoDocumentTextOutline } from "react-icons/io5";
import { ManufacturerInfo } from "./ManufacturerInfo";
import { TbMoneybag } from "react-icons/tb";
import { HiOutlineWrenchScrewdriver } from "react-icons/hi2";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { BsArchive, BsThreeDotsVertical } from "react-icons/bs";
import { FaPrint } from "react-icons/fa";
import { MaintenanceTab } from "../MaintenanceTab";
import { CgNotes } from "react-icons/cg";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";
import { printBarcodes } from "../../../Inventory/Inventories/components/RestockModalComponents/util";
import { Attachments } from "./Attachments";
import { CapitalEquipmentInfo } from "./CapitalEquipmentInfo";
import { CapitalEquipmentNotes } from "./CapitalEquipmentNotes";
import { CapitalEquipmentVendorInformation } from "./CapitalEquipmentVendorInformation";
import { EquipmentStatus } from "./EquipmentStatus";
import { ServiceAndUtilityRequirements } from "./ServiceAndUtilityRequirements";

export const CapitalEquipmentDetails = () => {
  const { id, tab } = useParams();
  const { facets, onFacets, filters, pagination, onPagination, resetFacets } = useCollection();
  const {
    data: capitalEquipment,
    isLoading: isLoadingCapitalEquipment,
    isError,
  } = useGetCapitalEquipment(Number(id));
  const { search, debouncedSearch } = useDebouncedSearch();
  const { mutateAsync: updateTool, isLoading: isLoadingUpdateTool } = useUpdateCapitalEquipment(
    Number(id)
  );
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const { mutateAsync: archiveTool, isLoading: isLoadingArchiveTool } = useArchiveCapitalEquipment(
    Number(id)
  );
  const { data: otherCapitalEquipment, isLoading: isLoadingOtherCapitalEquipment } =
    useSearchCapitalEquipments({
      term: search || "*",
      aggs: [],
      filters,
      pagination,
    });
  const { mutateAsync: deleteCapEquip, isLoading: isLoadingDeleteCapEquip } =
    useDeleteCapitalEquipment();
  const toast = useToast();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(tab?.toString().toLowerCase() || "details");
  const isDisabled =
    capitalEquipment?.status === "sold" ||
    capitalEquipment?.status === "archived" ||
    capitalEquipment?.status === "decomissioned";

  if (isLoadingCapitalEquipment)
    return (
      <Center h="90vh">
        <Spinner />
      </Center>
    );

  if (isError) return <Text>Failed to load capital equipment</Text>;

  const handlePrevCapitalEquipment = () => {
    navigate(`/equipment/${capitalEquipment?.previous_active_capital_equipment_id}/details`);
  };

  const handleNextCapitalEquipment = () => {
    navigate(`/equipment/${capitalEquipment?.next_active_capital_equipment_id}/details`);
  };

  const qrCodeToPrint = [
    {
      barcode: capitalEquipment.barcode,
      id: capitalEquipment.id.toString(),
    },
  ];

  const handlePrintBarcodes = () => {
    if (!qrCodeToPrint[0].barcode) {
      toast({
        title: "Error Printing QR Codes",
        description: `There are no available items to print QR codes for`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      printBarcodes(qrCodeToPrint);
    }
  };

  const tabList = [
    {
      label: "Details",
      value: "details",
      icon: <IoDocumentTextOutline />,
    },
    {
      label: "Budgets",
      value: "budgets",
      icon: <TbMoneybag />,
    },
    {
      label: "Maintenance",
      value: "maintenance",
      icon: <HiOutlineWrenchScrewdriver />,
    },
  ];

  return (
    <Flex width="100%" direction="column" gap={4}>
      <Header
        title={capitalEquipment.name}
        isEditable={capitalEquipment.can_act_as_lead}
        editableTextProps={{
          onSubmit: (value) =>
            updateTool({
              name: value?.toString() || capitalEquipment.name,
              id: capitalEquipment.id,
            }),
        }}
        crumbs={[{ label: "Equipment", url: "/inventory/equipment" }]}
        crumbsColor="teal"
        badgeDropdown={
          <EquipmentStatus
            capitalEquipment={capitalEquipment}
            isLoadingUpdateTool={isLoadingUpdateTool}
            updateTool={updateTool}
          />
        }
        actions={[
          <>
            <Button leftIcon={<FaPrint />} variant="solid" onClick={handlePrintBarcodes}>
              Print QR Code
            </Button>
            <Popover>
              {({ isOpen }) => (
                <>
                  <PopoverTrigger>
                    <IconButton
                      aria-label="other-equipment"
                      colorScheme={isOpen ? "teal" : undefined}
                      icon={<CgNotes />}
                    />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverCloseButton />
                    <PopoverBody overflowY={"scroll"} height={"60vh"}>
                      <Text fontWeight={"semibold"} p={2} mb={2}>
                        Other Equipment
                      </Text>
                      <HStack marginBottom={2}>
                        <Searchbar
                          onChange={(value) => {
                            debouncedSearch(value);
                          }}
                          onSelect={() => {}}
                          width="100%"
                        />
                        <Box display={"flex"} justifyContent="flex-end" w="100%">
                          <Facets variant="button" facets={facets} onChange={onFacets} background />
                        </Box>
                      </HStack>
                      {otherCapitalEquipment?.results.map((equipment) => (
                        <Flex
                          key={equipment.id + equipment.name}
                          direction={"row"}
                          alignItems={"center"}
                          _hover={{ bgColor: bgColor }}
                          cursor={"pointer"}
                          justifyContent={"space-between"}
                          onClick={() => {
                            resetFacets();
                            navigate(`/equipment/${equipment.id}/details`);
                          }}>
                          <Box p={4} w={"100%"}>
                            {equipment.name}
                          </Box>
                          <ChevronRightIcon />
                        </Flex>
                      ))}
                    </PopoverBody>
                  </PopoverContent>
                </>
              )}
            </Popover>

            <IconButton
              aria-label="move-left-equipment"
              icon={<ArrowBackIcon />}
              onClick={handlePrevCapitalEquipment}
            />
            <IconButton
              aria-label="move-right-equipment"
              icon={<ArrowForwardIcon />}
              onClick={handleNextCapitalEquipment}
            />
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    as={IconButton}
                    icon={<BsThreeDotsVertical />}
                    size="md"
                    aria-label="More actions"
                    isActive={isOpen}
                  />
                  {capitalEquipment.can_act_as_lead && (
                    <MenuList>
                      {!capitalEquipment.can_be_deleted ? (
                        <MenuItem
                          onClick={() => {
                            archiveTool(Number(id), {
                              onSuccess: () => navigate("/inventory/equipment"),
                            }).then(() => {
                              toast({
                                title: "Equipment archived successfully",
                                status: "success",
                              });
                            });
                          }}>
                          <BsArchive style={{ marginRight: "6" }} />
                          Archive Equipment
                        </MenuItem>
                      ) : (
                        <MenuItem
                          onClick={() =>
                            deleteCapEquip(Number(id)).then(() => {
                              toast({
                                title: "Equipment deleted successfully",
                                status: "success",
                              });
                              navigate("/inventory/equipment");
                            })
                          }>
                          <DeleteIcon style={{ marginRight: "6" }} />
                          Delete Equipment
                        </MenuItem>
                      )}
                    </MenuList>
                  )}
                </>
              )}
            </Menu>
          </>,
        ]}
      />
      <Box overflowX="scroll">
        <SplitPage
          sidebarWidth="min-content"
          sidebar={
            <VStack spacing={3}>
              {tabList.map(({ label, value, icon }) => (
                <Button
                  key={value}
                  size="md"
                  width="100%"
                  justifyContent="start"
                  colorScheme={activeTab === value ? "teal" : "gray"}
                  bg={activeTab === value ? "teal.500" : "transparent"}
                  leftIcon={icon}
                  onClick={() => {
                    setActiveTab(value);
                    navigate(`/equipment/${id}/${value}`);
                  }}>
                  {label}
                </Button>
              ))}
            </VStack>
          }
          main={
            activeTab === "details" ? (
              <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap={6}>
                <GridItem>
                  <CapitalEquipmentNotes
                    isDisabled={isDisabled}
                    notes={capitalEquipment.notes || ""}
                    updateNotes={(value: string) =>
                      updateTool({ notes: value, id: capitalEquipment.id })
                    }
                    isLoading={isLoadingUpdateTool}
                  />
                </GridItem>
                <GridItem>
                  <Attachments
                    data={capitalEquipment}
                    isManager
                    type="capital_equipment"
                    isDisabled={isDisabled}
                  />
                </GridItem>
                <GridItem>
                  <CapitalEquipmentInfo
                    capitalEquipment={capitalEquipment}
                    isDisabled={isDisabled}
                  />
                </GridItem>
                <GridItem>
                  <CapitalEquipmentVendorInformation
                    data={capitalEquipment}
                    isManager={true}
                    type="CapitalEquipment"
                    isDisabled={isDisabled}
                  />
                </GridItem>
                <GridItem>
                  <ServiceAndUtilityRequirements
                    capitalEquipment={capitalEquipment}
                    isDisabled={isDisabled}
                  />
                </GridItem>
                <GridItem>
                  <ManufacturerInfo capitalEquipment={capitalEquipment} isDisabled={isDisabled} />
                </GridItem>
              </Grid>
            ) : activeTab === "budgets" ? (
              <SpendingCard
                budgetId={capitalEquipment.budget_id}
                editable={capitalEquipment.can_act_as_lead}
                location="capitalEquipment"
                budgetableName={capitalEquipment.name}
              />
            ) : (
              <MaintenanceTab capitalEquipment={capitalEquipment} />
            )
          }
        />
      </Box>
    </Flex>
  );
};
