import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { zodParse } from "./zodParse";
import { searchParams } from "./collection_types";
import { indexParams } from "./collection_types";
import { SearchParams } from "./collection_types";
import { createSearchResponseSchema } from "./shared";
import { userMinimalSchema } from "./user";
import { formSchema } from "./form_builder";
import { pollResponseTransformSchema } from "./poll_responses";

export const POLLS_BASE_URL = "polls";

export const pollSchema = z.object({
  id: z.number(),
  confidentiality: z.string(),
  preview_response: z.boolean(),
  start_date: z.string().nullable(),
  end_date: z.string().nullable(),
  user: z.lazy(() => userMinimalSchema),
  category: z.string(),
  status: z.string(),
  form: z.lazy(() => formSchema),
});

export type PollCreateParams = {
  category: string;
  confidentiality: string;
  preview_response: boolean;
};

export type PollResponseTransformParams = {
  poll_id: number;
  preview_response: boolean;
  group_type: string | null;
  group_id: number | null;
};

export type PollData = z.infer<typeof pollSchema>;

export const getPoll = async (id: number) => {
  const result = await api.get(`${POLLS_BASE_URL}/${id}`);
  return zodParse(pollSchema, result.data);
};

export const getPollResponses = async (poll_details: PollResponseTransformParams) => {
  try {
    const result = await api.post(`${POLLS_BASE_URL}/${poll_details.poll_id}/responses`, {
      group_type: poll_details.group_type,
      group_id: poll_details.group_id,
    });
    const responseData = result.data?.[0];

    if (responseData) {
      return zodParse(pollResponseTransformSchema, responseData);
    }
    console.warn("Poll response data is undefined.");
    return null;
  } catch (error) {
    console.error("Error fetching poll responses:", error);
    throw new Error("Failed to fetch or parse poll responses.");
  }
};

export const createPoll = async (poll: PollCreateParams): Promise<PollData> => {
  const result = await api.post(POLLS_BASE_URL, { poll });
  return zodParse(pollSchema, result.data);
};

export const updatePoll = async (poll: Partial<PollData> & Pick<PollData, "id">) => {
  const result = await api.put(`${POLLS_BASE_URL}/${poll.id}`, { poll });
  return zodParse(pollSchema, result.data);
};

export const deletePoll = async (id: number) => {
  await api.delete(`${POLLS_BASE_URL}/${id}`);
};

const searchPolls = async ({
  aggs,
  bodyOptions,
  filters,
  pagination,
  order,
  term,
}: SearchParams) => {
  const path = [POLLS_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, bodyOptions, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });

  return zodParse(createSearchResponseSchema(pollSchema), result.data);
};

export const invalidatePolls = (id?: number, subquery?: string) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [POLLS_BASE_URL];
  if (id) queryKey.push(id);

  return async () => {
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
  };
};

export const useSearchPolls = (params: SearchParams) => {
  return useQuery({
    queryKey: [POLLS_BASE_URL, params],
    queryFn: () => searchPolls(params),
  });
};

export const useGetPoll = (id: number) => {
  return useQuery({
    queryKey: [POLLS_BASE_URL, id],
    queryFn: () => getPoll(id),
  });
};

export const useGetPollResponses = (poll_details: PollResponseTransformParams) => {
  return useQuery({
    queryKey: [POLLS_BASE_URL, poll_details],
    queryFn: () => getPollResponses(poll_details),
    enabled: !!poll_details.preview_response,
  });
};

export const useCreatePoll = () => {
  return useMutation({
    mutationFn: createPoll,
    onSuccess: invalidatePolls(),
  });
};

export const useUpdatePoll = (pollId: number) => {
  return useMutation({
    mutationFn: updatePoll,
    onSuccess: invalidatePolls(pollId),
  });
};

export const useDeletePoll = (pollId: number) => {
  return useMutation({
    mutationFn: deletePoll,
    onSuccess: invalidatePolls(pollId),
  });
};
