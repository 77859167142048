import _ from "lodash";
import React from "react";
import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Heading,
  HStack,
  VStack,
  Link,
  Skeleton,
} from "@chakra-ui/react";
import { AddIcon, InfoIcon } from "@chakra-ui/icons";
import { EmptyState } from "../../../shared/EmptyState";
import { useCurrentUserQuery, userHasRole } from "../../../../api/user";
import { ReimbursementLineItemShowData } from "../../../../api/reimbursement_line_items";
import { ReimbursementShowData, useGetReimbursementLineItems } from "../../../../api/reimbursement";
import { AddExpenseToReimbursementModal } from "./AddExpenseToReimbursementModal";
import ReimbursementCardItem from "./ReimbursementCardItem";
import { MoneyText } from "../../../MoneyText";

type EditReimbursementLineItemProps = {
  reimbursement?: ReimbursementShowData;
};

export const EditReimbursementLineItemList = ({
  reimbursement,
}: EditReimbursementLineItemProps) => {
  if (!reimbursement) {
    return (
      <Skeleton width={"100%"} height={"400px"}>
        {" "}
      </Skeleton>
    );
  }
  const lineItemsQuery = useGetReimbursementLineItems(reimbursement.id);
  const userQuery = useCurrentUserQuery();
  const isFinanceAdmin = userHasRole(userQuery, "finance_admin");

  if (userQuery.isSuccess && lineItemsQuery.isSuccess) {
    return (
      <ReimbursementLineItemTable
        reimbursement={reimbursement}
        reimbursementId={reimbursement.id}
        approvalStatus={reimbursement.approval_status}
        lineItems={lineItemsQuery.data}
        isFinanceAdmin={isFinanceAdmin || false}
      />
    );
  } else if (userQuery.isLoading || lineItemsQuery.isLoading) {
    return (
      <Skeleton width={"100%"} height={"400px"}>
        {" "}
      </Skeleton>
    );
  } else {
    return <Text>Error loading user data</Text>;
  }
};

type ReimbursementLineItemTableProps = {
  reimbursementId: number;
  reimbursement: ReimbursementShowData;
  approvalStatus?: string;
  lineItems: ReimbursementLineItemShowData[];
  isFinanceAdmin: boolean;
};

const ReimbursementLineItemTable = ({
  lineItems,
  reimbursement,
}: ReimbursementLineItemTableProps) => {
  const {
    onOpen: onOpenAddExpenseToReimbursement,
    onClose: onCloseAddExpenseToReimbursement,
    isOpen: isOpenAddExpenseToReimbursement,
  } = useDisclosure();

  const currentUser = useCurrentUserQuery();

  const editable =
    currentUser.data?.id === reimbursement?.user.id && reimbursement.approval_status === "draft";

  return (
    <>
      <Flex flexDirection="column" width="100%" gap={4}>
        {reimbursement.reimbursement_type === "mileage" && (
          <HStack alignItems={"center"}>
            <InfoIcon boxSize={"20px"} color={"teal"} />
            <VStack align="start" marginLeft={"2"}>
              <Text>
                Mileage reimbursements require both a report from your free{" "}
                <Link color="teal" href={"https://dashboard.mileiq.com/"} isExternal>
                  MileIQ account
                </Link>{" "}
                and corresponding receipts.
              </Text>
            </VStack>
          </HStack>
        )}
        {lineItems?.length ? (
          lineItems.map((lineItem) => (
            <ReimbursementCardItem
              key={lineItem.id}
              reimbursement={reimbursement}
              reimbursement_line_item={lineItem}
            />
          ))
        ) : (
          <EmptyState title="Add an expense to get started" size="xs" />
        )}

        <Flex alignItems={"center"} justifyContent={"center"} width={"100%"}>
          {reimbursement.reimbursement_type === "mileage" && lineItems?.length >= 1 ? (
            <></>
          ) : (
            <Button
              data-testid={"add-expense-button"}
              size="sm"
              leftIcon={<AddIcon />}
              onClick={onOpenAddExpenseToReimbursement}
              flexShrink={1}
              isDisabled={!editable}>
              Add Expense
            </Button>
          )}
        </Flex>
      </Flex>

      {isOpenAddExpenseToReimbursement && (
        <AddExpenseToReimbursementModal
          mode="create"
          reimbursementId={reimbursement.id}
          isOpen={isOpenAddExpenseToReimbursement}
          onClose={onCloseAddExpenseToReimbursement}
        />
      )}
      <Flex
        flex="1"
        direction="row"
        p={6}
        border={"1px"}
        bg={useColorModeValue("gray.50", "gray.700")}
        borderColor="chakra-border-color"
        borderRadius="md"
        h="100%"
        w="100%"
        alignItems="center"
        justifyContent={"space-between"}>
        <Heading size="sm" fontWeight="medium">
          Amount Requested
        </Heading>
        <Heading size="md">
          <MoneyText money={reimbursement?.amount} formatOptions={{ compact: "never" }} />
        </Heading>
      </Flex>
    </>
  );
};
