import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { zodParse } from "./zodParse";
import { userMinimalSchema } from "./user";
import { CreateSubmissionData, formSubmissionSchema } from "./form_builder";

export const POLL_RESPONSES_BASE_URL = "poll_responses";

export const pollResponseSchema = z.object({
  id: z.number(),
  user: z.lazy(() => userMinimalSchema),
  poll_id: z.number(),
  form_submission: z.lazy(() => formSubmissionSchema).nullable(),
});

export const pollResponseTransformSchema = z.object({
  field_id: z.number(),
  poll_id: z.number(),
  field_label: z.string(),
  number_of_responses: z.number(),
  field_type: z.string(),
  responses: z.array(z.lazy(() => singlePollResponseSchema)),
});

export const singlePollResponseSchema = z.object({
  label: z.string(),
  count: z.number(),
});

export type PollResponseCreateParams = {
  poll_id: number;
  user_id: number;
  form_submission: CreateSubmissionData | null;
};

export const createPollResponse = async (poll_response: PollResponseCreateParams) => {
  const result = await api.post(POLL_RESPONSES_BASE_URL, poll_response);
  return zodParse(pollResponseSchema, result.data);
};

export const useCreatePollResponse = () => {
  return useMutation({
    mutationFn: createPollResponse,
    onSuccess: invalidatePollResponses(),
  });
};

export const invalidatePollResponses = (id?: number, subquery?: string) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [POLL_RESPONSES_BASE_URL];
  if (id) queryKey.push(id);

  return async () => {
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
  };
};
