import React from "react";
import { Box, Flex, BoxProps, Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/react";

type MultiProgressBarProps = {
  segments: {
    value: number;
    color: string;
    popoverContent?: JSX.Element | null;
    showPopover?: boolean;
  }[];
  boxProps?: BoxProps;
};

export const MultiProgressBar = ({ segments, boxProps }: MultiProgressBarProps) => {
  const renderedSegments: JSX.Element[] = [];
  let runningTotal = 0;

  for (let i = 0; i < segments.length; i++) {
    if (runningTotal >= 100) break;
    const segment = segments[i];
    const remaining = 100 - runningTotal;
    const width = Math.min(segment.value, remaining);
    runningTotal += width;

    const segmentBox: JSX.Element = <Box key={i} h="100%" width={`${width}%`} bg={segment.color} />;

    if (segment.showPopover && segment.popoverContent) {
      renderedSegments.push(
        <Popover placement="bottom" trigger="hover">
          <PopoverTrigger>{segmentBox}</PopoverTrigger>
          <PopoverContent>{segment.popoverContent}</PopoverContent>
        </Popover>
      );
    } else {
      renderedSegments.push(segmentBox);
    }
  }

  return (
    <Box {...boxProps} overflow="hidden">
      <Flex h="100%" width="100%">
        {renderedSegments}
      </Flex>
    </Box>
  );
};
