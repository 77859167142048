import React, { useState } from "react";
import {
  Circle,
  Heading,
  useColorModeValue,
  HStack,
  VStack,
  Text,
  Box,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Flex,
} from "@chakra-ui/react";
import { EmptyState, Select } from "@sciencecorp/helix-components";
import { useServiceAnalyticsOptions } from "../../../../api/options";
import { useGetServiceBreakdown, useGetTeamServiceRequestAnalytics } from "../../../../api/team";
import { MultiProgressBar } from "../../../shared/MultiProgressBar";
import { IoIosFlag } from "react-icons/io";
import { PRIORITY_OPTIONS } from "../../../Requests/components/ServiceRequestDetailsSidebar";
import { TimeIcon } from "@chakra-ui/icons";
import { Duration } from "luxon";

type ServiceAnalyticsProps = {
  teamId: number;
};

type BreakdownData = {
  [key: string]: BreakdownItem;
};

type BreakdownItem = {
  type_id: number;
  name: string;
  completed_count: number;
  overdue_count: number;
  in_progress: number;
  new: number;
  total: number;
  average_time_to_complete: number;
};

export const getFlagColor = (priority: number) => {
  return PRIORITY_OPTIONS.find((option) => option.value === priority)?.color || "gray";
};

export const ServiceAnalytics = ({ teamId }: ServiceAnalyticsProps) => {
  const analyticOptions = useServiceAnalyticsOptions();
  const [selectedFilter, setSelectedFilter] = useState<string>("category");
  const { data: analytics } = useGetTeamServiceRequestAnalytics(teamId);
  const { data: breakdown } = useGetServiceBreakdown(teamId);

  const breakdownData: BreakdownData = breakdown?.[selectedFilter] || {};

  const convertToDaysAndHours = (seconds: number) => {
    const duration = Duration.fromObject({ seconds });
    const days = Math.floor(duration.as("days"));
    const hours = duration.shiftTo("hours").hours % 24;
    return { days, hours };
  };

  const { days, hours } = convertToDaysAndHours(analytics?.average_time_to_complete || 0);

  const spendingProgressBarElements = (key: string) => {
    const entry = breakdown?.[selectedFilter]?.[key];
    if (!entry || entry.total === 0) {
      return [];
    }

    return [
      {
        value: (entry.in_progress / entry.total) * 100,
        color: "orange",
      },
      {
        value: (entry.overdue_count / entry.total) * 100,
        color: "red",
      },
      {
        value: (entry.new / entry.total) * 100,
        color: "gray",
      },
      { value: (entry.completed_count / entry.total) * 100, color: "teal" },
    ];
  };

  return (
    <VStack width="100%" mb={4}>
      <HStack justifyContent="space-between" width="100%" height="100%">
        <Heading size="md">Analytics</Heading>
        <Select
          options={analyticOptions}
          onChange={(value) => setSelectedFilter(value as string)}
          value={selectedFilter}
          placeholder="Select a filter"
        />
      </HStack>

      <Stack width="100%" height="100%" direction={["column", "column", "row"]}>
        {/* Left Section */}
        <HStack width={["100%", "100%", "50%"]} height="100%" paddingRight={[0, 0, 4]}>
          <VStack width="100%" spacing={4}>
            <VStack
              width="100%"
              align="center"
              p={4}
              border="1px"
              borderColor="chakra-border-color"
              borderRadius="md">
              <HStack width="100%" align="center">
                <Circle
                  size={12}
                  bgColor={useColorModeValue("green.100", "green.200")}
                  border="1px"
                  borderColor={useColorModeValue("green.500", "green.400")}>
                  <Text fontSize="xl" w="100%" textAlign="center">
                    🎉
                  </Text>
                </Circle>

                <Stat>
                  <StatNumber>{analytics?.total_requests}</StatNumber>
                  <StatLabel>Total Requests</StatLabel>
                </Stat>

                <Stat>
                  <StatNumber>{analytics?.total_completed_this_month}</StatNumber>
                  <StatLabel>Total Completed this month</StatLabel>
                </Stat>
              </HStack>
            </VStack>
            <StatGroup w="100%" justifyContent="space-between">
              {/* Open Requests */}
              <Stat>
                <StatLabel>Open</StatLabel>
                <StatNumber>{analytics?.open_requests}</StatNumber>
              </Stat>

              {/* Started Requests */}
              <Stat>
                <HStack>
                  <Box boxSize={2} borderRadius="full" bg="orange" />
                  <StatLabel>Started</StatLabel>
                </HStack>
                <StatNumber>{analytics?.started_requests}</StatNumber>
              </Stat>

              {/* Overdue Requests */}
              <Stat>
                <HStack>
                  <Box boxSize={2} borderRadius="full" bg="red" />
                  <StatLabel>Overdue</StatLabel>
                </HStack>
                <StatNumber>{analytics?.total_overdue}</StatNumber>
              </Stat>

              {/* Not Started Requests */}
              <Stat>
                <HStack>
                  <Box boxSize={2} borderRadius="full" bg="gray" />
                  <StatLabel>Not Started</StatLabel>
                </HStack>
                <StatNumber>{analytics?.not_started_requests}</StatNumber>
              </Stat>
            </StatGroup>

            {Object.entries(breakdownData).length > 0 ? (
              <VStack w="100%" align="start">
                <Text alignSelf="start" fontWeight="semibold" fontSize="sm">
                  Breakdown
                </Text>
                <Flex direction="column" w="100%">
                  {Object.entries(breakdownData).map(([key, item], idx) => {
                    return (
                      <HStack
                        key={key}
                        width="100%"
                        borderBottom="1px"
                        borderColor="chakra-border-color"
                        gap={4}>
                        <HStack
                          width="100%"
                          paddingY={3}
                          paddingRight={3}
                          borderRight="1px"
                          borderColor="gray.200"
                          height="100%">
                          <Text w="100%" alignSelf="start" fontSize="sm">
                            {item.name || "No Priority"}
                          </Text>
                          {selectedFilter === "priority" && (
                            <Box height="100%" width="100%" alignItems="center">
                              <IoIosFlag color={getFlagColor(item.type_id)} />
                            </Box>
                          )}
                        </HStack>
                        <Box h="100%" w="100%">
                          <MultiProgressBar
                            segments={spendingProgressBarElements(key)}
                            boxProps={{ height: 3, width: "100%" }}
                          />
                        </Box>
                        <Text width="100%" fontSize="sm">
                          <Box as="span" fontWeight="bold">
                            {item.completed_count}
                          </Box>{" "}
                          / {item.total} completed
                        </Text>
                      </HStack>
                    );
                  })}
                </Flex>
              </VStack>
            ) : (
              <EmptyState title="No breakdown data available" size="sm" />
            )}
          </VStack>
        </HStack>
        {/* Right Section */}
        <HStack width={["100%", "100%", "50%"]} height="100%" alignItems={"flex-start"}>
          <VStack width="100%" paddingLeft={[0, 0, 4]}>
            <HStack
              width="100%"
              p={4}
              border="1px"
              borderColor="chakra-border-color"
              borderRadius="md">
              <TimeIcon boxSize="8" marginRight={4} />
              <Stat>
                <StatNumber>
                  {days}{" "}
                  <Box as="span" fontSize="md">
                    days
                  </Box>{" "}
                  {hours.toFixed(0)}{" "}
                  <Box as="span" fontSize="md">
                    hours
                  </Box>
                </StatNumber>
                <StatLabel>Average Time to Closure</StatLabel>
              </Stat>
            </HStack>

            <Text alignSelf="start" fontWeight="semibold" fontSize="sm" mt={4}>
              Average Time to Closure
            </Text>
            {Object.entries(breakdownData).length > 0 ? (
              <VStack w="100%" align="start" mt={4}>
                {Object.entries(breakdownData).map(([key, item], idx) => (
                  <HStack width="100%" p={2} alignItems="center" key={key}>
                    <Stack position="relative">
                      {idx !== Object.entries(breakdownData).length - 1 && (
                        <Box
                          position="absolute"
                          height="100%"
                          bg="gray.200"
                          width={0.5}
                          left={2}
                          top={6}
                          zIndex={-1}
                        />
                      )}
                      <HStack alignItems="start">
                        <Box>
                          <TimeIcon boxSize="5" color="gray.500" />
                        </Box>
                        <VStack alignItems="start" gap={0} marginLeft={4}>
                          <Text fontWeight="medium">
                            {convertToDaysAndHours(item.average_time_to_complete).days} days{" "}
                            {convertToDaysAndHours(item.average_time_to_complete).hours.toFixed(0)}{" "}
                            hours
                          </Text>
                          <HStack>
                            <Text w="100%" alignSelf="start" fontSize="sm" color="gray.500">
                              {item.name ? item.name : "No Priority"}
                            </Text>
                            {selectedFilter === "priority" && (
                              <Box fontSize="sm" height="100%" width="100%" alignItems="center">
                                <IoIosFlag color={getFlagColor(item.type_id)} />
                              </Box>
                            )}
                          </HStack>
                        </VStack>
                      </HStack>
                    </Stack>
                  </HStack>
                ))}
              </VStack>
            ) : (
              <EmptyState title="No breakdown data available" size="sm" />
            )}
          </VStack>
        </HStack>
      </Stack>
    </VStack>
  );
};
