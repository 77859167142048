import React from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ServicePartialData } from "../../../../api/services";
import { VscNewFile } from "react-icons/vsc";
import { AddIcon } from "@chakra-ui/icons";
import { RiInputField } from "react-icons/ri";
import { FaCheck } from "react-icons/fa6";
import { CiText, CiCalendar } from "react-icons/ci";
import { BsCheck2All } from "react-icons/bs";
import { HiSelector } from "react-icons/hi";
import { FaArrowUp } from "react-icons/fa";
import { DebouncedFunc } from "lodash";
import { useDebounced } from "../../../hooks/useDebouncedUpdate";
import {
  FormFieldTypes,
  useCopyActiveTemplate,
  useCreateTemplateField,
  usePublishTemplate,
  useReorderTemplateFields,
} from "../../../../api/form_builder";
import { IconType } from "react-icons";
import { FormField } from "./FormField";
import _ from "lodash";
import { PollData } from "../../../../api/polls";

export type FormFieldOptionType = {
  label: string;
  icon: IconType;
  color: string;
  value: FormFieldTypes;
};

export const formFieldOptions: FormFieldOptionType[] = [
  { label: "Short Text", icon: RiInputField, color: "green.500", value: "Form::Field::ShortText" },
  { label: "Long Text", icon: CiText, color: "purple.500", value: "Form::Field::LongText" },
  {
    label: "Single Select",
    icon: FaCheck,
    color: "gray.500",
    value: "Form::Field::SingleSelect",
  },
  {
    label: "Multi Select",
    icon: BsCheck2All,
    color: "orange.500",
    value: "Form::Field::MultiSelect",
  },
  { label: "Number", icon: HiSelector, color: "teal.500", value: "Form::Field::Number" },
  { label: "Date", icon: CiCalendar, color: "red.500", value: "Form::Field::Date" },
  { label: "File Upload", icon: FaArrowUp, color: "blue.500", value: "Form::Field::File" },
];

export const pollFormFieldOptions: FormFieldOptionType[] = [
  { label: "Short Text", icon: RiInputField, color: "green.500", value: "Form::Field::ShortText" },
  { label: "Long Text", icon: CiText, color: "purple.500", value: "Form::Field::LongText" },
  {
    label: "Single Select",
    icon: FaCheck,
    color: "gray.500",
    value: "Form::Field::SingleSelect",
  },
  {
    label: "Multi Select",
    icon: BsCheck2All,
    color: "orange.500",
    value: "Form::Field::MultiSelect",
  },
];

type FormBuilderProps = {
  item: ServicePartialData | PollData;
  type: "service" | "poll";
  invalidateFunction: () => Promise<void>;
};

export const FormBuilder = ({ item, type, invalidateFunction }: FormBuilderProps) => {
  const { mutateAsync: createField } = useCreateTemplateField();
  const { mutateAsync: publishTemplate } = usePublishTemplate();
  const { mutateAsync: copyActiveTemplate } = useCopyActiveTemplate();

  const currTemplate = (item.form.draft_template || item.form.active_template) as NonNullable<
    typeof item.form.active_template
  >;

  const fieldOptions =
    type === "service" ? formFieldOptions : type === "poll" ? pollFormFieldOptions : [];

  const errors = _.groupBy(item.form.draft_template?.publishing_errors, "field_id");

  const { mutateAsync: reorderTemplateFields, isLoading: isLoadingReorder } =
    useReorderTemplateFields();

  const reorderTemplateFieldsDebounced: DebouncedFunc<typeof reorderTemplateFields> = useDebounced(
    reorderTemplateFields,
    [item.id, currTemplate.id],
    1000
  );

  const handleMoveUp = (id: number) => {
    const index = currTemplate.fields.findIndex((field) => field.id === id);
    const newFields = [...currTemplate.fields];
    [newFields[index], newFields[index - 1]] = [newFields[index - 1], newFields[index]];
    reorderTemplateFieldsDebounced({
      templateId: currTemplate.id,
      fieldIds: newFields.map((field) => field.id),
    });
  };

  const handleMoveDown = (id: number) => {
    const index = currTemplate.fields.findIndex((field) => field.id === id);
    const newFields = [...currTemplate.fields];
    [newFields[index], newFields[index + 1]] = [newFields[index + 1], newFields[index]];
    reorderTemplateFieldsDebounced({
      templateId: currTemplate.id,
      fieldIds: newFields.map((field) => field.id),
    });
  };

  return (
    <Flex
      direction="column"
      w="100%"
      p={6}
      gap={4}
      border="1px"
      borderColor="chakra-border-color"
      borderRadius="md">
      <VStack w="100%" align="start">
        <HStack justify="space-between" w="100%">
          <Heading size="md">{type === "service" ? "Form Builder" : "Prompt"}</Heading>
          {currTemplate.published_at ? (
            <Button
              onClick={() =>
                copyActiveTemplate({ formId: item.form.id }).then(() => invalidateFunction())
              }
              isDisabled={
                type === "poll" && (item.status === "active" || item.status === "closed")
              }>
              Edit Fields
            </Button>
          ) : currTemplate.fields.length > 0 ? (
            <Button
              onClick={() =>
                publishTemplate({ templateId: currTemplate.id }).then(() => invalidateFunction())
              }>
              Save Changes
            </Button>
          ) : (
            <></>
          )}
        </HStack>
      </VStack>
      {currTemplate.fields.length === 0 ? (
        <VStack spacing={4} justify="center" align="center" w="100%">
          <Icon as={VscNewFile} boxSize={20} color={"gray.100"} />
          <Text fontWeight="semibold" w="sm" textAlign="center" color="gray.500">
            {type === "service"
              ? "Add fields that you want the requester to see when creating a new request"
              : "Add a question that you want the requester to see when creating a new poll"}
          </Text>
        </VStack>
      ) : (
        <Flex direction="column" gap={4}>
          {currTemplate?.fields.map((field, idx) => (
            <FormField
              key={field.id}
              type={type}
              field={field}
              canEdit={!currTemplate.published_at}
              invalidateFunction={invalidateFunction}
              handleReorderUp={() => handleMoveUp(field.id)}
              handleReorderDown={() => handleMoveDown(field.id)}
              canReorderDown={idx !== currTemplate?.fields.length - 1}
              options={fieldOptions}
              canReorderUp={idx !== 0}
              isLoadingReorder={isLoadingReorder}
              errors={errors[field.id]?.map((error) => error.error)}
            />
          ))}
        </Flex>
      )}

      {!currTemplate.published_at && (
        <Box alignSelf="center">
          <Menu>
            {type === "service" ? (
              <MenuButton as={Button} rightIcon={<Icon as={AddIcon} />} colorScheme="teal">
                Add Field
              </MenuButton>
            ) : (
              currTemplate.fields.length < 1 && (
                <MenuButton as={Button} rightIcon={<Icon as={AddIcon} />} colorScheme="teal">
                  Add Question
                </MenuButton>
              )
            )}
            <MenuList>
              {fieldOptions.map((option) => (
                <MenuItem
                  gap={2}
                  key={option.value}
                  onClick={() => {
                    if (currTemplate)
                      createField({
                        templateId: currTemplate.id,
                        data: {
                          label: "",
                          is_required: false,
                          type: option.value,
                        },
                      }).then(() => invalidateFunction());
                  }}>
                  <Flex p={1} bg={option.color} borderRadius="md">
                    <Icon as={option.icon} boxSize={4} color="white" />
                  </Flex>
                  {option.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Box>
      )}
    </Flex>
  );
};
