export const CapitalEquipmentCategoryOptions = [
  { label: "Audio/Visual Equipment", value: "audio_visual_equipment" },
  { label: "Appliances", value: "appliances" },
  { label: "Building Infrastructure", value: "building_infrastructure" },
  { label: "Facility Maintenance Equipment", value: "facility_maintenance_equipment" },
  { label: "Furniture", value: "furniture" },
  { label: "Heavy Equipment & Machinery", value: "heavy_equipment_machinery" },
  { label: "Lab Equipment", value: "lab_equipment" },
  { label: "Lighting Equipment", value: "lighting_equipment" },
  { label: "Manufacturing", value: "manufacturing" },
  { label: "Medical Devices", value: "medical_devices" },
  { label: "Peripherals", value: "peripherals" },
  { label: "Personal Protective Equipment (PPE)", value: "personal_protective_equipment" },
  { label: "Phones", value: "phones" },
  { label: "Power Tools", value: "power_tools" },
  { label: "Prototyping Equipment", value: "prototyping_equipment" },
  { label: "Security Equipment", value: "security_equipment" },
  { label: "Servers & Networking Equipment", value: "servers_networking_equipment" },
  { label: "Shared Computers", value: "shared_computers" },
  { label: "Software Licenses", value: "software_licenses" },
  { label: "Storage Solutions", value: "storage_solutions" },
  { label: "Workplace Safety Equipment", value: "workplace_safety_equipment" },
  { label: "Other Tools & Equipment", value: "tools_equipment" },
];
