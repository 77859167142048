import React, { useMemo } from "react";
import { useGetProjectMeetingNotes } from "../../../api/planning/projects";
import { Box, Text, HStack, VStack, Spinner, Alert, Button } from "@chakra-ui/react";
import { EmptyState } from "@sciencecorp/helix-components";
import { ProjectMeetingNoteCard } from "./ProjectMeetingNoteCard";
import { AddIcon } from "@chakra-ui/icons";
import { useCurrentUserQuery } from "../../../api/user";
import { useCreateMeetingNotes } from "../../../api/meeting_notes";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

export type ProjectMeetingNotesProps = {
  projectId: number;
};

export const ProjectMeetingNotes = ({ projectId }: ProjectMeetingNotesProps) => {
  const projectMeetingNotesQuery = useGetProjectMeetingNotes(projectId);
  const { mutateAsync: createMeetingNotes } = useCreateMeetingNotes();
  const { data: currentUser } = useCurrentUserQuery();
  const navigate = useNavigate();

  const handleCreateMeetingNote = () => {
    if (!currentUser) return;

    createMeetingNotes({
      title: "Meeting",
      user_id: currentUser.id,
      meeting_time: DateTime.now(),
      project_id: projectId,
    })
      .then((data) => {
        navigate(`/meeting_notes/${data.id}`);
      })
      .catch((error) => {
        console.error("Error creating meeting notes:", error);
      });
  };

  const meetingNotes = useMemo(
    () =>
      projectMeetingNotesQuery.data?.filter(
        (meetingNote) =>
          meetingNote.content?.["root"]?.["children"]?.[0]?.["children"]?.[0]?.["text"] !== ""
      ),
    [projectMeetingNotesQuery.data]
  );

  if (projectMeetingNotesQuery.isLoading) {
    return <Spinner />;
  } else if (!projectMeetingNotesQuery.isSuccess) {
    return <Alert status="error">Error downloading meeting notes</Alert>;
  } else {
    const projectMeetingNotes = projectMeetingNotesQuery.data;
    if (projectMeetingNotes.length === 0) {
      return (
        <>
          <HStack width="100%" justifyContent="flex-end" mb={4}>
            <Button
              size="sm"
              leftIcon={<AddIcon />}
              colorScheme="teal"
              variant="outline"
              onClick={handleCreateMeetingNote}>
              Add Meeting Note
            </Button>
          </HStack>
          <EmptyState size="xl" title="No meeting notes are attached to this project." />
        </>
      );
    } else {
      return (
        <Box mt={4} width="100%">
          <HStack justifyContent="space-between" width="100%" mb={4}>
            <Text fontSize="lg" fontWeight="bold">
              Recent Discussions
            </Text>
            <Button
              size="sm"
              leftIcon={<AddIcon />}
              colorScheme="teal"
              variant="outline"
              onClick={handleCreateMeetingNote}>
              Add Meeting Note
            </Button>
          </HStack>
          <VStack width="100%" spacing={4}>
            {meetingNotes?.map((meetingNote) => (
              <ProjectMeetingNoteCard key={meetingNote.id} meetingNote={meetingNote} />
            ))}
          </VStack>
        </Box>
      );
    }
  }
};
