import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  ReimbursementLineItemShowData,
  useCreateReimbursementLineItem,
  useUpdateReimbursementLineItem,
} from "../../../../api/reimbursement_line_items";
import { useCurrency } from "../../../../contexts/CurrencyContext";
import { Money } from "../../../../helpers/Money";
import { MoneyInput } from "../../../MoneyInput";
import { useFileUpload } from "../../../hooks/useFileUpload";
import { MultipleFileDisplay } from "../../../shared/MultipleFileDisplay";

const useDefaultFormValues = (): FormValuesType => {
  const currency = useCurrency();
  return {
    reimbursement_id: null,
    name: "",
    location: "",
    description: "",
    expense_date: "",
    amount: Money.zero(currency),
    files: [],
  };
};

type FormValuesType = {
  reimbursement_id: number | null;
  name: string;
  location: string;
  description: string;
  expense_date: string;
  amount: Money;
  files: { name: string; file: string }[];
};

type AddExpenseToReimbursementModalProps = {
  mode: "edit" | "create";
  isOpen: boolean;
  onClose: () => void;
  reimbursementId: number;
  reimbursementLineItem?: ReimbursementLineItemShowData | null;
};

export const AddExpenseToReimbursementModal = ({
  mode,
  isOpen,
  onClose,
  reimbursementLineItem,
  reimbursementId,
}: AddExpenseToReimbursementModalProps) => {
  const {
    mutate: createReimbursementLineItem,
    isLoading: isCreateLoading,
    isError,
  } = useCreateReimbursementLineItem(reimbursementId);

  const { mutate: updateReimbursementLineItem, isLoading: isUpdateLoading } =
    useUpdateReimbursementLineItem(reimbursementId);

  const [encodedFiles, setEncodedFiles] = useState<{ name: string; file: string }[]>([]);

  const defaultFormValues = useDefaultFormValues();
  const { handleSubmit, setValue, control, reset, watch, register, trigger } =
    useForm<FormValuesType>({ defaultValues: defaultFormValues });

  const formValues = watch();

  const handleFormValidation = () => {
    return (
      formValues.name &&
      formValues.location &&
      formValues.description &&
      formValues.amount &&
      formValues.expense_date &&
      (mode === "create" ? encodedFiles.length > 0 : true)
    );
  };

  useEffect(() => {
    if (reimbursementLineItem) {
      const formData: FormValuesType = {
        ...reimbursementLineItem,
        reimbursement_id: reimbursementId,
        name: reimbursementLineItem.name,
        location: reimbursementLineItem.location,
        description: reimbursementLineItem.description || "",
        expense_date: reimbursementLineItem.expense_date || "",
        amount: reimbursementLineItem.amount || 0,
        files: [],
      };
      reset(formData);
    }
  }, [reimbursementLineItem]);

  const { handleFileChange } = useFileUpload({
    onUpload: (encodedFileObjects) => setEncodedFiles((prev) => [...prev, ...encodedFileObjects]),
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const submitFunction = (
    reimbursementLineItemFormData: FormValuesType,
    reimbursementLineItem: Omit<ReimbursementLineItemShowData, "uploaded_files"> | null | undefined
  ) => {
    if (reimbursementLineItem) {
      const { files, ...reimbursementLineItemFormDataCopy } = reimbursementLineItemFormData;
      updateReimbursementLineItem(
        {
          ...reimbursementLineItemFormDataCopy,
          id: reimbursementLineItem.id,
          reimbursement_id: reimbursementId,
        },
        {
          onSuccess: () => {
            handleClose();
          },
        }
      );
    } else {
      createReimbursementLineItem(
        {
          ...reimbursementLineItemFormData,
          reimbursement_id: reimbursementId,
          files: encodedFiles,
          amount: reimbursementLineItemFormData.amount,
        },
        {
          onSuccess: () => {
            handleClose();
          },
        }
      );
    }
  };

  const onSubmit = (data: FormValuesType) => {
    const { amount, ...restData } = data;

    if (!reimbursementId) return;
    const reimbursementLineItemData: FormValuesType = {
      ...restData,
      amount: data.amount,
      files: encodedFiles,
      reimbursement_id: reimbursementId,
      name: data.name,
      location: data.location,
      description: data.description,
      expense_date: data.expense_date,
    };
    submitFunction(reimbursementLineItemData, reimbursementLineItem);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} size={"3xl"} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader px={6}>
            <VStack width="100%" align="start">
              <Text>Add Expense</Text>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody justifyContent="center">
            <VStack gap={4} width="100%" align="start">
              <HStack w={"100%"}>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <FormControl isRequired>
                        <FormLabel>Name of Expense</FormLabel>
                        <Input data-testid="expense-name-input" {...field} value={field.value} />
                      </FormControl>
                    );
                  }}
                />
                <Controller
                  name="location"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormControl isRequired>
                      <FormLabel>Location</FormLabel>
                      <Input data-testid="expense-location-input" {...field} />
                    </FormControl>
                  )}
                />
              </HStack>
              <Controller
                name="description"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormControl isRequired>
                    <FormLabel>Description</FormLabel>
                    <Textarea data-testid="expense-description-input" {...field} />
                  </FormControl>
                )}
              />
              <HStack width={"100%"}>
                <Controller
                  name="expense_date"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormControl isRequired>
                      <FormLabel>Date</FormLabel>
                      <Input data-testid="expense-date-input" type="date" {...field} />
                    </FormControl>
                  )}
                />
                <Controller
                  name="amount"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormControl isRequired data-testid="expense-amount-input">
                      <FormLabel>Amount</FormLabel>
                      <MoneyInput {...field} />
                    </FormControl>
                  )}
                />
              </HStack>
              {reimbursementLineItem ? (
                <></>
              ) : (
                <FormControl isRequired>
                  <FormLabel>Receipt(s)</FormLabel>
                  <MultipleFileDisplay
                    files={encodedFiles}
                    handleFileChange={handleFileChange}
                    onDelete={(index) =>
                      setEncodedFiles((prev) => prev.filter((_, i) => i !== index))
                    }
                  />
                </FormControl>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup gap={3}>
              <Button
                data-testid="expense-submit-button"
                onClick={() => handleSubmit(onSubmit)()}
                colorScheme="teal"
                isLoading={isUpdateLoading || isCreateLoading}
                isDisabled={!handleFormValidation()}>
                Submit
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
