import { userMinimalSchema } from "./user";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { moneySchema } from "../helpers/Money";
import { blobFileSchema } from "./blob_files";
import { SearchParams, indexParams, searchParams } from "./collection_types";
import { expenditureSchema } from "./expenditures";
import { paymentScheduleShowSchema } from "./payment_schedule";
import { createSearchResponseSchema } from "./shared";
import { SpendingAuthorityReference } from "./spending_authority";
import { timelineEventSchema } from "./timeline_events";
import { zodParse } from "./zodParse";
import { dateTimeSchema } from "../helpers/dateTime";
import { purchasePaymentShowSchema } from "./purchase_payment";

export const CONTRACTS_BASE_URL = "/contracts";

export const contractSchema = z.object({
  id: z.number(),
  name: z.string(),
  currency: z.string(),
  vendor_id: z.number(),
  description: z.string(),
  wafer_services_tool: z.string().nullable(),
  archived_at: z.string().nullable(),
  rate: z.string(),
  renewal_date: dateTimeSchema.nullable(),
  billing_period: z.string().nullable(),
  budget_group_lead: z.lazy(() => userMinimalSchema).nullable(),
});

export const contractIndexSchema = contractSchema.omit({ vendor_id: true }).extend({
  total_paid: moneySchema,
  total_cost: moneySchema,
  number_paid_installments: z.number(),
  number_total_installments: z.number(),
  last_payment: z.lazy(() => purchasePaymentShowSchema).nullable(),
  vendor: z.object({
    id: z.number(),
    name: z.string(),
    status: z.string(),
  }),
});

export const contractSearchSchema = createSearchResponseSchema(contractIndexSchema).extend({
  total: moneySchema,
  total_paid: moneySchema,
  archived_contract_count: z.number(),
});

export const contractShowSchema = contractIndexSchema.extend(expenditureSchema.shape).extend({
  uploaded_files: z.array(blobFileSchema),
});

export type ContractData = z.infer<typeof contractSchema>;
export type ContractIndexData = z.infer<typeof contractIndexSchema>;
export type ContractShowData = z.infer<typeof contractShowSchema>;
export type ContractCreateParams = Pick<
  ContractData,
  "name" | "vendor_id" | "description" | "rate" | "renewal_date"
> &
  Partial<SpendingAuthorityReference> & {
    files: { name: string; file: string }[];
  };
export type ContractSearchData = z.infer<typeof contractSearchSchema>;

export const generateContractCSV = async (): Promise<any> => {
  const result = await api.get(`${CONTRACTS_BASE_URL}/csv`, {
    responseType: "blob",
  });
  return result.data;
};

/** queries */
export const getContract = async (id: number | undefined) => {
  const result = await api.get(`${CONTRACTS_BASE_URL}/${id}`);
  return zodParse(contractShowSchema, result.data);
};

export const getContracts = async () => {
  const result = await api.get(CONTRACTS_BASE_URL);
  return zodParse(contractShowSchema.array(), result.data);
};

export const getContractTimelineEvents = async (id: number) => {
  const result = await api.get(`${CONTRACTS_BASE_URL}/${id}/events`);
  return zodParse(z.array(timelineEventSchema), result.data);
};

export const getContractPayments = async (id: number) => {
  const result = await api.get(`${CONTRACTS_BASE_URL}/${id}/payments`);
  return zodParse(paymentScheduleShowSchema, result.data);
};

export const searchContracts = async ({
  aggs,
  filters,
  pagination,
  order,
  term,
  currency,
}: SearchParams & { currency?: string | null }) => {
  const path = [CONTRACTS_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search, currency });
  return zodParse(contractSearchSchema, result.data);
};

export const createContract = async (contract: ContractCreateParams) => {
  const result = await api.post(CONTRACTS_BASE_URL, { contract });
  return result.data;
};

export const updateContract = async (
  contract: Partial<ContractData> & Partial<SpendingAuthorityReference>
) => {
  await api.put(`${CONTRACTS_BASE_URL}/${contract.id}`, { contract });
};

export const updateContractCurrency = async (
  contract: Pick<ContractData, "id" | "currency"> & { convert_payments: boolean }
) => {
  const result = await api.put(`${CONTRACTS_BASE_URL}/${contract.id}/currency`, {
    currency: contract.currency,
    convert_payments: contract.convert_payments,
  });
  return result.data;
};

export const deleteContract = async (id: number) => {
  await api.delete(`${CONTRACTS_BASE_URL}/${id}`);
  return id;
};

export const acknowledgeContractPayments = async (id: number) => {
  await api.post(`${CONTRACTS_BASE_URL}/${id}/acknowledge`);
};

/** hooks */

export const invalidateContracts = (id?: number, subquery?: string) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [CONTRACTS_BASE_URL];
  if (id) queryKey.push(id);
  if (subquery) queryKey.push(subquery);
  return () =>
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
};

export const useGetContracts = () => {
  return useQuery({
    queryKey: [CONTRACTS_BASE_URL],
    queryFn: () => getContracts(),
  });
};

export const useSearchContracts = (params: SearchParams & { currency?: string | null }) => {
  return useQuery({
    queryKey: [CONTRACTS_BASE_URL, params],
    queryFn: () => searchContracts(params),
  });
};

export const useContractOptions = () => {
  return useQuery({
    queryKey: [CONTRACTS_BASE_URL],
    queryFn: () => {
      const searchResults = searchContracts({
        aggs: [],
        pagination: { page: 1, per_page: -1 },
        filters: {},
        term: "*",
      });
      return searchResults.then((data) => ({
        ...data,
        options: [
          { label: "No Contract", value: -1 },
          ...data.results.map((c) => ({ label: c.name, value: c.id })),
        ],
      }));
    },
  });
};

export const useGetContract = (id: number | undefined) => {
  return useQuery({
    queryKey: [CONTRACTS_BASE_URL, id],
    queryFn: () => getContract(id),
    enabled: !!id,
  });
};

export const useGetContractTimelineEvents = (id: number) => {
  return useQuery({
    queryKey: [CONTRACTS_BASE_URL, id, "events"],
    queryFn: () => getContractTimelineEvents(id),
  });
};

export const useGetContractPayments = (id: number) => {
  return useQuery({
    queryKey: [CONTRACTS_BASE_URL, id, "payments"],
    queryFn: () => getContractPayments(id),
  });
};

export const useCreateContract = () => {
  return useMutation({
    mutationFn: createContract,
    onSuccess: invalidateContracts(),
  });
};

export const useUpdateContract = (id?: number) => {
  return useMutation({
    mutationFn: updateContract,
    onSuccess: invalidateContracts(id),
  });
};

export const useUpdateContractCurrency = (id?: number) => {
  return useMutation({
    mutationFn: updateContractCurrency,
    onSuccess: invalidateContracts(id),
  });
};

export const useDeleteContract = (onSuccess?: () => void) => {
  return useMutation({
    mutationFn: deleteContract,
    onSuccess: (id: number) => {
      onSuccess && onSuccess();
      invalidateContracts(id);
    },
  });
};

export const useAcknowledgeContractPayments = (id?: number) => {
  return useMutation({
    mutationFn: acknowledgeContractPayments,
    onSuccess: invalidateContracts(id),
  });
};
