import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../api";
import {
  type CreateIncidentReportParams,
  incidentReportSchema,
  UpdateIncidentReportParams,
  searchIncidentReportsResponseSchema,
} from "../types/incident_reports";
import { zodParse } from "./zodParse";
import { SearchParams } from "./collection_types";

export const INCIDENT_REPORTS_BASE_URL = "incident_reports";

export const useGetIncidentReportQuery = (id: number) =>
  useQuery({
    queryKey: [INCIDENT_REPORTS_BASE_URL, id],
    queryFn: async () => {
      const { data } = await api.get(`/${INCIDENT_REPORTS_BASE_URL}/${id}`);
      return zodParse(incidentReportSchema, data);
    },
  });

export const useSearchIncidentReportsQuery = (params: SearchParams, enabled: boolean) =>
  useQuery({
    queryKey: [INCIDENT_REPORTS_BASE_URL, "search", params],
    enabled: enabled,
    queryFn: async () => {
      const { data } = await api.post(`/${INCIDENT_REPORTS_BASE_URL}/search`, params);
      return zodParse(searchIncidentReportsResponseSchema, data);
    },
  });

export const useCreateIncidentReportMutation = () =>
  useMutation({
    mutationFn: async (params: CreateIncidentReportParams) => {
      const { data } = await api.post(`/${INCIDENT_REPORTS_BASE_URL}`, params);
      return zodParse(incidentReportSchema, data);
    },
  });

export const useUpdateIncidentReportMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: UpdateIncidentReportParams) => {
      const { data } = await api.put(`/${INCIDENT_REPORTS_BASE_URL}/${params.id}`, {
        commands: params.commands,
      });
      return zodParse(incidentReportSchema, data);
    },
  });
};

export const useInvalidateIncidentReport = (id: number) => {
  const queryClient = useQueryClient();
  return async () => {
    queryClient.invalidateQueries({
      queryKey: [INCIDENT_REPORTS_BASE_URL, id],
    });
    queryClient.invalidateQueries({
      queryKey: [INCIDENT_REPORTS_BASE_URL, "search"],
    });
  };
};
