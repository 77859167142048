import {
  useToast,
  useDisclosure,
  Spinner,
  Box,
  ButtonGroup,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Flex,
  Text,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  HStack,
  Badge,
} from "@chakra-ui/react";
import { Header, SplitPage, ConfirmationModal, PersonIcon } from "@sciencecorp/helix-components";
import { titleize, humanize } from "inflection";
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router";
import { useUpdateInventory, useGetInventory, useSearchInventories } from "../../../api/inventory";
import { useCurrentUserQuery, userHasRole } from "../../../api/user";
import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";
import { NewInventoryModal } from "../components/NewInventoryModal";
import { inventoryStatusColor } from "../util";
import { AssignmentTable } from "./components/AssignmentTable";
import { InventoryDetailInformation } from "./components/InventoryDetailInformation";
import { NewInventoryItemModal } from "./components/RestockModal";
import { printBarcodes } from "./components/RestockModalComponents/util";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaUndo } from "react-icons/fa";

import { FaPrint } from "react-icons/fa";
import { BsArchive, BsThreeDotsVertical } from "react-icons/bs";
import { RiHistoryFill } from "react-icons/ri";
import { PopoverList } from "../../shared/PopoverList";
import { MinusIcon } from "@chakra-ui/icons";
import { RemoveStockModal } from "./components/RemoveStockModal";
import { CheckoutHistory } from "./components/CheckoutHistory";
import { InventoryPendingItems } from "./components/InventoryPendingItems";
import { PendingPurchaseBanner } from "./components/PendingPurchaseBanner";

export const InventoryPage = () => {
  const { id, tab } = useParams();
  const currentUserQuery = useCurrentUserQuery();
  const isInventoryManager = userHasRole(currentUserQuery, "inventory_manager");
  const { mutate: updateInventory } = useUpdateInventory();
  const { debouncedSearch, search } = useDebouncedSearch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(tab?.toString().toLowerCase() || "details");

  const {
    data: inventory,
    isLoading: isLoadingInventory,
    isError: isErrorInventory,
    isSuccess: isSuccessInventory,
  } = useGetInventory(Number(id));

  const { data: inventoryOptions } = useSearchInventories({
    term: search || "*",
    pagination: { per_page: -1 },
    order: { name: "asc" },
    filters: {
      is_archived: false,
    },
  });
  const toast = useToast();
  const {
    isOpen: archiveConfirmationModalIsOpen,
    onOpen: archiveConfirmationModalOnOpen,
    onClose: archiveConfirmationModalOnClose,
  } = useDisclosure();
  const {
    isOpen: archiveDenyModalIsOpen,
    onOpen: archiveDenyModalOnOpen,
    onClose: archiveDenyModalOnClose,
  } = useDisclosure();
  const {
    isOpen: restoreConfirmationModalIsOpen,
    onOpen: restoreConfirmationModalOnOpen,
    onClose: restoreConfirmationModalOnClose,
  } = useDisclosure();
  const {
    isOpen: removeStockModalIsOpen,
    onOpen: removeStockModalOnOpen,
    onClose: removeStockModalOnClose,
  } = useDisclosure();

  const handleArchiveSuccess = () => {
    archiveConfirmationModalOnClose();
    toast({
      title: "Successful!",
      description: `You sent ${inventory ? inventory.name : "the item"} to the archive.`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    navigate("/inventory/items");
  };

  const handleRestoreSuccess = () => {
    restoreConfirmationModalOnClose();
    toast({
      title: "Successful!",
      description: `You sent ${inventory ? inventory.name : "the item"} back to the item list.`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    navigate("/inventory/items");
  };

  const qrCodesToPrint = useMemo(
    () =>
      inventory?.inventory_item_barcodes
        ?.filter((item) => item.barcode !== null)
        .map((item) => ({
          barcode: item.barcode,
          id: item.id.toString(),
        })) || [],
    [inventory]
  );

  const tabList = [
    {
      label: "Details",
      value: "details",
      icon: <IoDocumentTextOutline />,
      disabled: false,
    },
    {
      label: (
        <HStack>
          <Text>History</Text>
          <Badge colorScheme="teal">Manager</Badge>
        </HStack>
      ),
      value: "history",
      icon: <RiHistoryFill />,
      disabled: !inventory?.is_consumable,
    },
    {
      label: "Assignments",
      value: "assignments",
      icon: <PersonIcon />,
      disabled: !isInventoryManager || inventory?.is_consumable,
    },
  ];

  const handlePrintBarcodes = () => {
    if (qrCodesToPrint && qrCodesToPrint.length === 0) {
      toast({
        title: "Error Printing QR Codes",
        description: `There are no available items to print QR codes for`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      printBarcodes(qrCodesToPrint);
    }
  };
  const tabs = useMemo(() => tabList.filter((t) => !t.disabled), [isInventoryManager, inventory]);

  useEffect(() => {
    setActiveTab(tab?.toString().toLowerCase() || "details");
  }, [tab]);

  if (isLoadingInventory) {
    return <Spinner />;
  }

  if (isErrorInventory) {
    return <Text>Error loading inventory item</Text>;
  }

  const statusLabel = inventory.is_archived
    ? "Unavailable"
    : titleize(humanize(inventory.status || ""));
  const statusColorScheme = inventory.is_archived
    ? "red"
    : inventoryStatusColor(inventory.status || "");

  const actions = isInventoryManager
    ? [
        <ButtonGroup key="action-group-1">
          <PopoverList
            items={
              inventoryOptions?.results
                .filter((item) => !item.is_archived)
                .map((item) => ({
                  id: +item.id,
                  name: item.name,
                  url: `inventory/items/${item.id}`,
                  status: item.is_archived ? "unavailable" : item.status || "",
                  is_archived: item.is_archived || false,
                })) || []
            }
            selectedItemId={inventory.id}
            debouncedSearch={debouncedSearch}
            title="Other Inventory Items"
          />
          <NewInventoryModal inventory={inventory} />
          <Menu>
            <MenuButton as={IconButton} icon={<BsThreeDotsVertical />} />
            <MenuList>
              {inventory.is_archived ? (
                <MenuItem icon={<FaUndo />} onClick={restoreConfirmationModalOnOpen}>
                  Restore Item
                </MenuItem>
              ) : (
                <>
                  <MenuItem icon={<FaPrint />} onClick={handlePrintBarcodes}>
                    Print QR Codes
                  </MenuItem>
                  <MenuItem icon={<BsArchive />} onClick={archiveConfirmationModalOnOpen}>
                    Send to Archive
                  </MenuItem>
                </>
              )}
            </MenuList>
          </Menu>
        </ButtonGroup>,
      ]
    : [];

  return isSuccessInventory ? (
    <Box>
      <RemoveStockModal
        isOpen={removeStockModalIsOpen}
        onClose={removeStockModalOnClose}
        inventory={inventory}
      />

      {inventory.is_archived && (
        <Alert status="warning" variant="subtle" flexDirection="row" alignItems="center" mb={4}>
          <AlertIcon />
          <Box>
            <AlertTitle mr={2}>Archived Item</AlertTitle>
            <AlertDescription>
              This item is in the inventory archive. Restore this item to place it back on the item
              list.
            </AlertDescription>
          </Box>
          <Button onClick={restoreConfirmationModalOnOpen} colorScheme="orange" ml="auto">
            Restore Item
          </Button>
        </Alert>
      )}
      <Header
        title={inventory.name}
        badge={{
          label: statusLabel,
          colorScheme: statusColorScheme,
        }}
        crumbs={[
          {
            label: "Inventory",
            url: "/inventory/items",
          },
        ]}
        crumbsColor="teal"
        actions={actions}
      />
      <SplitPage
        sidebar={
          <Flex direction="column" gap={2}>
            {tabs.map(({ label, value, icon }) => (
              <Button
                key={value}
                size="md"
                width="100%"
                justifyContent="start"
                colorScheme={activeTab === value ? "teal" : "gray"}
                bg={activeTab === value ? "teal.500" : "transparent"}
                leftIcon={icon}
                onClick={() => {
                  setActiveTab(value);
                  navigate(`/inventory/items/${id}/${value}`);
                }}>
                {label}
              </Button>
            ))}
          </Flex>
        }
        sidebarWidth="200px"
        sidebarWidthXL="250px"
        breakpoint="md"
        main={
          <Flex direction="column" gap={4}>
            {inventory.pending_delivered_purchase_line_item && (
              <PendingPurchaseBanner inventory={inventory} />
            )}
            <Stack width="100%" align="start" spacing={4}>
              {activeTab === "details" && (
                <>
                  <Stack
                    direction={{ base: "row", lg: "column" }}
                    justify="space-between"
                    width="100%">
                    <Flex
                      direction={{ base: "column", lg: "row" }}
                      w="100%"
                      border={"1px"}
                      borderColor="chakra-border-color"
                      borderRadius="md"
                      justifyContent="space-between">
                      <StatGroup
                        flex="1"
                        p={6}
                        borderRightWidth={{ base: "0", lg: "1px" }}
                        borderBottomWidth={{ base: "1px", lg: "0" }}>
                        {!inventory.is_consumable ? (
                          <>
                            <Stat p={2}>
                              <StatLabel w="max-content">Total Inventory Items</StatLabel>
                              <StatNumber fontWeight="medium" fontSize="xx-large">
                                {inventory.total_inventory_items}{" "}
                                {inventory.unit_of_measurement || ""}
                              </StatNumber>
                            </Stat>
                            <Stat p={2}>
                              <StatLabel w="max-content">Available to Checkout</StatLabel>
                              <StatNumber fontWeight={"medium"} fontSize="xx-large">
                                {inventory.stock} {inventory.unit_of_measurement || ""}
                              </StatNumber>
                            </Stat>
                          </>
                        ) : (
                          <Stat p={2}>
                            <StatLabel w="max-content">In Stock</StatLabel>
                            <StatNumber fontWeight="medium" fontSize="xx-large">
                              {inventory.total_quantity_stock}{" "}
                              {inventory.unit_of_measurement || "units"}
                            </StatNumber>
                          </Stat>
                        )}
                      </StatGroup>
                      <Stack
                        flex="1"
                        direction={{ base: "column", lg: "row" }}
                        align={{ base: "start", lg: "center" }}
                        p={6}
                        borderRightWidth={{ base: "0", lg: "1px" }}
                        borderBottomWidth={{ base: "1px", lg: "0" }}>
                        <Stat>
                          <StatLabel>Pending</StatLabel>
                          <StatNumber fontSize="xx-large">
                            {" "}
                            {inventory.pending_order_quantity}
                          </StatNumber>
                        </Stat>
                        <InventoryPendingItems inventory={inventory} />
                      </Stack>
                      <HStack p={6}>
                        {inventory.total_quantity_stock > 0 && (
                          <Button
                            variant="outline"
                            onClick={removeStockModalOnOpen}
                            leftIcon={<MinusIcon />}
                            colorScheme="red">
                            Remove From Stock
                          </Button>
                        )}
                        <NewInventoryItemModal inventory={inventory} />
                      </HStack>
                    </Flex>
                  </Stack>
                  <InventoryDetailInformation
                    inventory={inventory}
                    isInventoryManager={isInventoryManager}
                  />
                </>
              )}
              {activeTab === "assignments" && <AssignmentTable inventoryId={inventory.id} />}
              {activeTab === "history" && <CheckoutHistory inventory={inventory} />}
            </Stack>

            <ConfirmationModal
              colorScheme="teal"
              children={`Restoring this item will place it back on the item list.`}
              header="Restore Inventory Item"
              isOpen={restoreConfirmationModalIsOpen}
              onClick={() => {
                updateInventory(
                  { id: inventory.id, is_archived: false },
                  { onSuccess: () => handleRestoreSuccess() }
                );
              }}
              confirmText="Confirm"
              onClose={restoreConfirmationModalOnClose}
            />
            <ConfirmationModal
              colorScheme="teal"
              children={
                <span>
                  Are you sure you want to archive <strong>{inventory.name}</strong>? This inventory
                  will no longer be available for checkout.
                </span>
              }
              header="Archive Inventory Item"
              isOpen={archiveConfirmationModalIsOpen}
              onClick={() => {
                updateInventory(
                  { id: inventory.id, is_archived: true },
                  { onSuccess: () => handleArchiveSuccess() }
                );
              }}
              confirmText="Confirm Archive"
              onClose={archiveConfirmationModalOnClose}
            />
            <ConfirmationModal
              colorScheme="teal"
              children={`You can only send items with no stock and pending items to the archive.`}
              header="Send To Archive"
              isOpen={archiveDenyModalIsOpen}
              onClick={() => {
                archiveDenyModalOnClose();
              }}
              onClose={archiveDenyModalOnClose}
            />
          </Flex>
        }
      />
    </Box>
  ) : (
    <Text>Error loading inventory item</Text>
  );
};
