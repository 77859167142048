import React, { useState } from "react";
import {
  Box,
  SimpleGrid,
  Button,
  useDisclosure,
  VStack,
  HStack,
  Text,
  Heading,
  Skeleton,
  Divider,
  Stack,
  Grid,
  GridItem,
  IconButton,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { BsCalendarRange } from "react-icons/bs";
import { LuLayoutGrid } from "react-icons/lu";
import _ from "lodash";
import { DateTime } from "luxon";
import { ProjectCard } from "./components/ProjectCard";
import { ProjectModal } from "./components/ProjectModal";
import { ArcModal } from "./components/ArcModal";
import { ProjectGanttChart } from "./components/ProjectGanttChart";
import { CsvDownload } from "../shared/csvDownloadButton";
import { useDebouncedSearch } from "../hooks/useDebouncedSearch";
import {
  ProjectIndex,
  useSearchProjects,
  getProjectCSV,
  useGetProjectSummaries,
  projectComparator,
  useGetProjectAnalytics,
} from "../../api/planning/projects";
import { Arc, useGetArcs, useUpdateArc } from "../../api/planning/arcs";
import { useCurrentUserQuery, userHasFeatureFlag, userHasRole } from "../../api/user";
import {
  Facets,
  Header,
  SplitPage,
  useCollection,
  buildFacets,
  EmptyState,
  EditableText,
} from "@sciencecorp/helix-components";
import { FilterValue } from "@sciencecorp/helix-components/dist/types";

export const PlanningPage: React.FC<{}> = () => {
  interface ArcWithProjects extends Arc {
    projects: ProjectIndex[];
  }
  const { order, filters, pagination, facets, onFacets } = useCollection({
    pagination: { per_page: -1 },
  });

  const { data: projectAnalytics, isLoading: projectAnalyticsIsLoading } = useGetProjectAnalytics();

  const currentUserQuery = useCurrentUserQuery();
  const isProjectManager = userHasRole(currentUserQuery, "project_manager");
  const isTopLevelTeamLead = currentUserQuery.data?.top_level_team_lead || false;
  const { search, debouncedSearch } = useDebouncedSearch();

  const {
    data: projects,
    isLoading,
    isError,
    error,
  } = useSearchProjects({
    term: search || "*",
    aggs: [
      "team_name",
      "status",
      "is_public",
      "depends_on_incomplete_project",
      "group_name",
      "has_lead",
    ],
    filters,
    pagination: pagination,
    order,
    endDate: DateTime.now().endOf("year"),
  });

  const { data: projectSummaries, isLoading: projectSummariesIsLoading } = useGetProjectSummaries();

  const populatedFacets = buildFacets(projects?.aggregations || {}, facets);

  const {
    isOpen: projectModalIsOpen,
    onOpen: projectModalOnOpen,
    onClose: projectModalOnClose,
  } = useDisclosure();

  const projectOptions =
    projects?.results.map((project) => ({
      label: project.title,
      value: String(project.id),
    })) || [];

  const {
    isOpen: arcModalIsOpen,
    onOpen: arcModalOnOpen,
    onClose: arcModalOnClose,
  } = useDisclosure();

  const { data: arcs } = useGetArcs();
  const { mutate: updateArc } = useUpdateArc();
  const [projectView, setProjectView] = useState<"chart" | "grid">("grid");
  const bgColor = useColorModeValue("gray.100", "gray.600");

  const handleFacetChange = (field: string, value: FilterValue, checked: boolean) => {
    const updatedFacets: Facets = {
      ...populatedFacets,
      [field]: {
        ...populatedFacets[field],
        options: populatedFacets[field]?.options?.map((option) => ({
          ...option,
          selected: option.value === value ? checked : false,
        })),
      },
    };

    onFacets(updatedFacets);
  };

  function organizeProjectsByArc(
    arcs: Partial<Arc>[] | undefined,
    projects: ProjectIndex[]
  ): ArcWithProjects[] {
    if (!arcs) {
      return [];
    }
    const projectMap = _.groupBy(projects, "arc_id");
    for (const arcProjects of _.values(projectMap)) {
      arcProjects.sort(projectComparator); // Sort projects by cost within each arc
    }

    const sortedArcs = arcs.sort((a, b) => a.title!.localeCompare(b.title!));
    let result = sortedArcs
      .map((arc) => ({
        ...arc,
        projects: projectMap[arc.id!] || [],
      }))
      .filter((arc) => arc.projects.length > 0);

    // Handle unassociated projects: Include projects where arc_id is null or undefined
    const unassociatedProjects = projects.filter((p) => p.arc_id == null || !projectMap[p.arc_id]);
    if (unassociatedProjects.length > 0) {
      result.push({
        id: -1,
        title: "Unassociated",
        projects: unassociatedProjects.sort(projectComparator),
      });
    }

    return result as ArcWithProjects[];
  }

  if (isError) {
    return <div>Error: {error instanceof Error ? error.message : "An error occurred"}</div>;
  }
  const projectsByArc = organizeProjectsByArc(arcs, projects?.results || []);
  const projectDetails = [
    {
      title: "Approved",
      status: "approved",
      value: projectAnalytics?.approved_projects_count || 0,
    },
    {
      title: "Needs Approval",
      status: "needs_approval",
      value: projectAnalytics?.needs_approval_projects_count || 0,
    },
  ];

  return (
    <>
      <Header
        title="Projects"
        actions={
          isProjectManager
            ? [
                <Stack direction={{ base: "column", md: "row" }}>
                  <CsvDownload
                    title="helix-projects.csv"
                    apiCall={getProjectCSV}
                    buttonName="Download Project List"
                  />
                  ,
                  <Button
                    onClick={() => arcModalOnOpen()}
                    leftIcon={<AddIcon />}
                    key="createArc"
                    colorScheme="teal"
                    variant="outline">
                    Create Group
                  </Button>
                  ,
                  <Button
                    onClick={() => projectModalOnOpen()}
                    leftIcon={<AddIcon />}
                    colorScheme="teal"
                    key="createProject">
                    Create Project
                  </Button>
                </Stack>,
              ]
            : isTopLevelTeamLead
            ? [
                <Button
                  onClick={() => projectModalOnOpen()}
                  leftIcon={<AddIcon />}
                  colorScheme="teal"
                  key="createProject">
                  Create Project
                </Button>,
              ]
            : []
        }
      />
      <Divider />
      <Box p={4}>
        <SplitPage
          sidebarWidth="max-content"
          sidebarWidthXL="max-content"
          sidebar={
            <>
              <Box mb={4}>
                <Text fontSize="md" fontWeight="bold" mb={2}>
                  Overview
                </Text>
                <Skeleton isLoaded={!projectAnalyticsIsLoading}>
                  <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                    {projectDetails.map((detail) => (
                      <Link
                        onClick={() => handleFacetChange("status", detail.status, true)}
                        _hover={{
                          bgColor: bgColor,
                        }}
                        style={{ textDecoration: "none" }}
                        key={detail.value}>
                        <GridItem
                          border="1px solid"
                          borderColor="chakra-border-color"
                          borderRadius="md"
                          p={2}>
                          <Text fontSize="sm" fontWeight="medium">
                            {detail.title}
                          </Text>
                          <Text fontSize="sm" fontWeight="bold">
                            {detail.value}
                          </Text>
                        </GridItem>
                      </Link>
                    ))}
                  </Grid>
                </Skeleton>
              </Box>
              <Box alignSelf="start" display={{ base: "flex", md: "none" }} zIndex={2}>
                <Facets
                  facets={populatedFacets}
                  variant="button"
                  background
                  search
                  debouncedSearch={debouncedSearch}
                  onChange={onFacets}
                />
              </Box>
              <Box display={{ base: "none", md: "flex" }}>
                <Facets
                  facets={populatedFacets}
                  background
                  search
                  debouncedSearch={debouncedSearch}
                  onChange={onFacets}
                />
              </Box>
            </>
          }
          main={
            <>
              <Skeleton isLoaded={!isLoading}>
                {userHasFeatureFlag(currentUserQuery, "projects") && (
                  <Box display="flex" justifyContent="flex-start" mb={4}>
                    <IconButton
                      mr={2}
                      variant={projectView === "grid" ? "solid" : "ghost"}
                      aria-label="list-view"
                      icon={<LuLayoutGrid />}
                      onClick={() => setProjectView("grid")}
                    />
                    <IconButton
                      variant={projectView === "chart" ? "solid" : "ghost"}
                      aria-label="grid-view"
                      icon={<BsCalendarRange />}
                      onClick={() => setProjectView("chart")}
                    />
                  </Box>
                )}

                {(projects?.results?.length || 0) > 0 ? (
                  projectView === "grid" ? (
                    <VStack spacing={8} w={"100%"}>
                      {projectsByArc.map((arc) => (
                        <VStack key={arc.id} w={"100%"}>
                          <HStack justifyContent={"space-between"} w={"100%"} pb={2}>
                            {arc.title !== "Unassociated" ? (
                              <EditableText
                                defaultValue={arc.title}
                                onSubmit={(value) =>
                                  updateArc({ id: arc.id, title: value || arc.title })
                                }
                                preview={
                                  <Heading size="md" fontWeight="semibold">
                                    {arc.title}
                                  </Heading>
                                }
                              />
                            ) : (
                              <Heading size="md" fontWeight="semibold">
                                {arc.title}
                              </Heading>
                            )}
                          </HStack>
                          <SimpleGrid
                            templateColumns="repeat(auto-fill, minmax(250px, 1fr))"
                            spacing="4"
                            w={"100%"}>
                            {arc.projects.map((project) => (
                              <ProjectCard
                                key={project.id}
                                project={project}
                                summary={projectSummaries?.[project.id]}
                                summaryIsLoading={projectSummariesIsLoading}
                              />
                            ))}
                          </SimpleGrid>
                        </VStack>
                      ))}
                    </VStack>
                  ) : (
                    <ProjectGanttChart />
                  )
                ) : (
                  <EmptyState
                    title="You currently do not have access to view any projects. Projects are visible only to top level team leads and members who are directly affiliated with a project. If you believe this is a mistake or if you need access to a specific project, please contact your team lead or the Helix channel on Slack."
                    size="xs"
                  />
                )}
              </Skeleton>
              <ProjectModal
                isOpen={projectModalIsOpen}
                onClose={projectModalOnClose}
                projectOptions={projectOptions}
              />
              <ArcModal isOpen={arcModalIsOpen} onClose={arcModalOnClose} />
            </>
          }
        />
      </Box>
    </>
  );
};
