import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { DateTime } from "luxon";
import { z } from "zod";
import { api } from ".";
import { BUDGETS_BASE_URL } from "./budget";
import { SearchParams, indexParams, searchParams } from "./collection_types";
import { PURCHASES_BASE_URL } from "./purchase";
import { createSearchResponseSchema } from "./shared";
import { TimelineEventData, timelineEventSchema } from "./timeline_events";
import { zodParse } from "./zodParse";
import { dateTimeSchema } from "../helpers/dateTime";
import { Money, moneySchema } from "../helpers/Money";
import { useCurrency } from "../contexts/CurrencyContext";
import { monthlySpendSchema } from "./budget_groups";
import { userMinimalSchema } from "./user";
import { generalLedgerCodeSchema } from "./general_ledger_codes";
import { backgroundTaskSchema } from "./background_tasks";

export const BUDGET_ITEMS_BASE_URL = "budget_items";

/** budget_items */
export const budgetItemSchema = z.object({
  id: z.number(),
  budget_id: z.number(),
  item_name: z.string(),
  name: z.string(),
  amount: moneySchema,
  allocated_amount: moneySchema,
  supporting_evidence: z.string().nullable(),
  archived_at: dateTimeSchema.nullable(),
  is_archived: z.boolean(),
  app_href: z.string(),
  requested_by_id: z.number().nullable(),
  approved_by_id: z.number().nullable(),
  general_ledger_code_id: z.number().nullable(),
  approved_at: dateTimeSchema.nullable(),
  status: z.enum(["pending", "approved", "declined", "archived"]),
  created_at: dateTimeSchema.nullable(),
});

export const minimalBudgetItemSchema = budgetItemSchema.pick({
  id: true,
  item_name: true,
});

export const budgetItemSummarySchema = z.object({
  funding_amount: moneySchema,
  pending_approval_amount: moneySchema,
  requested_amount: moneySchema,
  committed_amount: moneySchema,
  paid_amount: moneySchema,
  spent_amount: moneySchema,
  remaining_amount: moneySchema,
  pending_approval_records_count: z.number(),
});

export const spentAndCommittedSchema = z.object({
  total_spent: moneySchema,
  total_unpaid_committed: moneySchema,
});
export const budgetItemSpendByModelSchema = z.object({
  purchases: spentAndCommittedSchema,
  contracts: spentAndCommittedSchema,
  service_requests: spentAndCommittedSchema,
  reimbursements: spentAndCommittedSchema,
});

export const budgetItemShowSchema = budgetItemSchema
  .omit({
    approved_by_id: true,
    requested_by_id: true,
    general_ledger_code_id: true,
  })
  .extend({
    general_ledger_code: generalLedgerCodeSchema.nullable(),
    approved_by: z.lazy(() => userMinimalSchema).nullable(),
    requested_by: z.lazy(() => userMinimalSchema).nullable(),
    vendor: z
      .object({
        id: z.number(),
        name: z.string(),
      })
      .optional(),
    budgetable: z
      .object({
        id: z.number(),
        type: z.enum(["Team", "Project", "User", "CapitalEquipment"]),
        name: z.string(),
        app_href: z.string(),
      })
      .optional(),
    budget_group: z
      .object({
        id: z.number(),
        name: z.string(),
        app_href: z.string(),
      })
      .optional(),
    summary: budgetItemSummarySchema.optional(),
    events: timelineEventSchema.array().optional(),
  });

export const budgetItemWithSummarySchema = budgetItemSchema
  .omit({
    requested_by_id: true,
    approved_by_id: true,
    created_at: true,
    budget_id: true,
    status: true,
    supporting_evidence: true,
    amount: true,
    general_ledger_code_id: true,
  })
  .extend({
    budgetable: z
      .object({
        id: z.number(),
        type: z.enum(["Team", "Project", "User", "CapitalEquipment"]),
        name: z.string(),
      })
      .optional(),
    general_ledger_code: generalLedgerCodeSchema.nullable(),
    ...budgetItemSummarySchema.shape,
    budget_group_summary: budgetItemSummarySchema.optional(),
    budget_summary: budgetItemSummarySchema.optional(),
  });

const budgetItemSearchResponseSchema = createSearchResponseSchema(budgetItemShowSchema).extend({
  total_cost: moneySchema,
});

export const budgetableSchema = z.object({
  id: z.number(),
  type: z.enum(["Team", "Project", "User", "CapitalEquipment"]),
  name: z.string(),
  app_href: z.string(),
});

export const createBudgetItemTimelineEventBasicSchema = z.object({
  message: z.string(),
  amount: moneySchema,
});

export const budgetItemAllocationSchema = budgetItemSchema.pick({
  id: true,
  allocated_amount: true,
});

export type BudgetItemData = z.infer<typeof budgetItemSchema>;
export type BudgetItemCreateParams = Pick<
  BudgetItemData,
  "budget_id" | "item_name" | "requested_by_id"
>;
export type BudgetItemShowData = z.infer<typeof budgetItemShowSchema>;
export type BudgetItemWithSummaryData = z.infer<typeof budgetItemWithSummarySchema>;
export type BudgetItemSummary = z.infer<typeof budgetItemSummarySchema>;
export type BudgetItemSpendByModelData = z.infer<typeof budgetItemSpendByModelSchema>;
export type BudgetItemSearchResponse = z.infer<typeof budgetItemSearchResponseSchema>;
export type BudgetableData = z.infer<typeof budgetableSchema>;
export type CreateBudgetItemTimelineEventBasicData = z.infer<
  typeof createBudgetItemTimelineEventBasicSchema
>;
export type BudgetItemAllocationData = z.infer<typeof budgetItemAllocationSchema>;

/** api-queries */

export const searchBudgetItems = async ({
  aggs,
  filters,
  pagination,
  order,
  term,
}: SearchParams) => {
  const path = [BUDGET_ITEMS_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return zodParse(budgetItemSearchResponseSchema, result.data);
};

export const getBudgetItem = async (id: number, currency: string) => {
  const result = await api.get(`${BUDGET_ITEMS_BASE_URL}/${id}`, { params: { currency } });
  return zodParse(budgetItemShowSchema, result.data);
};

export const getBudgetItemEvents = async (id: number): Promise<TimelineEventData[]> => {
  const result = await api.get(`${BUDGET_ITEMS_BASE_URL}/${id}/events`);
  return zodParse(timelineEventSchema.array(), result.data);
};

export const deleteBudgetItems = async (budget_item_ids: number[]) =>
  await api.delete(`${BUDGET_ITEMS_BASE_URL}/`, { params: { budget_item_ids } });

export const createBudgetItem = async (budget_item: BudgetItemCreateParams) => {
  const result = await api.post(BUDGET_ITEMS_BASE_URL, { budget_item });
  return result.data;
};

export const getBudgetItemMonthlySpend = async (id: number) => {
  const result = await api.get(`${BUDGET_ITEMS_BASE_URL}/${id}/monthly_spend`);
  return zodParse(monthlySpendSchema, result.data);
};

export const archiveBudgetItems = async ({
  budget_item_ids,
  archived_at,
}: {
  budget_item_ids: number[];
  archived_at: string;
}) => {
  await api.post(`${BUDGET_ITEMS_BASE_URL}/archive`, {
    budget_item_ids,
    archived_at,
  });
};

export const unarchiveBudgetItem = async (id: number) => {
  await api.post(`${BUDGET_ITEMS_BASE_URL}/${id}/unarchive`);
};

export const bulkAllocateBudgetItems = async ({
  budget_items,
}: {
  budget_items: BudgetItemAllocationData[];
}) => {
  const result = await api.post(`${BUDGET_ITEMS_BASE_URL}/bulk_allocate`, { budget_items });
  return zodParse(backgroundTaskSchema, result.data);
};

export const approveBudgetItem = async (
  budget_item: Partial<BudgetItemData> & { message: string }
) => {
  await api.post(`${BUDGET_ITEMS_BASE_URL}/${budget_item.id}/approve`, {
    general_ledger_code_id: budget_item.general_ledger_code_id,
    message: budget_item.message,
  });
};

export const declineBudgetItem = async (
  budget_item: Partial<BudgetItemData> & { message: string }
) => {
  await api.post(`${BUDGET_ITEMS_BASE_URL}/${budget_item.id}/decline`, {
    message: budget_item.message,
  });
};

export const getBudgetItemSummary = async (
  id: number,
  endDate: DateTime,
  currency?: string
): Promise<BudgetItemWithSummaryData> => {
  const result = await api.get(`${BUDGET_ITEMS_BASE_URL}/${id}/summary`, {
    params: _.omitBy({ end_date: endDate.toISO(), currency: currency }, _.isUndefined),
  });
  return zodParse(budgetItemWithSummarySchema, result.data);
};

export const getBudgetItemSpendByModel = async (
  id: number,
  endDate: DateTime
): Promise<BudgetItemSpendByModelData> => {
  const result = await api.get(`${BUDGET_ITEMS_BASE_URL}/${id}/spend_by_model`, {
    params: _.omitBy({ end_date: endDate.toISO() }, _.isUndefined),
  });
  return zodParse(budgetItemSpendByModelSchema, result.data);
};

export const getCompanyWideBudgetItemSummary = async (
  endDate: DateTime
): Promise<BudgetItemSummary> => {
  const result = await api.get(`${BUDGET_ITEMS_BASE_URL}/summary`, {
    params: _.omitBy({ end_date: endDate.toISO() }, _.isUndefined),
  });
  return zodParse(budgetItemSummarySchema, result.data);
};

export const getAllTeamBudgetItemSummary = async (
  endDate: DateTime
): Promise<BudgetItemSummary> => {
  const result = await api.get(`${BUDGET_ITEMS_BASE_URL}/team_summary`, {
    params: _.omitBy({ end_date: endDate.toISO() }, _.isUndefined),
  });
  return zodParse(budgetItemSummarySchema, result.data);
};

export const getAllProjectBudgetItemSummary = async (
  endDate: DateTime
): Promise<BudgetItemSummary> => {
  const result = await api.get(`${BUDGET_ITEMS_BASE_URL}/project_summary`, {
    params: _.omitBy({ end_date: endDate.toISO() }, _.isUndefined),
  });
  return zodParse(budgetItemSummarySchema, result.data);
};

export const updateBudgetItem = async (budget_item: Partial<BudgetItemData> & { id: number }) => {
  const result = await api.put(`${BUDGET_ITEMS_BASE_URL}/${budget_item.id}`, { budget_item });
  return result.data;
};

export const mergeBudgetItems = async ({
  budget_item_ids,
  new_budget_item_id,
}: {
  budget_item_ids: number[];
  new_budget_item_id: number;
}): Promise<void> => {
  await api.post(`${BUDGET_ITEMS_BASE_URL}/merge`, {
    budget_item_ids,
    new_budget_item_id,
  });
};

export const moveBudgetItemAssociation = async ({
  destinationBudgetId,
  movingBudgetItemIds,
}: {
  destinationBudgetId: number | null;
  movingBudgetItemIds: number[];
}): Promise<void> => {
  const payload = {
    budget_item_ids: movingBudgetItemIds,
    destination_budget_id: destinationBudgetId,
  };

  await api.post(`${BUDGET_ITEMS_BASE_URL}/move_budget_items`, payload);
};

/** api-query-hooks */

export const invalidateBudget = (
  budgetId?: number,
  parentBudgetId?: number,
  budgetItemId?: number
) => {
  const queryClient = useQueryClient();
  const budgetItemQueryKey: (string | number)[] = [BUDGET_ITEMS_BASE_URL];
  if (budgetItemId) budgetItemQueryKey.push(budgetItemId);
  if (!budgetId)
    return () => {
      queryClient.invalidateQueries([BUDGETS_BASE_URL]);
      queryClient.invalidateQueries(budgetItemQueryKey);
    };
  return () => {
    queryClient.invalidateQueries([BUDGETS_BASE_URL, budgetId]);
    queryClient.invalidateQueries([BUDGETS_BASE_URL, parentBudgetId]);
    queryClient.invalidateQueries(budgetItemQueryKey);
  };
};

export const invalidateBudgetItem = (id?: number, subQuery?: string) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [BUDGET_ITEMS_BASE_URL];
  if (id) queryKey.push(id);
  if (subQuery) queryKey.push(subQuery);
  return () => {
    queryClient.invalidateQueries({ queryKey });
  };
};

export const useMergeBudgetItems = () => {
  const queryClient = useQueryClient();

  return useMutation(mergeBudgetItems, {
    onSuccess: () => {
      queryClient.invalidateQueries([BUDGETS_BASE_URL]);
      queryClient.invalidateQueries([BUDGET_ITEMS_BASE_URL]);
      queryClient.invalidateQueries([PURCHASES_BASE_URL, "team_purchases"]);
    },
  });
};

export const useGetBudgetItem = (
  id: number | null | undefined,
  currencyOverride?: string | null | undefined
) => {
  const currency = useCurrency(currencyOverride);
  return useQuery({
    queryKey: [BUDGET_ITEMS_BASE_URL, id],
    queryFn: () => getBudgetItem(id!, currency),
    enabled: !!id,
  });
};

export const useGetBudgetItemEvents = (id: number | null | undefined) => {
  return useQuery({
    queryKey: [BUDGET_ITEMS_BASE_URL, id, "events"],
    queryFn: () => getBudgetItemEvents(id!),
    enabled: !!id,
  });
};

export const useDeleteBudgetItemsQuery = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteBudgetItems,
    onSuccess: () => queryClient.invalidateQueries([BUDGETS_BASE_URL]),
  });
};

export const useCreateBudgetItemQuery = (budgetId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createBudgetItem,
    onSuccess: () => queryClient.invalidateQueries([BUDGETS_BASE_URL]),
  });
};

export const useUpdateBudgetItemQuery = (
  budgetItemId?: number,
  budgetId?: number,
  parentBudgetId?: number
) => {
  return useMutation({
    mutationFn: updateBudgetItem,
    onSuccess: invalidateBudget(budgetItemId, budgetId, parentBudgetId),
  });
};

export const useArchiveBudgetItemsQuery = () => {
  return useMutation({
    mutationFn: archiveBudgetItems,
    onSuccess: invalidateBudget(),
  });
};

export const useUnarchiveBudgetItemQuery = () => {
  return useMutation({
    mutationFn: unarchiveBudgetItem,
    onSuccess: invalidateBudget(),
  });
};

export const useBulkAllocateBudgetItems = () => {
  return useMutation({
    mutationFn: bulkAllocateBudgetItems,
    onSuccess: invalidateBudget(),
  });
};

export const useApproveBudgetItemQuery = () => {
  return useMutation({
    mutationFn: approveBudgetItem,
    onSuccess: invalidateBudget(),
  });
};

export const useDeclineBudgetItemQuery = () => {
  return useMutation({
    mutationFn: declineBudgetItem,
    onSuccess: invalidateBudget(),
  });
};

export const useMoveBudgetItemAssociation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: moveBudgetItemAssociation,
    onSuccess: () => queryClient.invalidateQueries([BUDGETS_BASE_URL]),
  });
};

export const useSearchBudgetItemsQuery = (params: SearchParams & { team_ids?: number[] }) => {
  return useQuery({
    queryKey: [BUDGET_ITEMS_BASE_URL, params],
    queryFn: () => searchBudgetItems(params),
  });
};

export const useBudgetItemSummary = (
  id: number | null | undefined,
  endDate: DateTime,
  currencyOverride?: string | null | undefined
) => {
  const currency = useCurrency(currencyOverride);
  return useQuery({
    queryKey: [BUDGET_ITEMS_BASE_URL, id, "summary", endDate.toISO(), currency],
    queryFn: (): Promise<BudgetItemWithSummaryData> => {
      return getBudgetItemSummary(id!, endDate, currency);
    },
    enabled: !!id,
  });
};

export const useBudgetItemSpendByModel = (id: number | null | undefined, endDate: DateTime) => {
  return useQuery({
    queryKey: [BUDGET_ITEMS_BASE_URL, id, "spend_by_model", endDate.toISO()],
    queryFn: (): Promise<BudgetItemSpendByModelData> => getBudgetItemSpendByModel(id!, endDate),
    enabled: !!id,
  });
};

export const useBudgetItemMonthlySpend = (id: number | null | undefined) => {
  return useQuery({
    queryKey: [BUDGET_ITEMS_BASE_URL, id, "monthly_spend"],
    queryFn: () => getBudgetItemMonthlySpend(id!),
    enabled: !!id,
  });
};

export const useCompanyWideBudgetItemSummary = (endDate: DateTime, enabled = true) => {
  return useQuery({
    queryKey: [BUDGET_ITEMS_BASE_URL, "summary", endDate.toISO()],
    queryFn: (): Promise<BudgetItemSummary> => getCompanyWideBudgetItemSummary(endDate),
    enabled: enabled,
  });
};

export const useAllTeamBudgetItemSummary = (endDate: DateTime, enabled = true) => {
  return useQuery({
    queryKey: [BUDGET_ITEMS_BASE_URL, "team_summary", endDate.toISO()],
    queryFn: (): Promise<BudgetItemSummary> => getAllTeamBudgetItemSummary(endDate),
    enabled: enabled,
  });
};

export const useAllProjectBudgetItemSummary = (endDate: DateTime, enabled = true) => {
  return useQuery({
    queryKey: [BUDGET_ITEMS_BASE_URL, "project_summary", endDate.toISO()],
    queryFn: (): Promise<BudgetItemSummary> => getAllProjectBudgetItemSummary(endDate),
    enabled: enabled,
  });
};

const yearToDateBudgetItemSummarySchema = z
  .object({
    date: z.string(),
  })
  .extend(budgetItemSummarySchema.shape);

type YearToDateBudgetItemSummary = z.infer<typeof yearToDateBudgetItemSummarySchema>;
export const useGetYearToDateBudgetItemSummaries = (budgetItemId: number) => {
  const currency = useCurrency();
  return useQuery({
    queryKey: [BUDGET_ITEMS_BASE_URL, ["budgetItemSummaries", budgetItemId]],
    queryFn: async (): Promise<YearToDateBudgetItemSummary[]> => {
      const endDate = DateTime.now().endOf("year");
      const startDate = endDate.startOf("year").endOf("month");
      const dates: DateTime[] = [];
      for (let dt = startDate; dt <= endDate; dt = dt.plus({ months: 1 })) {
        dates.push(dt);
      }
      const results = await Promise.all(
        dates.map((date) => {
          return getBudgetItemSummary(budgetItemId, date, currency);
        })
      );
      return results.map((summary, index) => ({
        ...summary,
        date: dates[index].toISODate() as string,
      }));
    },
    enabled: !!budgetItemId,
  });
};
