import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Stack,
  VStack,
  Text,
  useColorModeValue,
  Divider,
  HStack,
  Box,
  IconButton,
  Input,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Spinner,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { ImLink } from "react-icons/im";
import { invalidateMeetingNotes, useGetProjectMeetingNotes } from "../../api/meeting_notes";
import { RecordLink } from "@sciencecorp/helix-components";
import { useCreateProjectMeetingNote } from "../../api/planning/project_meeting_notes";
import { useDeleteProjectMeetingNote } from "../../api/planning/project_meeting_notes";
import { useProjectOptions } from "../../api/options";

export type AttachProjectsToMeetingNoteProps = {
  meetingNoteId: number;
  editable: boolean;
};

export const AttachProjectsToMeetingNote = ({
  meetingNoteId,
  editable,
}: AttachProjectsToMeetingNoteProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { data: projectMeetingNotes, isLoading: projectMeetingNotesLoading } =
    useGetProjectMeetingNotes(meetingNoteId);
  const projectOptions = useProjectOptions({
    excludeIds: projectMeetingNotes?.map((project) => project.project_id) ?? [],
    searchTerm: searchTerm,
  });
  const { mutateAsync: createProjectMeetingNote } = useCreateProjectMeetingNote();
  const { mutateAsync: deleteProjectMeetingNote } = useDeleteProjectMeetingNote();
  const trashBgColor = useColorModeValue("red.50", "red.800");
  const trashColor = useColorModeValue("red.500", "red.100");
  const trashHoverBgColor = useColorModeValue("red.100", "red.700");
  const invalidateMeetingNote = invalidateMeetingNotes(meetingNoteId);
  return (
    <VStack p={4} border="1px" borderRadius="md" spacing={2} borderColor={"chakra-border-color"}>
      <Stack
        gap={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%">
        <HStack>
          <Box
            bgColor={useColorModeValue("green.100", "green.500")}
            height={"100%"}
            width={"max-content"}
            color={useColorModeValue("green.700", "green.100")}
            p={"2"}
            marginRight={"2"}
            borderRadius={"md"}>
            <ImLink size={"20px"} />
          </Box>
          <Text fontSize={"sm"} textColor={useColorModeValue("gray.700", "auto")}>
            Projects being discussed:
          </Text>
        </HStack>
        <Popover placement="bottom-end">
          <PopoverTrigger>
            <IconButton
              icon={<AddIcon />}
              aria-label="Add Project to Meeting Note"
              size="xs"
              isDisabled={!editable}
            />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverCloseButton />
            <PopoverBody maxH={52} overflowY="scroll">
              <VStack align="start" padding={2}>
                <Text fontWeight="semibold">Add Project to Meeting Note</Text>
                {projectOptions && (
                  <Input
                    mb={2}
                    placeholder="Search for a project..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                )}
                {projectOptions.map((project) => (
                  <Text
                    width={"100%"}
                    key={project.value}
                    _hover={{ cursor: "pointer", bgColor: "gray.100" }}
                    onClick={() =>
                      createProjectMeetingNote({
                        project_id: project.value,
                        meeting_note_id: meetingNoteId,
                      }).then(invalidateMeetingNote)
                    }>
                    {project.label}
                  </Text>
                ))}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Stack>
      <Divider />
      {projectMeetingNotes && projectMeetingNotes.length > 0 ? (
        <Stack direction="column" alignItems="flex-start" padding={2} width="100%">
          {projectMeetingNotes.map((project) => (
            <HStack key={project.id} justifyContent="space-between" width="100%">
              <RecordLink
                key={project.id}
                identifier={`${project.project_name}`}
                type="Project"
                maxWidth="18ch"
                link={`/planning/projects/${project.project_id}`}
              />
              <IconButton
                isDisabled={!editable}
                onClick={() => deleteProjectMeetingNote(project.id).then(invalidateMeetingNote)}
                sx={{
                  bg: trashBgColor,
                  color: trashColor,
                  _hover: {
                    bg: trashHoverBgColor,
                  },
                }}
                icon={<DeleteIcon />}
                aria-label="Remove Project from Meeting Note"
                size="xs"
              />
            </HStack>
          ))}
        </Stack>
      ) : (
        <Text fontSize="sm" color="gray.500">
          No projects are associated with this meeting note.
        </Text>
      )}
    </VStack>
  );
};
