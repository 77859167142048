import React, { useMemo } from "react";
import {
  Box,
  Heading,
  HStack,
  VStack,
  Text,
  Divider,
  Icon,
  useColorModeValue,
  Grid,
  LinkBox,
  LinkOverlay,
  GridItem,
  Avatar,
} from "@chakra-ui/react";
import { BiBarChartSquare } from "react-icons/bi";
import { FiChevronRight } from "react-icons/fi";
import { useCurrentUserQuery, userHasRole } from "../../api/user";
import { Select } from "chakra-react-select";
import { HrWarning } from "./warning";
import { useIncompleteReviewPromptsQuery } from "../../api/review";
import { EmployeePartialData, useEmployeeListQuery } from "../../api/hr";
import { useUserQuery } from "../../api/user";
import { Link } from "react-router-dom";
import { EmployeeCard } from "./components/EmployeeCard";
import { orderBy } from "lodash";

const otherPages = [
  {
    title: "Polls",
    description:
      "Share your voice and help shape a better workplace. View responses and insights from company-wide polls.",
    icon: BiBarChartSquare,
    href: "/hr/polls",
  },
];

export const HR = () => {
  const currentUserQuery = useCurrentUserQuery();
  const { data } = useEmployeeListQuery();
  const mostRecentEmployees = useMemo(() => data?.results.slice(0, 5), [data?.results]);
  const { data: incompleteReviewPrompts } = useIncompleteReviewPromptsQuery();

  return userHasRole(currentUserQuery, "hr_admin") ? (
    <Box>
      <HrWarning />
      <Heading size="lg" mb={2}>
        People Operations
      </Heading>
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
        gap={6}
        marginTop="4">
        <GridItem colSpan={{ base: 1, md: 2, lg: 1 }}>
          <VStack
            width="100%"
            border="1px"
            flex="1"
            p={5}
            height="sm"
            spacing={5}
            borderRadius="md"
            borderColor={useColorModeValue("gray.200", "gray.600")}>
            <HStack justify="space-between" width="100%">
              <Heading size="md">
                All Employees{" "}
                <Box as="span" fontWeight="light" color="gray.500">{`(${data?.total})`}</Box>
              </Heading>
              <Link to="/hr/employees">
                <Text color="teal.500"> View All</Text>
              </Link>
            </HStack>
            <Divider />
            <VStack align="start" width="100%" spacing={4}>
              <Heading
                size="xs"
                letterSpacing="wider"
                textTransform="uppercase"
                color={useColorModeValue("gray.500", "gray.300")}>
                Recently Joined
              </Heading>
              {mostRecentEmployees?.map((employee: EmployeePartialData) => (
                <EmployeeCard key={employee.id} employee={employee} />
              ))}
            </VStack>
          </VStack>
        </GridItem>

        <GridItem colSpan={{ base: 1, md: 2, lg: 1 }}>
          <VStack
            flex="1"
            align="start"
            border="1px"
            overflowY="scroll"
            height="sm"
            p={5}
            spacing={4}
            borderRadius="md"
            borderColor={useColorModeValue("gray.200", "gray.600")}>
            <HStack justify="space-between" width="100%">
              <Heading size="md">
                Flags{" "}
                <Box as="span" fontWeight="light" color="gray.500">{`(${
                  Object.keys(incompleteReviewPrompts || {}).length
                })`}</Box>
              </Heading>
              <Select
                size="sm"
                value={{ label: "Incomplete Reviews", value: "incomplete_reviews" }}
                options={[{ label: "Incomplete Reviews", value: "incomplete_reviews" }]}
                useBasicStyles
              />
            </HStack>
            <Divider />

            <VStack width="100%" align="start" spacing={4}>
              {orderBy(
                Object.entries(incompleteReviewPrompts || {}),
                ([employeeId, promptCount]) => promptCount,
                "desc"
              ).map(([employeeId, promptCount]) => (
                <OutstandingEmployeeCard
                  key={employeeId}
                  employeeId={employeeId}
                  promptCount={promptCount}
                />
              ))}
            </VStack>
          </VStack>
        </GridItem>

        <GridItem colSpan={{ base: 1, md: 2, lg: 1 }}>
          <VStack flex="1" justify="space-between" minH="300px">
            {otherPages.map((page) => (
              <AdditionalPage key={page.title} page={page} />
            ))}
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  ) : (
    <p>Not authorized</p>
  );
};

const AdditionalPage = ({ page }) => (
  <LinkBox w="100%">
    <LinkOverlay as={Link} to={page.href}>
      <HStack
        border="1px"
        borderColor={useColorModeValue("gray.200", "gray.600")}
        borderRadius="md"
        bg={useColorModeValue("gray.50", "gray.700")}
        p={4}
        spacing={5}>
        <Icon as={page.icon} boxSize={8} color="gray.500" />
        <VStack width="100%" align="start" spacing={1}>
          <Heading size="sm">{page.title}</Heading>
          <Text fontSize="xs">{page.description}</Text>
        </VStack>
        <Icon as={FiChevronRight} boxSize={6} color="gray.500" />
      </HStack>
    </LinkOverlay>
  </LinkBox>
);

const OutstandingEmployeeCard = ({ employeeId, promptCount }) => {
  const { data: user } = useUserQuery(employeeId);
  return (
    <LinkBox width="100%">
      <LinkOverlay as={Link} to={`/hr/employees/${user?.id}`}>
        <HStack justify="space-between" align="center">
          <HStack>
            <Avatar size="sm" src={user?.picture_uri} />
            <VStack width="100%" align="start" spacing={0}>
              <Heading size="xs">{user?.name}</Heading>
              <Text fontSize="xs" color={useColorModeValue("gray.500", "gray.400")}>
                {user?.employee_title}
              </Text>
            </VStack>
          </HStack>
          <Text fontSize="xs" color={useColorModeValue("gray.500", "gray.400")}>
            {promptCount} incomplete reviews
          </Text>
        </HStack>
      </LinkOverlay>
    </LinkBox>
  );
};
