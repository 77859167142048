import React, { useState, useEffect } from "react";
import {
  Box,
  VStack,
  Spinner,
  Center,
  HStack,
  Grid,
  Heading,
  IconButton,
  Text,
  Tag,
  Button,
  EditableTextarea,
  Editable,
  EditablePreview,
  useEditableControls,
  Card,
  CardHeader,
  CardBody,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useColorModeValue,
} from "@chakra-ui/react";
import { Header, SplitPage } from "@sciencecorp/helix-components";
import { Navigate, useParams } from "react-router-dom";
import {
  useInvalidateIncidentReport,
  useGetIncidentReportQuery,
  useUpdateIncidentReportMutation,
} from "../../api/incident_reports";
import { TimelineTable } from "../shared/TimelineTable";
import { useCurrentUserQuery } from "../../api/user";
import { IncidentReportDetailsSidebar } from "./IncidentReportDetailsSidebar";
import { useQueryClient } from "@tanstack/react-query";
import { DraftCommand, IncidentReport } from "../../types/incident_reports";
import { ChevronDownIcon, EditIcon } from "@chakra-ui/icons";
import { DateTime } from "luxon";

const commandReducer = (
  incidentReport: IncidentReport | undefined,
  command: DraftCommand
): IncidentReport | undefined => {
  if (!incidentReport) return undefined;
  switch (command.event_type) {
    case "incident_type_updated":
      return { ...incidentReport, incident_type: command.event_data.incident_type };
    case "site_updated":
      return {
        ...incidentReport,
        site: { id: command.event_data.site_id, name: command.event_data.site_name },
      };
    case "discovered_at_updated":
      return {
        ...incidentReport,
        discovered_at: DateTime.fromJSDate(command.event_data.discovered_at),
      };
    case "description_updated":
      return { ...incidentReport, description: command.event_data.description };
    default:
      return incidentReport;
  }
};

const DescriptionEditableControls = () => (
  <IconButton
    aria-label="Edit"
    icon={<EditIcon />}
    size="xs"
    {...useEditableControls().getEditButtonProps()}
  />
);

export const IncidentReportDetails = () => {
  const rawId = useParams<{ id: string }>().id;
  const id = rawId ? parseInt(rawId) : undefined;
  if (!id) {
    return <Navigate to="/incident-reports" />;
  }
  const { data: persistedIncidentReport, isLoading: isLoadingReport } =
    useGetIncidentReportQuery(id);
  const [draftCommands, setDraftCommands] = useState<DraftCommand[]>([]);
  const [draftIncidentReport, setDraftIncidentReport] = useState(persistedIncidentReport);
  useEffect(() => {
    if (draftCommands.length) {
      // WARN: some risk of `persistedIncidentReport` updating with existing commands
      setDraftIncidentReport(draftCommands.reduce(commandReducer, persistedIncidentReport));
    } else {
      setDraftIncidentReport(undefined);
    }
  }, [draftCommands, persistedIncidentReport]);
  const addCommandInDraft = (command: DraftCommand) => {
    setDraftCommands([...draftCommands, command]);
  };
  const displayIncidentReport = draftIncidentReport || persistedIncidentReport;

  const { data: currentUser } = useCurrentUserQuery();

  const invalidateIncidentReport = useInvalidateIncidentReport(id);

  const draftMode = draftCommands.length > 0;

  const [currentDescription, setCurrentDescription] = useState<string | null>(null);
  useEffect(() => {
    if (displayIncidentReport) {
      setCurrentDescription(displayIncidentReport.description);
    }
  }, [displayIncidentReport]);

  const { mutateAsync: updateIncidentReport, isLoading: isUpdatingIncidentReport } =
    useUpdateIncidentReportMutation();

  const borderColor = useColorModeValue("gray.200", "gray.600");
  const backgroundColor = useColorModeValue("gray.50", "gray.700");

  if (isLoadingReport || !currentUser) {
    return (
      <Center py={4}>
        <Spinner />
      </Center>
    );
  }

  if (!displayIncidentReport) {
    return (
      <Center>
        <Text>No incident report found</Text>
      </Center>
    );
  }

  return (
    <Box>
      {!draftMode ? (
        <></>
      ) : (
        <Alert status="warning" mb={4}>
          <AlertIcon />
          <AlertTitle>Draft Mode</AlertTitle>
          <AlertDescription>
            You have updated the investigation details. Save your changes to avoid losing them, and
            submit for approval when ready
          </AlertDescription>
        </Alert>
      )}
      <Header
        title={`Incident Report ${id}`}
        crumbs={[{ label: "Incident Reports", url: "/incident-reports" }]}
        crumbsColor="teal.500"
        badge={{
          label: "Status",
          colorScheme: "green",
        }}
        actions={[
          <Button
            colorScheme="orange"
            variant="outline"
            isLoading={isUpdatingIncidentReport}
            onClick={() =>
              updateIncidentReport({
                id,
                commands: draftCommands,
              }).then(() => {
                setDraftCommands([]);
                invalidateIncidentReport();
              })
            }>
            Save Draft
          </Button>,
          <Button colorScheme="red" variant="outline" onClick={() => setDraftCommands([])}>
            Discard Draft Changes
          </Button>,
          <Button colorScheme="teal">Submit for Approval</Button>,
        ]}
      />
      <Box>
        <SplitPage
          sidebar={
            <IncidentReportDetailsSidebar
              incidentReport={displayIncidentReport}
              addCommandInDraft={addCommandInDraft}
              currentUser={currentUser}
              isInDraftMode={draftMode}
            />
          }
          main={
            <VStack spacing={4} align="stretch" width="100%">
              <Grid templateColumns="1fr 1fr" gap={4}>
                <Box border="1px solid" borderColor={borderColor} borderRadius="md" p={4}>
                  <Editable
                    value={currentDescription ?? undefined}
                    isPreviewFocusable={false}
                    onChange={(value) => {
                      setCurrentDescription(value);
                    }}
                    onSubmit={(value) => {
                      addCommandInDraft({
                        event_type: "description_updated",
                        event_data: {
                          description: value,
                        },
                      });
                    }}>
                    <HStack justify="space-between" mb={2}>
                      <Heading size="sm">Description</Heading>
                      <DescriptionEditableControls />
                    </HStack>
                    <EditablePreview>{displayIncidentReport.description}</EditablePreview>
                    <EditableTextarea />
                  </Editable>
                </Box>
                <Box border="1px solid" borderColor={borderColor} borderRadius="md" p={4}>
                  <HStack justify="space-between">
                    <Heading size="sm">
                      References <Tag size="sm">1</Tag>
                    </Heading>
                    <Button size="xs">
                      + Add <ChevronDownIcon />
                    </Button>
                  </HStack>
                  <Box height="15rem">{/* TODO */}</Box>
                </Box>
              </Grid>
              <Card
                border="1px solid"
                borderColor={borderColor}
                backgroundColor={backgroundColor}
                borderRadius="md">
                <CardHeader>
                  <HStack justify="space-between">
                    <Heading size="md">Investigation</Heading>
                    <Button colorScheme="red" size="md" variant="outline">
                      Mark as Not Required
                    </Button>
                  </HStack>
                </CardHeader>
                <CardBody>
                  <Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                  </Text>
                </CardBody>
              </Card>
              <Card
                border="1px solid"
                borderColor={borderColor}
                backgroundColor={backgroundColor}
                borderRadius="md">
                <CardHeader>
                  <Heading size="md">Action Plan</Heading>
                </CardHeader>
                <CardBody>
                  <HStack justify="start" spacing={4}>
                    <Button size="md" colorScheme="teal">
                      Propose Action Plan
                    </Button>
                    <Button size="md" colorScheme="red" variant="outline">
                      Mark as Not Required
                    </Button>
                  </HStack>
                </CardBody>
              </Card>
              <Card
                border="1px solid"
                borderColor={borderColor}
                backgroundColor={backgroundColor}
                borderRadius="md">
                <CardHeader>
                  <Heading size="md">Effectiveness Check</Heading>
                </CardHeader>
                <CardBody>
                  <HStack justify="start" spacing={4}>
                    <Button size="md" colorScheme="teal">
                      Schedule Effectiveness Check
                    </Button>
                  </HStack>
                </CardBody>
              </Card>
              {draftMode ? (
                <></>
              ) : (
                <Box data-testid="incident-report-timeline">
                  <TimelineTable
                    timelineable_id={id}
                    timelineable_type="IncidentReport"
                    events={displayIncidentReport.timeline_events || []}
                    onComment={invalidateIncidentReport}
                  />
                </Box>
              )}
            </VStack>
          }
        />
      </Box>
    </Box>
  );
};
