import { Tag } from "@chakra-ui/react";
import { RecordLink } from "@sciencecorp/helix-components";
import { humanize, titleize } from "inflection";
import React from "react";
import { useSearchPurchases } from "../../../../../../api/purchase";
import { MoneyText } from "../../../../../MoneyText";
import { purchaseRequestStatusColor } from "../../../../../Purchasing/util";
import { buildTabComponent } from "../../../../../TabbedTable";
import { useSearchSubscriptions } from "../../../../../../api/subscription";

export const SubscriptionTab = buildTabComponent({
  label: "Subscriptions",
  columns: [
    {
      label: "Subscription",
      orderOptions: { orderKey: "id" },
      render: (subscription) => (
        <RecordLink
          type=""
          identifier={`#${subscription.id}`}
          link={`/services/subscriptions/${subscription.id}`}
        />
      ),
    },
    {
      label: "Vendor",
      orderOptions: { orderKey: "vendor_name" },
      render: (subscription) => (
        <RecordLink
          type=""
          maxWidth="15ch"
          identifier={subscription.vendor?.name || ""}
          link={`/services/vendors/${subscription.vendor?.id}`}
        />
      ),
    },
    {
      label: "Amount Paid",
      render: (subscription) => (
        <MoneyText money={subscription.total_paid} formatOptions={{ compact: "never" }} />
      ),
    },
    {
      label: "Max Amount for Interval",
      render: (subscription) => (
        <MoneyText
          money={subscription.projected_max_amount_per_interval}
          formatOptions={{ compact: "never" }}
        />
      ),
    },
    {
      label: "Interval",
      render: (subscription) => subscription.interval,
    },
    {
      label: "Status",
      orderOptions: { orderKey: "status" },
      render: (purchase) => (
        <Tag colorScheme={purchaseRequestStatusColor(purchase.status)} whiteSpace="nowrap">
          {titleize(humanize(purchase.status))}
        </Tag>
      ),
    },
  ],
  query: useSearchSubscriptions,
});
