import {
  VStack,
  Text,
  Flex,
  useColorModeValue,
  Divider,
  LinkBox,
  HStack,
  Box,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router";
import { MeetingNoteDataWithAttendees } from "../../api/meeting_notes";
import { DateTime } from "luxon";
import { Avatar } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

export type MeetingItemInfoProps = {
  meeting: MeetingNoteDataWithAttendees;
};

export const useGradient = (light: string, dark: string) => {
  const color = useColorModeValue(light, dark);
  return `linear-gradient(to right, var(--chakra-colors-transparent) 0, var(--chakra-colors-gray-${color}) 80%)`;
};

export const MeetingItemInfo = ({ meeting }: MeetingItemInfoProps) => {
  const navigate = useNavigate();
  const dateTime = meeting?.meeting_time;
  const date = dateTime && dateTime.toLocaleString(DateTime.DATE_MED);
  const time = dateTime && dateTime.toLocaleString(DateTime.TIME_SIMPLE);
  const [avatarGroupSize, setAvatarGroupSize] = useState(8);
  const baseScreenSize = useBreakpointValue({ base: true, md: false });
  const gradient = useGradient("50", "700");
  const textColor = useColorModeValue("gray.500", "auto");

  useEffect(() => {
    if (baseScreenSize) {
      setAvatarGroupSize(4);
    } else {
      setAvatarGroupSize(8);
    }
  }, [baseScreenSize]);

  return (
    <Flex
      justifyContent={"space-between"}
      width={"100%"}
      alignItems={"center"}
      p={"4"}
      onClick={() => navigate(`/meeting_notes/${meeting.id}`)}
      cursor={"pointer"}
      borderBottom={"1px"}
      borderColor={"chakra-border-color"}>
      <VStack spacing={2} alignItems={"flex-start"}>
        <Text noOfLines={1}>{meeting.title}</Text>
        <Text color={textColor}>
          {date} at {time}
        </Text>
      </VStack>
      <Flex>
        <Flex position="relative" zIndex={0}>
          {meeting?.meeting_attendees.slice(0, avatarGroupSize).map((attendee) => (
            <LinkBox
              marginInlineEnd={-2}
              key={attendee.id + attendee.user.name}
              as={RouterLink}
              to={`/users/${attendee.user.id}`}>
              <Avatar name={attendee.user.name} src={attendee.user.picture_uri} boxSize={"10"} />
            </LinkBox>
          ))}
          {meeting.meeting_attendees.length > avatarGroupSize && (
            <Flex
              zIndex={1}
              width="min-content"
              height="100%"
              align="center"
              right={-5}
              background={gradient}
              lineHeight="shorter"
              pointerEvents="none"
              paddingInlineStart={32}
              position="absolute">
              <Text pointerEvents="all" fontSize="sm" textAlign="end">
                +{meeting.meeting_attendees.length - avatarGroupSize} more
              </Text>
            </Flex>
          )}
        </Flex>

        <ChevronRightIcon
          marginLeft={meeting.meeting_attendees.length > avatarGroupSize ? "12" : "6"}
        />
      </Flex>
    </Flex>
  );
};

export const MeetingNavBarItems = ({ meeting }: MeetingItemInfoProps) => {
  const navigate = useNavigate();
  const dateTime = meeting?.meeting_time;
  const date = dateTime && dateTime.toLocaleString(DateTime.DATE_MED);
  const time = dateTime && dateTime.toLocaleString(DateTime.TIME_SIMPLE);
  const [avatarGroupSize, setAvatarGroupSize] = useState(7);
  const gradient = useGradient("50", "700");

  return (
    <Box onClick={() => navigate(`/meeting_notes/${meeting.id}`)}>
      <Flex
        justifyContent={"space-between"}
        alignItems={"center"}
        role="group"
        width={"100%"}
        cursor={"pointer"}
        borderColor={"chakra-border-color"}>
        <VStack spacing={0.2} alignItems={"flex-start"}>
          <Text noOfLines={1} color={useColorModeValue("gray.800", "auto")}>
            {meeting.title}
          </Text>
          <Text color={useColorModeValue("gray.500", "auto")} fontSize={"xs"}>
            {date} at {time}
          </Text>
        </VStack>
        <Flex
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          align={"center"}
          flex={1}
          justify={"flex-end"}
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}>
          <ChevronRightIcon />
        </Flex>
      </Flex>
      <HStack>
        <Flex width="100%" position="relative" zIndex={0}>
          {meeting?.meeting_attendees.slice(0, avatarGroupSize).map((attendee) => (
            <LinkBox
              marginInlineEnd={-2}
              key={attendee.id}
              marginTop={2}
              marginBottom={2}
              as={RouterLink}
              to={`/users/${attendee.id}`}>
              <Avatar name={attendee.user.name} src={attendee.user.picture_uri} boxSize={"8"} />
            </LinkBox>
          ))}
          {meeting.meeting_attendees.length > avatarGroupSize && (
            <Flex
              zIndex={1}
              width="min-content"
              height="100%"
              align="center"
              right={10}
              background={gradient}
              lineHeight="shorter"
              pointerEvents="none"
              marginInlineEnd={8}
              position="absolute">
              <Text pointerEvents="all" fontSize="sm" textAlign="end">
                +{meeting.meeting_attendees.length - avatarGroupSize} more
              </Text>
            </Flex>
          )}
        </Flex>
      </HStack>
      <Divider width={"110%"} />
    </Box>
  );
};
