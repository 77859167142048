import { Header } from "@sciencecorp/helix-components";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Flex,
  VStack,
  Center,
  AlertIcon,
  AlertTitle,
  Alert,
  AlertDescription,
  IconButton,
  Button,
  Box,
  Spacer,
  Tag,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useGetPoll } from "../../../api/polls";
import { humanize } from "inflection";
import { FormFieldSubmission } from "../../Requests/components/ServiceRequest/FormFieldSubmission";
import { CloseIcon, ViewIcon } from "@chakra-ui/icons";
import { useConfidentialityOptions } from "../../../api/options";

export const PollPreviewPage = () => {
  const { id } = useParams();
  const { data: poll, isSuccess } = useGetPoll(Number(id));
  const { control } = useForm({});
  const template = poll?.form.draft_template || poll?.form.active_template;
  const navigate = useNavigate();
  const confidentialityOptions = useConfidentialityOptions();
  return (
    <>
      {isSuccess && (
        <>
          <Alert status="warning" alignItems="center" marginBottom={4}>
            <AlertIcon />
            <VStack alignItems="start" gap={0}>
              <AlertTitle>Preview Mode</AlertTitle>
              <AlertDescription>This is a preview of the poll.</AlertDescription>
            </VStack>
          </Alert>

          <Header
            title={"Preview"}
            crumbs={[
              { label: "HR", url: "/hr" },
              { label: "Polls", url: "/hr/polls" },
              { label: `Poll ${poll.id.toString()}`, url: `/hr/polls/${id}` },
            ]}
            crumbsColor="teal"
            actions={[
              <IconButton
                aria-label="Close-preview"
                icon={<CloseIcon />}
                onClick={() => {
                  navigate(`/hr/polls/${id}`);
                }}></IconButton>,
            ]}
          />
          {poll && (
            <Center>
              <Flex
                direction="column"
                gap={4}
                width="50%"
                p={7}
                border="1px"
                borderColor="gray.200"
                borderRadius="md"
                boxShadow="md">
                <VStack width="100%" gap={4} mb={4}>
                  {template?.fields.map((field, idx) => (
                    <FormFieldSubmission
                      key={`field-${field.id}-${idx}`}
                      formField={field}
                      control={control}
                      isPreview={true}
                    />
                  ))}
                  <Flex flex={1} justifyContent="space-between" width="100%" mt={4}>
                    <Tag
                      colorScheme={
                        confidentialityOptions.find(
                          (option) => option.value === poll.confidentiality
                        )?.color
                      }>
                      <ViewIcon mr={2} /> {humanize(poll.confidentiality)}
                    </Tag>
                    <Spacer />
                    <Button colorScheme="teal">Submit</Button>
                  </Flex>
                </VStack>
              </Flex>
            </Center>
          )}
        </>
      )}
    </>
  );
};
