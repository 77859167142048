import { Box, Center, Spinner, Text } from "@chakra-ui/react";
import React from "react";

export const IncidentReportsAnalytics = () => {
  const isLoading = false;

  if (isLoading) {
    return (
      <Center h="200px">
        <Spinner />
      </Center>
    );
  }

  return (
    <Box>
      <Text fontSize="lg" mb={4}>
        Analytics Dashboard
      </Text>

      {/* TODO: Add charts and analytics visualizations here */}
      <Text color="gray.500">Analytics visualizations coming soon...</Text>
    </Box>
  );
};
