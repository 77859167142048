export const DefaultIncidentTypes = [
  "Protocol Deviation",
  "SOP Deviation",
  "Planned Deviation / Waiver",
  "Unforeseen Circumstance",
  "Nonconformance",
  "Nonconforming Product",
  "Adverse Event",
  "Significant Adverse Event",
  "Audit Finding",
  "Customer Complaint",
  "Customer Feedback",
  "Corrective Action",
  "Preventive Action",
];
