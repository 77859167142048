import { z } from "zod";
import { timelineEventSchema } from "../api/timeline_events";
import { approvableSchema } from "../api/approvals";
import { dateTimeSchema } from "../helpers/dateTime";
export const incidentReportSchema = approvableSchema.extend({
  id: z.number(),
  incident_type: z.string(),
  description: z.string(),
  discovered_at: dateTimeSchema.nullable(),
  created_at: dateTimeSchema,
  updated_at: dateTimeSchema,
  site: z.object({
    id: z.number(),
    name: z.string(),
  }),
  timeline_events: z.array(timelineEventSchema),
});

export type IncidentReport = z.infer<typeof incidentReportSchema>;

export interface CreateIncidentReportParams {
  incident_report: {
    incident_type: string;
    site_id: number;
    description: string;
    discovered_at?: Date;
  };
}

export interface UpdateIncidentReportParams {
  id: number;
  commands: DraftCommand[];
}

export const searchIncidentReportsResponseSchema = z.object({
  results: z.array(incidentReportSchema),
  aggregations: z.record(z.any()).nullable(),
});

export type SearchIncidentReportsResponse = z.infer<typeof searchIncidentReportsResponseSchema>;

export interface IncidentTypeUpdatedCommand {
  event_type: "incident_type_updated";
  event_data: {
    incident_type: string;
  };
}

export interface DescriptionUpdatedCommand {
  event_type: "description_updated";
  event_data: {
    description: string;
  };
}

export interface DiscoveredAtUpdatedCommand {
  event_type: "discovered_at_updated";
  event_data: {
    discovered_at: Date;
  };
}

export interface SiteUpdatedCommand {
  event_type: "site_updated";
  event_data: {
    site_id: number;
    site_name: string;
  };
}

export interface CommentCommand {
  event_type: "comment";
  event_data: {
    comment: string;
  };
}

export interface ApprovalApprovedCommand {
  event_type: "approval_approved";
  event_data: {};
}

export interface ApprovalDeclinedCommand {
  event_type: "approval_declined";
  event_data: {};
}

export type DraftCommand =
  | IncidentTypeUpdatedCommand
  | SiteUpdatedCommand
  | DescriptionUpdatedCommand
  | DiscoveredAtUpdatedCommand
  | ApprovalApprovedCommand
  | ApprovalDeclinedCommand;
