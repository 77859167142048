import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Text,
  useColorModeValue,
  LinkBox,
  LinkOverlay,
  VStack,
  Tag,
  Button,
  HStack,
  Divider,
  Heading,
  Flex,
} from "@chakra-ui/react";
import { useCurrentUserQuery } from "../../../api/user";
import { ReimbursementIndexItemData, useSearchReimbursements } from "../../../api/reimbursement";
import {
  ServiceRequestIndexItemData,
  useSearchServiceRequestsQuery,
} from "../../../api/service_requests";
import { PurchaseIndexItemData, useSearchPurchases } from "../../../api/purchase";
import { EmptyState } from "@sciencecorp/helix-components";
import { titleize, humanize } from "inflection";
import { purchaseRequestStatusColor } from "../../Purchasing/util";
import { reimbursementStatusColor } from "../../Purchasing/Reimbursements/components/utils";
import { ChevronRightIcon } from "@chakra-ui/icons";
import _ from "lodash";
import { DateTime } from "luxon";

export const InProgressList = () => {
  const { data: currentUser, isLoading: isLoadingUser, error: userError } = useCurrentUserQuery();

  if (isLoadingUser || !currentUser) {
    return <Text>Loading...</Text>;
  }

  const {
    data: serviceRequests,
    isLoading: isLoadingRequests,
    error: requestError,
  } = useSearchServiceRequestsQuery({
    term: "*",
    filters: {
      requesting_user_id: currentUser?.id,
    },
  });

  const {
    data: purchaseRequests,
    isLoading: isLoadingPurchases,
    error: purchaseError,
  } = useSearchPurchases({
    term: "*",
    filters: {
      user_id: currentUser?.id,
    },
  });

  const {
    data: reimbursements,
    isLoading: isLoadingReimbursements,
    error: reimbursementError,
  } = useSearchReimbursements({
    term: "*",
    filters: {
      user_id: currentUser?.id,
    },
  });

  const requestData = [
    ...(serviceRequests?.results || []),
    ...(purchaseRequests?.results || []),
    ...(reimbursements?.results || []),
  ].filter((ele) => filterCompletedAndClosedRequest(ele));
  const sortedData = _.orderBy([...requestData], ["created_at"], ["desc"]);

  if (isLoadingRequests || isLoadingPurchases || isLoadingReimbursements) {
    return <Text>Loading...</Text>;
  }

  if (userError || requestError || purchaseError || reimbursementError) {
    return <Text color="red.500">Failed to load data. Please try again later.</Text>;
  }

  return (
    <Box
      borderRadius="md"
      borderWidth="1px"
      borderColor={useColorModeValue("gray.100", "gray.700")}
      p={6}
      width="100%">
      <HStack w="100%" justify={"space-between"} mb="4">
        <Heading size="sm" fontWeight="semibold">
          In Progress{" "}
          <Tag ml={2} size="sm" colorScheme="teal" display={["inline", "inline", "none", "inline"]}>
            {requestData.length}
          </Tag>
        </Heading>
        <Button as="a" href="/services/all" variant="ghost" colorScheme="teal" size="sm">
          View All
        </Button>
      </HStack>
      <VStack width="100%" align="stretch" maxH="400px" overflow="auto" gap={0}>
        {sortedData.length ? (
          sortedData.map((item) => <RequestItem item={item} />)
        ) : (
          <EmptyState title="No open requests" />
        )}
      </VStack>
    </Box>
  );
};

const closedStatuses = ["closed", "declined", "completed", "complete", "delivered", "abandoned"];

const filterCompletedAndClosedRequest = (
  item: PurchaseIndexItemData | ServiceRequestIndexItemData | ReimbursementIndexItemData
) => {
  const status = "approval_status" in item ? item.approval_status : item.status;
  const lastStatusChange = item.last_status_change;
  const oneWeekAgo = DateTime.now().minus({ weeks: 1 });

  // Include all open items or recently closed items (within the last week)
  if (!closedStatuses.includes(status)) {
    return true; // Include open items
  }
  // Include closed items only if they were closed within the last week
  return lastStatusChange && lastStatusChange >= oneWeekAgo;
};

type RequestItemProps = {
  item: PurchaseIndexItemData | ServiceRequestIndexItemData | ReimbursementIndexItemData;
};
const RequestItem = ({ item }: RequestItemProps) => {
  const status = "approval_status" in item ? item.approval_status : item.status;
  const hoverBackgroundColor = useColorModeValue("gray.100", "gray.600");

  return (
    <LinkBox
      key={`${item.model_type}-${item.id}`}
      _hover={{
        bg: hoverBackgroundColor,
        borderRadius: "md",
      }}
      ml={-6}
      pl={6}>
      <LinkOverlay as={RouterLink} to={`${item.app_href}`}>
        <Flex
          direction={["column", "column", "column", "row"]}
          justify="space-between"
          align={["start", "start", "start", "center"]}
          justifyItems={"center"}
          w="100%"
          p={2}
          borderBottom="1px"
          borderColor="chakra-border-color">
          <VStack align="start" gap={0}>
            <Text fontWeight="semibold">
              {item.model_type} #{item.id}
            </Text>
            <Text fontSize={"sm"} textColor={useColorModeValue("gray.600", "auto")}>
              Created {item.created_at.toFormat("MMM d, yyyy")}
            </Text>
          </VStack>
          <Flex direction={["column", "column", "column", "row"]} gap={2}>
            <Text fontSize={"sm"} textColor={useColorModeValue("gray.600", "auto")} w="max-content">
              Status Updated {item.last_status_change?.toFormat("MMM d, yyyy")}
            </Text>
            <HStack>
              <Tag w="max-content" size="sm" colorScheme={getStatusColor(status, item.model_type)}>
                {titleize(humanize(status))}
              </Tag>
              <ChevronRightIcon />
            </HStack>
          </Flex>
        </Flex>
      </LinkOverlay>
    </LinkBox>
  );
};

const getStatusColor = (status: string, type: string) => {
  if (type === "Reimbursement") {
    return reimbursementStatusColor(status);
  }
  if (type === "Purchase") {
    return purchaseRequestStatusColor(status);
  }
  if (type === "ServiceRequest") {
    switch (status) {
      case "abandoned":
      case "needs approval":
        return "red";
      case "complete":
        return "green";
      case "in progress":
      case "review":
        return "orange";
      case "new":
        return "purple";
    }
  }
  return "gray";
};
