import { z } from "zod";
import { api } from ".";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SearchParams, indexParams, searchParams } from "./collection_types";
import { createSearchResponseSchema } from "./shared";
import { zodParse } from "./zodParse";
import { Money, moneySchema } from "../helpers/Money";
import {
  BudgetGroupScheduleCreateParams,
  budgetGroupScheduleSchema,
} from "./budget_group_schedules";
import { budgetItemSpendByModelSchema, budgetItemSummarySchema } from "./budget_items";
import { budgetGroupApproverShowSchema } from "./budget_group_approvers";
import { DateTime } from "luxon";
import _ from "lodash";
import { TimelineEventData, timelineEventSchema } from "./timeline_events";
import { BUDGETS_BASE_URL, committedSpendBreakdownSchema } from "./budget";
import { dateTimeSchema } from "../helpers/dateTime";

export const BUDGET_GROUPS_BASE_URL = "budget_groups";

export const budgetGroupSchema = z.object({
  id: z.number(),
  name: z.string(),
  purchasing_approval_threshold: moneySchema,
  autonomous_spending: z.boolean(),
  archived_at: dateTimeSchema.nullable(),
});

export const budgetGroupIndexSchema = budgetGroupSchema
  .omit({
    purchasing_approval_threshold: true,
    autonomous_spending: true,
  })
  .extend({
    budget_count: z.number(),
    budget_group_schedule: z.lazy(() => budgetGroupScheduleSchema).nullable(),
    lead: z.lazy(() => budgetGroupApproverShowSchema),
    summary: z.lazy(() => budgetItemSummarySchema),
  });

export const timeRangeSchema = z.object({
  start_date: dateTimeSchema,
  end_date: dateTimeSchema,
  id: z.number(),
});

export const budgetGroupShowSchema = budgetGroupSchema.extend({
  currency: z.string(),
  lead: z.lazy(() => budgetGroupApproverShowSchema),
  approvers: z.array(z.lazy(() => budgetGroupApproverShowSchema)),
  budget_group_schedule: z.lazy(() => budgetGroupScheduleSchema).nullable(),
  summary: z.lazy(() => budgetItemSummarySchema),
  can_be_archived: z.boolean(),
  time_range_options: z.array(timeRangeSchema),
});
export const monthlySpendSchema = z.record(z.string(), moneySchema);

export const minimalBudgetGroupSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    app_href: z.string(),
  })
);

export const highestSpendAndCommittedGroupsSchema = z.object({
  spent: z.array(z.object({ name: z.string(), amount: moneySchema })),
  committed: z.array(z.object({ name: z.string(), amount: moneySchema })),
});

export const companyWideSummarySchema = z.object({
  spend_summary: z.lazy(() => budgetItemSummarySchema),
  expenditure_spend_summary: z.lazy(() => budgetItemSpendByModelSchema),
  monthly_spend: monthlySpendSchema,
  highest_spend_and_committed_records: highestSpendAndCommittedGroupsSchema,
});

export const budgetGroupSummaryStats = z.object({
  expenditure_spend_summary: z.lazy(() => budgetItemSpendByModelSchema),
  highest_spend_and_committed_records: highestSpendAndCommittedGroupsSchema,
});

export type MonthlySpendData = z.infer<typeof monthlySpendSchema>;

export type BudgetGroupData = z.infer<typeof budgetGroupSchema>;
export type BudgetGroupIndexData = z.infer<typeof budgetGroupIndexSchema>;
export type HighestSpendAndCommittedRecordData = z.infer<
  typeof highestSpendAndCommittedGroupsSchema
>;
export type BudgetGroupShowData = z.infer<typeof budgetGroupShowSchema>;
export type BudgetGroupCreateParams = Pick<BudgetGroupData, "name"> & {
  budget_group_schedule: Omit<BudgetGroupScheduleCreateParams, "budget_group_id">;
  budget_group_approver: number;
};

export const getBudgetGroups = async () => {
  const result = await api.get(BUDGET_GROUPS_BASE_URL);
  return zodParse(budgetGroupIndexSchema.array(), result.data);
};

export const getBudgetGroup = async (id: number | null | undefined) => {
  const result = await api.get(`${BUDGET_GROUPS_BASE_URL}/${id}`);
  return zodParse(budgetGroupShowSchema, result.data);
};

export const getBudgetGroupOptions = async () => {
  const result = await api.get(`${BUDGET_GROUPS_BASE_URL}/options`);
  return zodParse(minimalBudgetGroupSchema, result.data);
};

export const getBudgetGroupSummaryStats = async (
  id: number | null | undefined,
  budget_group_schedule_id: number | null | undefined
) => {
  const result = await api.get(`${BUDGET_GROUPS_BASE_URL}/${id}/summary_stats`, {
    params: _.omitBy({ budget_group_schedule_id }, _.isUndefined),
  });
  return zodParse(budgetGroupSummaryStats, result.data);
};

export const getBudgetGroupSummary = async (
  id: number | null | undefined,
  budget_group_schedule_id: number | null | undefined
) => {
  const result = await api.get(`${BUDGET_GROUPS_BASE_URL}/${id}/summary`, {
    params: _.omitBy({ budget_group_schedule_id }, _.isUndefined),
  });
  return zodParse(budgetItemSummarySchema, result.data);
};

export const getBudgetGroupMonthlySpend = async (
  id: number | null | undefined,
  budget_group_schedule_id: number | null | undefined
) => {
  const result = await api.get(`${BUDGET_GROUPS_BASE_URL}/${id}/monthly_spend`, {
    params: _.omitBy({ budget_group_schedule_id }, _.isUndefined),
  });
  return zodParse(monthlySpendSchema, result.data);
};

export const getBudgetGroupCommittedSpendBreakdownByExpenditure = async (id: number) => {
  const result = await api.post(`${BUDGET_GROUPS_BASE_URL}/${id}/committed_spend_breakdown`);
  return zodParse(committedSpendBreakdownSchema.array(), result.data);
};

export const getBudgetGroupEvents = async (id: number): Promise<TimelineEventData[]> => {
  const result = await api.get(`${BUDGET_GROUPS_BASE_URL}/${id}/events`);
  return zodParse(timelineEventSchema.array(), result.data);
};

export const getAllBudgetGroupsSummary = async (endDate: DateTime) => {
  const result = await api.get(`${BUDGET_GROUPS_BASE_URL}/summary`, {
    params: _.omitBy({ end_date: endDate.toISO() }, _.isUndefined),
  });
  return zodParse(companyWideSummarySchema, result.data);
};

export const requestFundingBudgetGroup = async ({
  id,
  message,
  amount,
}: {
  id: number;
  message: string;
  amount: Money;
}): Promise<void> => {
  await api.post(`${BUDGET_GROUPS_BASE_URL}/${id}/request`, {
    message,
    amount,
  });
};

export const addFundingBudgetGroup = async ({
  id,
  message,
  amount,
  occurred_at,
}: {
  id: number;
  message: string;
  amount: Money;
  occurred_at: DateTime;
}): Promise<void> => {
  await api.post(`${BUDGET_GROUPS_BASE_URL}/${id}/fund`, {
    message,
    amount,
    occurred_at: occurred_at.toISO(),
  });
};

export const archiveBudgetGroup = async (id: number): Promise<void> => {
  await api.post(`${BUDGET_GROUPS_BASE_URL}/${id}/archive`);
};

export const searchBudgetGroups = async ({
  aggs,
  filters,
  pagination,
  order,
  term,
}: SearchParams) => {
  const path = [BUDGET_GROUPS_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });

  return zodParse(createSearchResponseSchema(budgetGroupIndexSchema), result.data);
};

export const createBudgetGroup = async (budget_group: BudgetGroupCreateParams) => {
  try {
    const result = await api.post(`${BUDGET_GROUPS_BASE_URL}`, {
      budget_group,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateBudgetGroup = async (budget_group: Partial<BudgetGroupData>) => {
  const result = await api.put(`${BUDGET_GROUPS_BASE_URL}/${budget_group.id}`, {
    budget_group,
  });
  return result.data;
};

export const deleteBudgetGroup = async (id: number) => {
  await api.delete(`${BUDGET_GROUPS_BASE_URL}/${id}`);
};

export const invalidateBudgetGroup = (id?: number | null, subQuery?: string) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [BUDGET_GROUPS_BASE_URL];
  if (id) queryKey.push(id);
  if (subQuery) queryKey.push(subQuery);
  return () => {
    queryClient.invalidateQueries({ queryKey });
    queryClient.invalidateQueries([BUDGETS_BASE_URL]);
  };
};

export const useGetBudgetGroups = () => {
  return useQuery({
    queryKey: [BUDGET_GROUPS_BASE_URL],
    queryFn: () => getBudgetGroups(),
  });
};

export const useGetBudgetGroup = (id: number | undefined | null) => {
  return useQuery({
    queryKey: [BUDGET_GROUPS_BASE_URL, id],
    queryFn: () => getBudgetGroup(id),
    enabled: !!id,
  });
};

export const useGetBudgetGroupOptions = () => {
  return useQuery({
    queryKey: [BUDGET_GROUPS_BASE_URL, "options"],
    queryFn: () => getBudgetGroupOptions(),
  });
};

export const useGetAllBudgetGroupsSummary = (end_date: DateTime) => {
  return useQuery({
    queryKey: [BUDGET_GROUPS_BASE_URL, "summary"],
    queryFn: () => getAllBudgetGroupsSummary(end_date),
  });
};

export const useGetBudgetGroupSummaryStats = (
  id: number | null | undefined,
  budget_group_schedule_id?: number | null | undefined
) => {
  return useQuery({
    queryKey: [BUDGET_GROUPS_BASE_URL, id, "summary_stats", budget_group_schedule_id],
    queryFn: () => getBudgetGroupSummaryStats(id, budget_group_schedule_id),
    enabled: !!id && !!budget_group_schedule_id,
  });
};

export const useGetBudgetGroupSummary = (
  id: number | null | undefined,
  budget_group_schedule_id?: number | null | undefined
) => {
  return useQuery({
    queryKey: [BUDGET_GROUPS_BASE_URL, id, "summary", budget_group_schedule_id],
    queryFn: () => getBudgetGroupSummary(id, budget_group_schedule_id),
    enabled: !!id && !!budget_group_schedule_id,
  });
};

export const useGetBudgetGroupMonthlySpend = (
  id: number | null | undefined,
  budget_group_schedule_id?: number | null | undefined
) => {
  return useQuery({
    queryKey: [BUDGET_GROUPS_BASE_URL, id, "monthly_spend", budget_group_schedule_id],
    queryFn: () => getBudgetGroupMonthlySpend(id, budget_group_schedule_id),
    enabled: !!id && !!budget_group_schedule_id,
  });
};

export const useGetBudgetGroupCommittedSpendBreakdownByExpenditure = (id: number) => {
  return useQuery({
    queryKey: [BUDGET_GROUPS_BASE_URL, id, "committed_spend_breakdown"],
    queryFn: () => getBudgetGroupCommittedSpendBreakdownByExpenditure(id),
    enabled: !!id,
  });
};

export const useGetBudgetGroupEvents = (id: number | null | undefined) => {
  return useQuery({
    queryKey: [BUDGET_GROUPS_BASE_URL, id, "events"],
    queryFn: () => getBudgetGroupEvents(id!),
    enabled: !!id,
  });
};

export const useArchiveBudgetGroup = () => {
  return useMutation({
    mutationFn: archiveBudgetGroup,
    onSuccess: invalidateBudgetGroup(),
  });
};

export const useAddFundingBudgetGroup = () => {
  return useMutation({
    mutationFn: addFundingBudgetGroup,
    onSuccess: invalidateBudgetGroup(),
  });
};

export const useRequestFundingBudgetGroup = () => {
  return useMutation({
    mutationFn: requestFundingBudgetGroup,
    onSuccess: invalidateBudgetGroup(),
  });
};

export const useSearchBudgetGroups = (params: SearchParams) => {
  return useQuery({
    queryKey: [BUDGET_GROUPS_BASE_URL, params],
    queryFn: () => searchBudgetGroups(params),
  });
};

export const useCreateBudgetGroup = () => {
  return useMutation({
    mutationFn: createBudgetGroup,
    onSuccess: invalidateBudgetGroup(),
  });
};

export const useUpdateBudgetGroup = (id?: number | null) => {
  return useMutation({
    mutationFn: updateBudgetGroup,
    onSuccess: invalidateBudgetGroup(id),
  });
};
export const useDeleteBudgetGroup = () => {
  return useMutation({
    mutationFn: deleteBudgetGroup,
    onSuccess: invalidateBudgetGroup(),
  });
};
