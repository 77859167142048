import { MeetingNoteDataWithAttendees } from "../../../api/meeting_notes";
import {
  Box,
  Icon,
  Text,
  useColorModeValue,
  HStack,
  Flex,
  Avatar,
  LinkBox,
  Stack,
} from "@chakra-ui/react";
import { RichTextEditor } from "@sciencecorp/helix-components";
import React from "react";
import { CiCalendar } from "react-icons/ci";
import { useGradient } from "../../MeetingNotes/MeetingItemInfo";
import { Link, Link as RouterLink } from "react-router-dom";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export const ProjectMeetingNoteCard = ({
  meetingNote,
}: {
  meetingNote: MeetingNoteDataWithAttendees;
}) => {
  const iconBgColor = useColorModeValue("gray.100", "gray.600");
  const bgColor2 = useColorModeValue("gray.50", "gray.600");
  const avatarGroupSize = 7;
  const gradient = useGradient("50", "700");

  return (
    <Stack position="relative" mb={3} alignSelf="start" width="100%">
      <HStack gap={1} w="100%">
        <Box
          position="absolute"
          height="100%"
          bg={iconBgColor}
          width={0.5}
          left={3.5}
          top={5}
          zIndex={-1}
        />
        <Box
          borderRadius="full"
          bg={iconBgColor}
          boxSize={10}
          display="flex"
          mr={2}
          alignSelf="start"
          justifyContent="center"
          alignItems="center">
          <Icon as={CiCalendar} />
        </Box>

        <Box width="100%" mb={6}>
          <HStack
            alignItems={"center"}
            bg={bgColor2}
            p={2}
            borderTopRadius="lg"
            borderBottomRadius={"none"}
            border="1px"
            justifyContent={"space-between"}
            borderColor="chakra-border-color"
            borderBottom={"0px"}>
            <Text>
              {meetingNote.title} - {meetingNote.created_at.toLocaleString()}
            </Text>
            <Link to={`/meeting_notes/${meetingNote.id}`} target="_blank" rel="noopener noreferrer">
              <ExternalLinkIcon />
            </Link>
          </HStack>
          {meetingNote.content ? (
            <Flex
              direction="column"
              p={2}
              borderBottomRadius="lg"
              border="1px"
              justifyContent="space-between"
              borderColor="chakra-border-color">
              <RichTextEditor
                autoSaveTimeout={1000}
                editable={false}
                width="100%"
                onSave={() => {}}
                minHeight="5vh"
                defaultValue={JSON.stringify(meetingNote.content)}
                isSaving={false}
              />
              <HStack>
                <Flex width="100%" position="relative" zIndex={0}>
                  {meetingNote.meeting_attendees.slice(0, avatarGroupSize).map((attendee) => (
                    <LinkBox
                      marginInlineEnd={-2}
                      key={attendee.id}
                      marginTop={2}
                      marginBottom={2}
                      as={RouterLink}
                      to={`/users/${attendee.id}`}>
                      <Avatar
                        name={attendee.user.name}
                        src={attendee.user.picture_uri}
                        boxSize={"8"}
                      />
                    </LinkBox>
                  ))}
                  {meetingNote.meeting_attendees.length > avatarGroupSize && (
                    <Flex
                      zIndex={1}
                      width="min-content"
                      height="100%"
                      align="center"
                      right={10}
                      background={gradient}
                      lineHeight="shorter"
                      pointerEvents="none"
                      marginInlineEnd={8}
                      position="absolute">
                      <Text pointerEvents="all" fontSize="sm" textAlign="end">
                        +{meetingNote.meeting_attendees.length - avatarGroupSize} more
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </HStack>
            </Flex>
          ) : (
            <>There is no content</>
          )}
        </Box>
      </HStack>
    </Stack>
  );
};
