import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Stack,
  VStack,
  Text,
  HStack,
  useColorModeValue,
  Divider,
  Icon,
  useDisclosure,
  Textarea,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Badge,
  useToast,
  Spinner,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import {
  ConfirmationButton,
  ConfirmationModal,
  Header,
  RecordLink,
} from "@sciencecorp/helix-components";
import { AddIcon, CheckIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { IoMedalOutline } from "react-icons/io5";
import { FiArchive } from "react-icons/fi";
import { RiCheckFill, RiCloseFill, RiEdit2Line } from "react-icons/ri";
import {
  useGetTraining,
  useUpdateTraining,
  useGetNextTrainingSession,
  useDeleteTraining,
} from "../../../api/trainings";
import { TrainingMaterialShowData } from "../../../api/training_materials";

import { useCurrentUserQuery, userHasRole } from "../../../api/user";
import { AllTrainingSessionTable } from "./components/AllTrainingSessionsTable";
import { NewMaterial } from "./components/NewMaterial";
import { NewTrainingModal } from "./components/NewTrainingModal";
import { NewTrainingSession } from "./components/NewTrainingSession";
import { TrainingMaterials } from "./components/TrainingMaterials";
import { RSVPModal } from "./components/RSVPModal";
import {
  AttendanceHistoryTable,
  FacilitatorHistoryTable,
} from "./components/TrainingSessionTables";
import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";
import { useSearchTrainings } from "../../../api/trainings";
import { attendanceHistoryColorScheme } from "../util";
import { TrainingScheduleWidget } from "./ScheduleWidget";
import { DateTime } from "luxon";
import { useNavigate, useParams } from "react-router";
import { CiUndo } from "react-icons/ci";
import { BsThreeDotsVertical } from "react-icons/bs";
import { PopoverList, popoverStatusMap } from "../../shared/PopoverList";

export const TrainingPage = () => {
  const { id } = useParams();

  const { search, debouncedSearch } = useDebouncedSearch();
  const { data: training, isError, isLoading } = useGetTraining(Number(id));
  const { data: nextSession } = useGetNextTrainingSession(Number(id));
  const { mutate: updateTraining } = useUpdateTraining();
  const { mutate: deleteTraining } = useDeleteTraining();
  const toast = useToast();

  const { data: trainingSearch } = useSearchTrainings({
    term: search || "*",
    pagination: { per_page: 10 },
    filters: { is_archived: false },
  });
  const currentUser = useCurrentUserQuery();
  const isCredentialsAdmin = userHasRole(currentUser, "credentials_admin") || false;
  const [editDescription, setEditDescription] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const navigate = useNavigate();

  const {
    isOpen: isOpenNewTraining,
    onOpen: onOpenNewTraining,
    onClose: onCloseNewTraining,
  } = useDisclosure();

  const {
    isOpen: isOpenNewSession,
    onOpen: onOpenNewSession,
    onClose: onCloseNewSession,
  } = useDisclosure();

  const {
    isOpen: isOpenMaterial,
    onOpen: onOpenMaterial,
    onClose: onCloseMaterial,
  } = useDisclosure();

  const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();

  const { isOpen: isOpenArchive, onOpen: onOpenArchive, onClose: onCloseArchive } = useDisclosure();

  const { isOpen: isOpenRSVP, onOpen: onOpenRSVP, onClose: onCloseRSVP } = useDisclosure();

  const [activeMaterial, setActiveMaterial] = useState<TrainingMaterialShowData | null>(null);

  if (isLoading) return <Spinner />;
  if (isError) return <Text>Failed to load training</Text>;

  const actions = isCredentialsAdmin
    ? !training.is_archived
      ? ([
          training.is_draft && (
            <ConfirmationButton
              key="publish"
              label="Publish"
              rightIcon={<CheckIcon />}
              variant="Button"
              confirmationButtonLabel="Yes"
              colorScheme="teal"
              children="Publishing this training will make it active and visible to all employees. Are you sure you want to proceed?"
              confirmationHeader="Publish Training"
              onConfirm={() => updateTraining({ id: training.id, is_draft: false })}
            />
          ),
          <PopoverList
            key="popover"
            items={
              trainingSearch?.results
                .filter((item) => !item.is_archived)
                .map((item) => ({
                  id: item.id,
                  name: item.name,
                  url: `/trainings/${item.id}`,
                  status: item.status,
                })) || []
            }
            selectedItemId={training.id}
            debouncedSearch={debouncedSearch}
            title="Other Trainings"
          />,
          <Menu key="menu">
            <MenuButton
              as={IconButton}
              icon={<BsThreeDotsVertical />}
              size="md"
              aria-label="More actions"
            />
            <MenuList>
              <MenuItem icon={<EditIcon />} onClick={() => onOpenNewTraining()}>
                Edit
              </MenuItem>
              {training.can_be_deleted ? (
                <MenuItem
                  icon={<DeleteIcon />}
                  gap={3}
                  color="red"
                  fontWeight="medium"
                  onClick={onOpenDelete}>
                  Delete Training
                </MenuItem>
              ) : (
                <MenuItem
                  icon={<FiArchive />}
                  onClick={onOpenArchive}
                  color="red"
                  fontWeight="medium">
                  Archive Training
                </MenuItem>
              )}
            </MenuList>
          </Menu>,
        ].filter(Boolean) as JSX.Element[])
      : [
          <PopoverList
            key="popover"
            items={
              trainingSearch?.results
                .filter((item) => !item.is_archived)
                .map((item) => ({
                  id: item.id,
                  name: item.name,
                  url: `/trainings/${item.id}`,
                  status: item.status,
                })) || []
            }
            selectedItemId={training.id}
            debouncedSearch={debouncedSearch}
            title="Other Trainings"
          />,
          <Menu key="menu">
            <MenuButton
              as={IconButton}
              icon={<BsThreeDotsVertical />}
              size="md"
              aria-label="More actions"
            />
            <MenuList>
              <MenuItem
                icon={<CiUndo />}
                gap={3}
                color="teal"
                fontWeight="medium"
                onClick={() => updateTraining({ id: training.id, is_archived: false })}>
                Restore Training
              </MenuItem>
            </MenuList>
          </Menu>,
        ]
    : [];

  const determineTrainingStatus = (training: any) => {
    if (!training) {
      return "not_assigned";
    }
    if (training.is_archived) {
      return "archived";
    }
    return training.user_training_session_status || "not_assigned";
  };

  return (
    <>
      {training?.is_archived && (
        <Alert status="warning" width="100%" mb={4} justifyContent="space-between">
          <HStack>
            <AlertIcon />
            <VStack align="start" spacing={0}>
              <AlertTitle>Archived Training</AlertTitle>
              <AlertDescription>
                Please restore this training to make it available to employees.
              </AlertDescription>
            </VStack>
          </HStack>
          <Button
            colorScheme="teal"
            onClick={() => updateTraining({ id: training.id, is_archived: false })}>
            Restore Training
          </Button>
        </Alert>
      )}

      <VStack align="start" width="100%" spacing={5}>
        <Flex direction="column" width="100%">
          <Header
            title={training?.name ?? ""}
            badge={{
              label: popoverStatusMap(determineTrainingStatus(training)).label,
              colorScheme: popoverStatusMap(determineTrainingStatus(training)).color,
            }}
            actions={actions}
            crumbsColor="teal"
            crumbs={[{ url: `/credentials/trainings`, label: "Trainings" }]}
          />
        </Flex>
        <VStack align="start" spacing={6} width="100%">
          <TrainingScheduleWidget training={training} onOpenRSVP={onOpenRSVP} />
          <VStack align="start" spacing={1}>
            <HStack>
              <Heading size="md">Description</Heading>
              {isCredentialsAdmin &&
                (!editDescription ? (
                  <IconButton
                    size="xs"
                    aria-label="edit description"
                    icon={<RiEdit2Line />}
                    onClick={() => setEditDescription(true)}
                  />
                ) : (
                  <Flex gap={1}>
                    <IconButton
                      size="xs"
                      aria-label="Save Edits"
                      icon={<RiCheckFill />}
                      onClick={() =>
                        updateTraining(
                          { id: training.id, description: description },
                          { onSuccess: () => setEditDescription(false) }
                        )
                      }
                    />
                    <IconButton
                      size="xs"
                      aria-label="Cancel Edits"
                      icon={<RiCloseFill />}
                      onClick={() => setEditDescription(false)}
                    />
                  </Flex>
                ))}
            </HStack>
            {!editDescription ? (
              training?.description ? (
                <Text fontSize="sm">{training?.description}</Text>
              ) : (
                <Text as="i" fontSize="sm">
                  No description yet
                </Text>
              )
            ) : (
              <Textarea
                width="100%"
                defaultValue={training?.description}
                onChange={(e) => setDescription(e.target.value)}
              />
            )}
          </VStack>
        </VStack>
        <Stack width="100%" direction={{ base: "column", lg: "row" }}>
          {training && (
            <TrainingMaterials
              trainingId={training.id}
              editable={isCredentialsAdmin || training.is_facilitator}
              onOpen={onOpenMaterial}
              setActiveMaterial={setActiveMaterial}
            />
          )}
          <VStack
            p={5}
            spacing={4}
            border="1px"
            borderColor="chakra-border-color"
            borderRadius="md"
            align="start"
            width={{ base: "100%", lg: "50%" }}>
            <HStack>
              <Box
                bg={useColorModeValue("green.100", "green.700")}
                p={2}
                borderRadius="md"
                display="flex">
                <Icon as={IoMedalOutline} color={useColorModeValue("green.700", "green.400")} />
              </Box>
              <Heading size="md">Award </Heading>
            </HStack>
            <Divider />
            {training?.credential_trainings.length && (
              <Flex width="100%" align="start" gap={4} flexWrap="wrap">
                {isCredentialsAdmin
                  ? training?.credential_trainings.map((credentialTraining) => (
                      <RecordLink
                        type=""
                        identifier={credentialTraining.credential.name}
                        link={`/credentials/all/${credentialTraining.credential.id}`}
                        icon={
                          <Box ml={3} mt={1}>
                            <Icon as={IoMedalOutline} boxSize={4} />
                          </Box>
                        }
                      />
                    ))
                  : training?.credential_trainings.map((credentialTraining) => (
                      <Badge size="lg">
                        <HStack p={1}>
                          <Icon as={IoMedalOutline} />
                          <Text textTransform="capitalize">
                            {credentialTraining.credential.name}
                          </Text>
                        </HStack>
                      </Badge>
                    ))}
              </Flex>
            )}
          </VStack>
        </Stack>
        <VStack width="100%" align="start" spacing={2}>
          <Heading size="md">Attendance History</Heading>
          <Box width="100%">
            <AttendanceHistoryTable trainingId={training.id} />
          </Box>
        </VStack>
        {training?.is_facilitator && (
          <VStack width="100%" align="start" spacing={2}>
            <Heading size="md">Training Sessions You Facilitated</Heading>
            <Box width="100%">
              <FacilitatorHistoryTable trainingId={training.id} />
            </Box>
          </VStack>
        )}
        {isCredentialsAdmin && (
          <VStack width="100%" align="start" spacing={2}>
            <HStack justify="space-between" width="100%">
              <Heading size="md">Training Sessions</Heading>
              <Tooltip
                label={
                  training?.is_archived
                    ? "Restore this training to create a new session."
                    : training?.is_draft
                    ? "Publish this training to create a new session."
                    : ""
                }>
                <Button
                  leftIcon={<AddIcon />}
                  onClick={onOpenNewSession}
                  isDisabled={training?.is_archived || training?.is_draft}>
                  New Session
                </Button>
              </Tooltip>
              <NewTrainingSession
                trainingId={training.id}
                isOpen={isOpenNewSession}
                onClose={onCloseNewSession}
              />
            </HStack>
            <Box width="100%">
              <AllTrainingSessionTable id={training.id} />
            </Box>
          </VStack>
        )}
        {activeMaterial && (
          <NewMaterial
            isOpen={isOpenMaterial}
            onClose={onCloseMaterial}
            activeMaterial={activeMaterial}
          />
        )}
        <NewTrainingModal
          isOpen={isOpenNewTraining}
          onClose={onCloseNewTraining}
          training={training}
        />
        {nextSession?.training_session && training && currentUser?.data && (
          <RSVPModal
            isOpen={isOpenRSVP}
            onClose={onCloseRSVP}
            training={training}
            nextSession={nextSession}
            currentUser={currentUser.data}
          />
        )}
        <ConfirmationModal
          isOpen={isOpenArchive}
          colorScheme="red"
          onClose={onCloseArchive}
          onClick={() => {
            updateTraining({ id: training.id, is_archived: true });
          }}
          header="Archive Training"
          confirmText="Archive"
          children="Archiving this training will send it to the training archive."
        />
        <ConfirmationModal
          isOpen={isOpenDelete}
          colorScheme="red"
          onClose={onCloseDelete}
          confirmText="Yes"
          onClick={() => {
            deleteTraining(training.id, {
              onSuccess: () => {
                toast({
                  title: "Training deleted successfully",
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
                navigate("/credentials/trainings");
              },
            });
          }}
          header="Delete Training"
          children="Are you sure you want to delete this training? This cannot be undone."
        />
      </VStack>
    </>
  );
};
