import { AddIcon, SettingsIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Switch,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  AttributesTable,
  BadgeSelect,
  EditableSelect,
  EditableText,
  Header,
} from "@sciencecorp/helix-components";
import { DateTime } from "luxon";
import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { LiaPiggyBankSolid } from "react-icons/lia";
import { useNavigate, useParams } from "react-router";
import { useCurrentUserQuery, userHasRole } from "../../../api/user";
import {
  invalidateVendors,
  useDeleteVendor,
  useGetVendor,
  useSearchVendors,
  useUpdateVendor,
} from "../../../api/vendor";
import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";
import { EmptyState } from "../../shared/EmptyState";
import { NewVendorContactModal } from "./components/NewVendorContactModal";
import { NewVendorModal } from "./components/NewVendorModal";
import { VendorContactsTable } from "./components/VendorContactsTable";
import { VendorTransactionsTable } from "./components/VendorTransactionsTable";
import { PopoverList } from "../../shared/PopoverList";
import { DeleteableFileDownload } from "../../shared/DeleteableFileDownload";
import { useDeleteFile } from "../../../api/blob_files";
import { BlobUploadButton } from "../../shared/BlobUploadButton";
import { MergeVendorModal } from "./components/MergeVendorModal";
import { PaymentsSpendingGraph } from "./components/PaymentsSpendingGraph";
import { VendorDetails } from "./components/VendorDetails";

export const Vendor = (): JSX.Element => {
  const { id } = useParams();

  const navigate = useNavigate();
  const vendorQuery = useGetVendor(Number(id));

  const { mutate: deleteVendor } = useDeleteVendor();
  const { mutate: updateVendor, isLoading: isMutateLoading } = useUpdateVendor();

  const { search, debouncedSearch } = useDebouncedSearch();
  const currentUserQuery = useCurrentUserQuery();
  const isPurchasingAdmin = userHasRole(currentUserQuery, "purchasing_admin") || false;

  const { data } = useSearchVendors({
    term: search || "*",
    pagination: { per_page: 100 },
  });

  const {
    isOpen: isOpenNewContact,
    onOpen: onOpenNewContact,
    onClose: onCloseNewContact,
  } = useDisclosure();

  const {
    isOpen: isOpenMergeVendor,
    onOpen: onOpenMergeVendor,
    onClose: onCloseMergeVendor,
  } = useDisclosure();

  const { mutate: deleteFile } = useDeleteFile(() => invalidateVendors(Number(id)));
  const menuButtonColor = useColorModeValue("gray.100", "gray.600");

  if (vendorQuery.isLoading) return <Spinner />;
  if (vendorQuery.isSuccess) {
    const vendor = vendorQuery.data;
    const menuActions = (
      <>
        {!vendor.can_be_deleted && <MenuItem onClick={onOpenMergeVendor}>Merge Vendor</MenuItem>}
        {vendor.archived_at && (
          <MenuItem onClick={() => updateVendor({ id: vendor.id, archived_at: null })}>
            Restore
          </MenuItem>
        )}
        {vendor.can_be_deleted ? (
          <MenuItem
            onClick={() => {
              deleteVendor(vendor.id);
              navigate("/services/vendors");
            }}>
            Delete Vendor
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              updateVendor({ id: vendor.id, archived_at: DateTime.now().toISO() });
            }}>
            Archive Vendor
          </MenuItem>
        )}
      </>
    );

    const isArchived = !!vendor.archived_at;

    return (
      <>
        {isArchived && (
          <Alert status="warning" mb={4}>
            <AlertIcon />
            <VStack align="start" spacing={0}>
              <AlertTitle>Archived</AlertTitle>
              <AlertDescription>This vendor has been archived.</AlertDescription>
            </VStack>
          </Alert>
        )}

        <VStack width="100%" align="start" spacing={8}>
          <Flex direction="column" width="100%" justify={"space-between"}>
            <Header
              title={vendor.name}
              crumbs={[{ label: "Vendors", url: "/services/vendors" }]}
              crumbsColor="teal.500"
              badgeDropdown={
                <BadgeSelect
                  options={[
                    { label: "Approved", value: "approved", colorScheme: "green" },
                    { label: "Pending", value: "pending", colorScheme: "yellow" },
                    { label: "Rejected", value: "rejected", colorScheme: "red" },
                  ]}
                  selectedOption={vendor.status}
                  handleOptionChange={(value) => updateVendor({ id: vendor.id, status: value })}
                />
              }
              actions={
                isPurchasingAdmin
                  ? [
                      <PopoverList
                        items={
                          data?.results.map((vendor) => ({
                            id: vendor.id,
                            name: vendor.name,
                            url: `/services/vendors/${vendor.id}`,
                            status: vendor.status,
                            is_archived: !!vendor.archived_at,
                          })) || []
                        }
                        selectedItemId={vendor.id}
                        debouncedSearch={debouncedSearch}
                        title="Other Vendors"
                      />,
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          icon={<SettingsIcon />}
                          backgroundColor={menuButtonColor}
                        />
                        <MenuList>
                          <NewVendorModal
                            vendor={vendor}
                            location="vendor"
                            buttonLabel="Edit Vendor"
                          />
                        </MenuList>
                      </Menu>,
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          icon={<BsThreeDotsVertical />}
                          backgroundColor={menuButtonColor}
                        />
                        <MenuList>{menuActions}</MenuList>
                      </Menu>,
                    ]
                  : []
              }
            />
            <VendorDetails vendor={vendor} isPurchasingAdmin={isPurchasingAdmin} />
          </Flex>

          <Flex
            direction={["column", "column", "row"]}
            justify="space-between"
            width={"100%"}
            gap={8}>
            <Box
              w={{ base: "100%", md: "50%" }}
              p={4}
              border="1px"
              borderColor="chakra-border-color"
              borderRadius="md">
              <AttributesTable
                title={"Vendor Details"}
                attributes={[
                  {
                    label: "Tax ID",
                    value: (
                      <EditableText
                        defaultValue={vendor.tax_id || ""}
                        onSubmit={(value) => {
                          value && updateVendor({ id: vendor.id, tax_id: value });
                        }}
                        disabled={!isPurchasingAdmin}
                      />
                    ),
                  },
                  {
                    label: "Supplier Category",
                    value: (
                      <EditableSelect
                        options={[
                          { label: "A", value: "A" },
                          { label: "B", value: "B" },
                          { label: "C", value: "C" },
                          { label: "X", value: "X" },
                        ]}
                        selectedValue={vendor.risk_category || null}
                        onSubmit={(value) => {
                          value && updateVendor({ id: vendor.id, risk_category: value.toString() });
                        }}
                        disabled={!isPurchasingAdmin}
                      />
                    ),
                  },
                  {
                    label: "Payment Method",
                    value: (
                      <EditableSelect
                        options={[
                          { label: "Purchase Request", value: "purchase_request" },
                          { label: "Purchase Order", value: "purchase_order" },
                          { label: "Other", value: "other" },
                        ]}
                        selectedValue={vendor.payment_method}
                        onSubmit={(value) => {
                          value &&
                            updateVendor({ id: vendor.id, payment_method: value.toString() });
                        }}
                        disabled={!isPurchasingAdmin}
                      />
                    ),
                  },
                  {
                    label: "Payment Terms",
                    value: (
                      <EditableSelect
                        options={[
                          { label: "Due on Receipt", value: "due_on_receipt" },
                          { label: "Net 10", value: "net_10" },
                          { label: "Net 15", value: "net_15" },
                          { label: "Net 30", value: "net_30" },
                          { label: "Net 60", value: "net_60" },
                          { label: "Net 90", value: "net_90" },
                          { label: "EOM", value: "end_of_month" },
                          { label: "COD", value: "cash_on_delivery" },
                          { label: "CIA", value: "cash_in_advance" },
                          { label: "Installments", value: "installments" },
                          { label: "Staged Payments", value: "staged_payments" },
                          { label: "Prepaid", value: "prepaid" },
                        ]}
                        selectedValue={vendor.payment_terms}
                        onSubmit={(value) => {
                          value && updateVendor({ id: vendor.id, payment_terms: value.toString() });
                        }}
                        disabled={!isPurchasingAdmin}
                      />
                    ),
                  },
                  {
                    label: "1099 Vendor",
                    value: (
                      <Switch
                        colorScheme="teal"
                        isChecked={vendor.is_1099}
                        onChange={(e) => updateVendor({ id: vendor.id, is_1099: e.target.checked })}
                        isDisabled={!isPurchasingAdmin}
                      />
                    ),
                  },
                  {
                    label: "W-9/W-8 Tax Documents",
                    value: (
                      <HStack flexWrap="wrap">
                        {vendor.uploaded_tax_documents.map((ele) => (
                          <DeleteableFileDownload
                            key={`${ele.id} - ${ele.filename}`}
                            file={ele}
                            deleteFile={deleteFile}
                          />
                        ))}
                        <BlobUploadButton
                          fileableColumn="uploaded_tax_documents"
                          recordId={vendor.id}
                          recordType="Vendor"
                          onSuccessCallback={() => invalidateVendors(Number(id))}
                        />
                      </HStack>
                    ),
                  },
                  {
                    label: "Infosec / Supply Questionaire Completion",
                    value: (
                      <HStack flexWrap="wrap">
                        {vendor.uploaded_supply_questionaires.map((ele) => (
                          <DeleteableFileDownload
                            key={`${ele.id} - ${ele.filename}`}
                            file={ele}
                            deleteFile={deleteFile}
                          />
                        ))}
                        <BlobUploadButton
                          fileableColumn="uploaded_supply_questionaires"
                          recordId={vendor.id}
                          recordType="Vendor"
                          onSuccessCallback={() => invalidateVendors(Number(id))}
                        />
                      </HStack>
                    ),
                  },
                  {
                    label: "ISO Certifications",
                    value: (
                      <HStack flexWrap="wrap">
                        {vendor.uploaded_iso_certificates.map((ele) => (
                          <DeleteableFileDownload
                            key={`${ele.id} - ${ele.filename}`}
                            file={ele}
                            deleteFile={deleteFile}
                          />
                        ))}
                        <BlobUploadButton
                          fileableColumn="uploaded_iso_certificates"
                          recordId={vendor.id}
                          recordType="Vendor"
                          onSuccessCallback={() => invalidateVendors(Number(id))}
                        />
                      </HStack>
                    ),
                  },
                ]}
              />
            </Box>

            <VStack
              align="start"
              w={{ base: "100%", md: "50%" }}
              p={5}
              borderRadius="md"
              spacing={4}
              border="1px"
              borderColor="chakra-border-color">
              <HStack>
                <Box
                  px={2}
                  py={1.5}
                  bg="green.100"
                  borderRadius="md"
                  justifyContent="center"
                  alignItems="center">
                  <Icon
                    as={LiaPiggyBankSolid}
                    color="green.700"
                    justifySelf={"center"}
                    alignSelf={"center"}
                  />
                </Box>
                <Heading size="md" fontWeight={"semibold"}>
                  Spending
                </Heading>
              </HStack>
              <Divider />
              <PaymentsSpendingGraph filters={{ vendor_id: vendor.id }} />
            </VStack>
          </Flex>
          <Divider />
          <VStack width="100%" align="start" spacing={6}>
            <HStack justify="space-between" width="100%">
              <Heading size="md">Transactions</Heading>
            </HStack>
            <VendorTransactionsTable vendor={vendor} />
          </VStack>
          <VStack width="100%" align="start" spacing={6}>
            <HStack justify="space-between" width="100%">
              <Heading size="md">Payment Methods</Heading>
            </HStack>
            <EmptyState title="No payment methods yet" size="3xs" />
            <VStack width="100%" align="start" spacing={6}>
              <HStack justify="space-between" width="100%">
                <Heading size="md">Contacts</Heading>
                <Button
                  leftIcon={<AddIcon />}
                  size={"sm"}
                  onClick={() => onOpenNewContact()}
                  isDisabled={!isPurchasingAdmin}>
                  Add New
                </Button>
              </HStack>
              <VendorContactsTable vendor={vendor} />
            </VStack>
          </VStack>
        </VStack>

        <NewVendorContactModal
          isOpen={isOpenNewContact}
          onClose={onCloseNewContact}
          vendor={vendor}
        />
        <MergeVendorModal vendor={vendor} isOpen={isOpenMergeVendor} onClose={onCloseMergeVendor} />
      </>
    );
  }
  return <Text>Vendor not found</Text>;
};
