import React, { useEffect, useState } from "react";
import { FormModal } from "@sciencecorp/helix-components";
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Box,
  Text,
  useColorModeValue,
  Stack,
  useToast,
} from "@chakra-ui/react";
import {
  CredentialData,
  useNewCredential,
  useGetCredentialNames,
  useUpdateCredential,
} from "../../../api/credentials";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import axios from "axios";

type FormValues = {
  name: string;
  is_public: string;
  training_location: string;
  expiration_months: number;
  has_training: string;
};

const defaultFormValues = {
  name: "",
  is_public: "public",
  has_training: "yes",
  training_location: "internal",
  expiration_months: 0,
};

type NewCredentialModalProps = {
  isOpen: boolean;
  onClose: () => void;
  credential?: CredentialData;
};

export const NewCredentialModal = ({ isOpen, onClose, credential }: NewCredentialModalProps) => {
  const [expires, setExpires] = useState<boolean>(credential?.expiration_months ? true : false);
  const { mutate: newCredential, isLoading: isLoadingNewCredential } = useNewCredential();
  const { mutate: updateCredential, isLoading: isLoadingUpdateCredential } = useUpdateCredential();
  const { data: credentialNames } = useGetCredentialNames();

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    if (credential) {
      reset({
        name: credential.name,
        is_public: credential.is_public ? "public" : "private",
        has_training: credential.has_training ? "yes" : "no",
        training_location: credential.training_location,
        expiration_months: credential.expiration_months || 0,
      });
      setExpires(credential.expiration_months ? true : false);
    }
  }, [credential]);

  const toast = useToast();

  const navigate = useNavigate();
  const formValues = watch();

  const allFieldsFilledIn = () => {
    return (
      formValues.name &&
      formValues.is_public &&
      formValues.has_training &&
      formValues.training_location &&
      (formValues.expiration_months || !expires)
    );
  };

  const onSubmit = (data: FormValues) => {
    const newCredentialData = {
      ...data,
      is_public: data.is_public === "public" ? true : false,
      has_training: data.has_training === "yes" ? true : false,
      expiration_months: expires ? data.expiration_months : null,
    };
    credential
      ? updateCredential(
          { id: credential.id, ...newCredentialData },
          {
            onSuccess: handleClose,
            onError: (e) => {
              toast({
                title: "Error",
                description:
                  axios.isAxiosError(e) && e.response
                    ? e.response.data.error.message
                    : "Error updating credential package",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
            },
          }
        )
      : newCredential(newCredentialData, {
          onSuccess: (data) => {
            handleClose();
            navigate(`/credentials/all/${data.id}`);
          },
          onError: (e) => {
            toast({
              title: "Error",
              description:
                axios.isAxiosError(e) && e.response
                  ? e.response.data.error.message
                  : "Error creating credential",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          },
        });
  };

  const handleClose = () => {
    reset();
    setExpires(false);
    onClose();
  };

  return (
    <FormModal
      title={credential ? "Edit Credential" : "New Credential"}
      submitButtonColorSchema="teal"
      closeOnOverlayClick={false}
      submitButtonTitle="Submit"
      size="3xl"
      isOpen={isOpen}
      submitButtonDisabled={!allFieldsFilledIn()}
      isLoading={isLoadingNewCredential || isLoadingUpdateCredential}
      onClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}>
      <VStack width="100%" align="start" spacing={5}>
        <Text textColor={useColorModeValue("gray.600", "gray.300")}>
          Add a new credential for science employees.
        </Text>
        <Stack width="100%" align="start" spacing={6} direction={{ base: "column", md: "row" }}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <FormControl isInvalid={errors.name ? true : false}>
                <FormLabel>Credential Name</FormLabel>
                <Input {...field} placeholder="Type Credential Name" />
                {errors.name && (
                  <Text color="red" mt={1} fontSize="sm">
                    This credential already exists.
                  </Text>
                )}
              </FormControl>
            )}
          />
          <Controller
            name="is_public"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Credential Visibility</FormLabel>
                <RadioGroup display="flex" colorScheme="teal" value={field.value} gap={6}>
                  <Radio {...field} value={"public"}>
                    Public
                  </Radio>
                  <Radio {...field} value={"private"}>
                    Private
                  </Radio>
                </RadioGroup>
              </FormControl>
            )}
          />
        </Stack>
        <Stack width="100%" align="start" spacing={6} direction={{ base: "column", md: "row" }}>
          <Controller
            name="has_training"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Is Credential Training-Associated?</FormLabel>
                <RadioGroup display="flex" colorScheme="teal" value={field.value} gap={6}>
                  <Radio {...field} value={"yes"}>
                    Yes
                  </Radio>
                  <Radio {...field} value={"no"}>
                    No
                  </Radio>
                </RadioGroup>
              </FormControl>
            )}
          />

          <Controller
            name="training_location"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Training Location</FormLabel>
                <RadioGroup display="flex" colorScheme="teal" value={field.value} gap={6}>
                  <Radio {...field} value={"internal"}>
                    At Science
                  </Radio>
                  <Radio {...field} value={"external"}>
                    External
                  </Radio>
                </RadioGroup>
              </FormControl>
            )}
          />
        </Stack>

        <Stack width="100%" align="start" spacing={6} direction={{ base: "column", md: "row" }}>
          <FormControl>
            <FormLabel>Does Credential Expire?</FormLabel>
            <RadioGroup
              colorScheme="teal"
              display="flex"
              gap={6}
              onChange={(value) => setExpires(value === "yes" ? true : false)}
              value={expires ? "yes" : "no"}>
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </RadioGroup>
          </FormControl>
          <Controller
            name="expiration_months"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel>
                  Validity Period{" "}
                  <Box
                    as="span"
                    fontWeight="normal"
                    color={useColorModeValue("gray.500", "gray.300")}>
                    (months)
                  </Box>
                </FormLabel>
                <Input {...field} isDisabled={!expires} type="number" />
              </FormControl>
            )}
          />
        </Stack>
      </VStack>
    </FormModal>
  );
};
