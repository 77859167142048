import React, { useState } from "react";
import {
  Box,
  Avatar,
  HStack,
  Text,
  Link,
  VStack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { AttributesTable, EditableDate, EditableSelect } from "@sciencecorp/helix-components";
import { DraftCommand, IncidentReport } from "../../types/incident_reports";
import moment from "moment";
import { useGetAllSitesQuery } from "../../api/sites";
import { ApprovalFlowTable } from "../shared/ApprovalFlowTable";
import { UserLoggedInData } from "../../api/user";
import { DefaultIncidentTypes } from "./DefaultIncidentTypes";
import { DateTime } from "luxon";
import {
  useInvalidateIncidentReport,
  useUpdateIncidentReportMutation,
} from "../../api/incident_reports";
import { useQueryClient } from "@tanstack/react-query";

interface IncidentReportDetailsSidebarProps {
  incidentReport: IncidentReport;
  addCommandInDraft: (command: DraftCommand) => void;
  currentUser: UserLoggedInData;
  isInDraftMode: boolean;
}

export const IncidentReportDetailsSidebar: React.FC<IncidentReportDetailsSidebarProps> = ({
  incidentReport,
  addCommandInDraft,
  currentUser,
  isInDraftMode,
}) => {
  const createdEvent = incidentReport.timeline_events.find(
    (event) => event.event_type === "created"
  );
  const [incidentTypeOptions, setIncidentTypeOptions] = useState([
    ...new Set([...DefaultIncidentTypes, "Other (specify if other)", incidentReport.incident_type]),
  ]);
  const appendIncidentTypeOption = (type: string) => {
    setIncidentTypeOptions([...new Set([...incidentTypeOptions, type])]);
  };
  const [selectedIncidentType, setSelectedIncidentType] = useState(incidentReport.incident_type);

  const { data: siteOptions, isLoading: isLoadingSiteOptions } = useGetAllSitesQuery();

  const borderColor = useColorModeValue("gray.200", "gray.600");
  const backgroundColor = useColorModeValue("gray.50", "gray.700");

  const toast = useToast();

  const invalidateIncidentReport = useInvalidateIncidentReport(incidentReport.id);

  const { mutateAsync: updateIncidentReport, isLoading: isUpdatingIncidentReport } =
    useUpdateIncidentReportMutation();

  const queryClient = useQueryClient();

  const handleApprove = async (id: number) => {
    updateIncidentReport({
      id,
      commands: [
        {
          event_type: "approval_approved",
          event_data: {},
        },
      ],
    }).then(invalidateIncidentReport);
  };

  const handleDecline = async (id: number) => {
    updateIncidentReport({
      id,
      commands: [
        {
          event_type: "approval_declined",
          event_data: {},
        },
      ],
    }).then(invalidateIncidentReport);
  };

  return (
    <VStack spacing={2} w="100%">
      <Box
        bg={backgroundColor}
        borderRadius="md"
        width="100%"
        border="1px"
        borderColor={borderColor}
        overflow="visible"
        p={4}>
        <AttributesTable
          title="Report Details"
          attributes={[
            {
              label: "Reported By",
              value: createdEvent && createdEvent.user && (
                <Link href={`/users/${createdEvent.user.id}`}>
                  <HStack>
                    <Avatar size="xs" name={createdEvent.user.name} />
                    <Text fontWeight="semibold" fontSize="sm">
                      {createdEvent.user.name}
                    </Text>
                  </HStack>
                </Link>
              ),
            },
            {
              label: "Incident Type",
              value: (
                <EditableSelect
                  options={incidentTypeOptions.map((type) => ({ label: type, value: type }))}
                  selectedValue={selectedIncidentType}
                  onSubmit={(value) => {
                    if (!value) return;
                    addCommandInDraft({
                      event_type: "incident_type_updated",
                      event_data: { incident_type: value.toString() },
                    });
                  }}
                  creatable
                  preview={incidentReport.incident_type}
                  onCreate={(value) => {
                    if (!value) return;
                    const newValue = value.toString();
                    appendIncidentTypeOption(newValue);
                    setSelectedIncidentType(newValue);
                  }}
                />
              ),
            },
            {
              label: "Site",
              value: isLoadingSiteOptions ? (
                <Box>Loading...</Box>
              ) : (
                <EditableSelect
                  options={siteOptions.map((site) => ({ label: site.name, value: site.id }))}
                  selectedValue={incidentReport.site.id}
                  preview={incidentReport.site.name}
                  onSubmit={(value) => {
                    if (!value) return;
                    addCommandInDraft({
                      event_type: "site_updated",
                      event_data: {
                        site_id: Number(value),
                        site_name:
                          siteOptions.find((site) => site.id === Number(value))?.name || "",
                      },
                    });
                  }}
                />
              ),
            },
            {
              label: "Incident Dates",
              value: <>{/* TODO */}</>,
            },
            {
              label: "Date Discovered",
              value: (
                <EditableDate
                  defaultValue={incidentReport.discovered_at?.toISO() ?? undefined}
                  preview={
                    incidentReport.discovered_at ? (
                      <Text>
                        {moment(incidentReport.discovered_at.toISO()).format("MMM D, YYYY")}
                      </Text>
                    ) : undefined
                  }
                  onSubmit={(value) => {
                    if (!value) return;
                    addCommandInDraft({
                      event_type: "discovered_at_updated",
                      event_data: { discovered_at: DateTime.fromISO(value).toJSDate() },
                    });
                  }}
                />
              ),
            },
          ]}
        />
      </Box>
      {!isInDraftMode && (
        <Box data-testid="incident-report-approval-table">
          <ApprovalFlowTable
            approvable={incidentReport}
            currentUser={currentUser}
            approve={handleApprove}
            decline={handleDecline}
            isApproveLoading={isUpdatingIncidentReport}
            isDeclineLoading={isUpdatingIncidentReport}
            expenditureType="Incident Report"
          />
        </Box>
      )}
    </VStack>
  );
};
