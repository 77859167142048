import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { TeamShowData, invalidateTeamAndBudgetHeadcount } from "../../../../api/team";
import { useActiveUsersQuery } from "../../../../api/user";
import { Headcount } from "../../../Budgets/Headcount";
import { SpendingCard } from "../../../Budgets/SpendingCard";
import { TeamProjectExpenditureCard } from "./TeamProjectExpenditureCard";
import { TeamEquipmentCard } from "./TeamEquipmentCard";

interface TeamBudgetProps {
  team: TeamShowData;
  editable: boolean;
  isCeo: boolean;
}
export const TeamBudget = ({ team, editable, isCeo }: TeamBudgetProps) => {
  const { budget_id: budgetId } = team;
  const { data: users } = useActiveUsersQuery();

  if (budgetId === null) {
    return <Text>No budgets exist for this team.</Text>;
  }
  return (
    <Flex direction="column" gap={6}>
      <Flex direction={{ base: "column", "2xl": "row" }} gap={6}>
        <Headcount
          team={team}
          budgetId={budgetId}
          editable={editable}
          users={users || []}
          location={"team"}
          onSuccessCallback={() => invalidateTeamAndBudgetHeadcount(team.id)}
        />
        <TeamProjectExpenditureCard
          teamId={team.id}
          editable={editable}
          topLevelTeamId={team.top_level_team?.id}
        />
      </Flex>

      <TeamEquipmentCard team={team} />

      <SpendingCard
        budgetableName={team.name}
        budgetId={budgetId}
        teamIds={[...team.all_sub_team_ids, team.id]}
        editable={editable}
        location={"team"}
      />
    </Flex>
  );
};
