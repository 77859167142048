import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Skeleton,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  Tooltip,
  VStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Collection,
  Column,
  EmptyState,
  RecordLink,
  useCollection,
} from "@sciencecorp/helix-components";
import React, { useMemo } from "react";
import {
  CapitalEquipmentShowData,
  useSearchCapitalEquipments,
} from "../../../../api/capital_equipment";
import { TeamShowData } from "../../../../api/team";
import { useCurrency } from "../../../../contexts/CurrencyContext";
import { Money } from "../../../../helpers/Money";
import { NewCapitalEquipment } from "../../../CapitalEquipments/components/NewCapitalEquipmentModal";
import { MoneyText } from "../../../MoneyText";
import { AddIcon } from "@chakra-ui/icons";

interface TeamEquipmentCardProps {
  team: TeamShowData;
}
export const TeamEquipmentCard = ({ team }: TeamEquipmentCardProps): JSX.Element => {
  const { pagination, onPagination, order, onOrder } = useCollection({
    pagination: { per_page: 10 },
  });
  const currency = useCurrency();
  const { data, isLoading, isSuccess, isError } = useSearchCapitalEquipments({
    term: "*",
    filters: { team_id: [team.id, ...team.all_sub_team_ids], is_archived: false },
    pagination,
    order: { name: "asc" },
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const statBgColor = useColorModeValue("gray.50", "gray.700");
  const statBorderColor = useColorModeValue("gray.200", "gray.600");

  const columns: Column<CapitalEquipmentShowData>[] = useMemo(
    () => [
      {
        label: "Equipment",
        weight: 3,
        render: (equipment: CapitalEquipmentShowData) => (
          <Tooltip label={equipment.name}>
            <Box w={"100%"}>
              <RecordLink link={`/equipment/${equipment.id}/details`} identifier={equipment.name} />
            </Box>
          </Tooltip>
        ),
      },
      {
        label: "Team",
        weight: 3,
        render: (equipment) => (
          <RecordLink
            type={"Team"}
            link={`/teams/${equipment.team?.id}/budget`}
            identifier={equipment.team?.name || ""}
          />
        ),
      },
      {
        label: "Spent",
        weight: 3,
        render: (equipment) => (
          <MoneyText money={equipment.summary?.spent_amount} formatOptions={{ compact: "never" }} />
        ),
      },
    ],
    []
  );

  const equipment = data?.results || [];

  const allTeamEquipmentSpent: Money = Money.sum(
    Money.zero(currency),
    ...equipment.map((equip) => equip.summary?.spent_amount || Money.zero(currency))
  );

  if (isError) {
    return <Alert status="error">Failed to load equipment</Alert>;
  }

  return (
    <Skeleton isLoaded={isSuccess}>
      <Accordion
        width="100%"
        allowToggle
        defaultIndex={0}
        border="1px"
        borderColor="chakra-border-color"
        borderRadius="md">
        <AccordionItem border="none">
          <AccordionButton>
            <HStack width="100%" justifyContent="space-between">
              <Heading p={2} size="md">
                Team Equipment
              </Heading>
              <HStack spacing={4}>
                <Button
                  leftIcon={<AddIcon />}
                  onClick={(e) => {
                    e.stopPropagation();
                    onOpen();
                  }}
                  colorScheme="teal">
                  Add New
                </Button>
                <AccordionIcon />
              </HStack>
            </HStack>
          </AccordionButton>
          <AccordionPanel>
            <Box flex={1}>
              <NewCapitalEquipment teamId={team.id} isOpen={isOpen} onClose={onClose} />
              <VStack spacing={4} w={"100%"} borderRadius="md">
                <Divider />
                <HStack
                  w={"100%"}
                  justifyContent={"space-between"}
                  bg={statBgColor}
                  p={6}
                  borderRadius={"md"}
                  borderWidth={"1px"}
                  borderColor={statBorderColor}>
                  <Stat>
                    <StatLabel>Total Affiliated Equipment Spent Amount</StatLabel>
                    <StatNumber>
                      <MoneyText
                        money={allTeamEquipmentSpent}
                        formatOptions={{ compact: "never" }}
                      />
                    </StatNumber>
                  </Stat>
                </HStack>

                {equipment.length > 0 ? (
                  <Box w={"100%"}>
                    <Collection
                      items={equipment}
                      columns={columns}
                      isLoading={isLoading}
                      pagination={data?.pagination || pagination}
                      order={order}
                      onOrder={onOrder}
                      onPagination={onPagination}
                    />
                  </Box>
                ) : (
                  <EmptyState size="sm" title={`This team is not affiliated with any equipment.`} />
                )}
              </VStack>
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Skeleton>
  );
};
