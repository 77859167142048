import {
  useToast,
  VStack,
  useColorModeValue,
  Stack,
  Tag,
  Heading,
  HStack,
  FormControl,
  FormLabel,
  Text,
  Box,
  Flex,
} from "@chakra-ui/react";
import { FormModal, Select } from "@sciencecorp/helix-components";
import React, { useEffect, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router";
import { useSitesOptions } from "../../../api/options";
import {
  ServiceRequestShowData,
  useCreateServiceRequest,
  useUpdateServiceRequest,
} from "../../../api/service_requests";
import { useCurrentUserQuery } from "../../../api/user";
import { SpendingAuthoritySelect } from "../../Purchasing/SpendingAuthoritySelectTree";
import { FormFieldTypes } from "../../../api/form_builder";
import { MinimalSpendingAuthority } from "../../../api/spending_authority";
import { ServicePartialData } from "../../../api/services";
import { FormFieldSubmission } from "./ServiceRequest/FormFieldSubmission";
import { WarnIfSpendingAuthorityIrrelevant } from "../../shared/WarnIfSpendingAuthorityIrrelevant";

const defaultVal = {
  spending_authority: null,
  site: null,
};

type BasicServiceRequestFieldTypes = {
  spending_authority: MinimalSpendingAuthority | null;
  site: string | null;
};

export const getDefaultForFieldType = (type: FormFieldTypes) => {
  switch (type) {
    case "Form::Field::ShortText":
    case "Form::Field::LongText":
      return "";
    case "Form::Field::SingleSelect":
      return null;
    case "Form::Field::MultiSelect":
    case "Form::Field::File":
      return [];
    case "Form::Field::Number":
      return "";
    case "Form::Field::Date":
      return null;
    default:
      return null;
  }
};

type TeamServiceRequestModalProps = {
  service: ServicePartialData;
  isOpen: boolean;
  onClose: () => void;
  setActiveService?: React.Dispatch<React.SetStateAction<ServicePartialData | null>>;
  serviceRequest?: ServiceRequestShowData;
};

export const TeamServiceRequestModal = ({
  service,
  isOpen,
  onClose,
  setActiveService,
  serviceRequest,
}: TeamServiceRequestModalProps) => {
  const navigate = useNavigate();

  const { control, handleSubmit, reset, setValue, watch } = useForm<BasicServiceRequestFieldTypes>({
    defaultValues: defaultVal,
  });

  const defaultFormBuilderValues = useMemo(() => {
    if (serviceRequest) {
      return serviceRequest.form_submission.responses.reduce((acc, response) => {
        acc[response.field_id.toString()] = response.value;
        return acc;
      }, {});
    } else {
      return service.form.active_template?.fields.reduce((acc, field) => {
        acc[field.id.toString()] = getDefaultForFieldType(field.type);
        return acc;
      }, {});
    }
  }, [serviceRequest]);

  const {
    control: formControl,
    reset: formReset,
    watch: formWatch,
  } = useForm({
    defaultValues: defaultFormBuilderValues,
  });

  useEffect(() => {
    if (serviceRequest) {
      reset({
        spending_authority: serviceRequest.spending_authority,
        site: serviceRequest.site.id.toString(),
      });
      const newFormValues = {};
      serviceRequest.form_submission.responses.forEach((response) => {
        newFormValues[response.field_id.toString()] = response.value;
      });

      formReset(newFormValues);
    }
  }, [serviceRequest]);

  const currentUserQuery = useCurrentUserQuery();
  const currentUser = currentUserQuery?.data;
  const { mutateAsync: createServiceRequest, isLoading } = useCreateServiceRequest();
  const { mutateAsync: updateServiceRequest, isLoading: isLoadingUpdateRequest } =
    useUpdateServiceRequest(serviceRequest?.id);

  const formBasicValues = watch();
  const formBuilderValues = formWatch();

  const requiredFieldsDirty = () => {
    const spendingAuthorityValid = service.spending_authority_required
      ? formBasicValues.spending_authority
      : true;
    const siteValid = formBasicValues.site;

    const requiredFields = serviceRequest
      ? serviceRequest.form_submission.template.fields
      : service.form.active_template?.fields;

    return (
      requiredFields?.every(
        (field) => !field.is_required || !!formBuilderValues[field.id.toString()]
      ) &&
      spendingAuthorityValid &&
      siteValid
    );
  };

  const toast = useToast();

  const SITE_OPTIONS = useSitesOptions();

  const onSubmit = (data) => {
    const { site } = data;

    if (currentUser && site && service.form.active_template) {
      if (serviceRequest) {
        updateServiceRequest({
          id: serviceRequest.id,
          site_id: Number(site),
          spending_authority_id: data.spending_authority?.id || null,
          spending_authority_type: data.spending_authority?.type || null,
          form_submission: {
            id: serviceRequest.form_submission.id,
            responses: Object.keys(formBuilderValues).map((key) => ({
              response_id: serviceRequest.form_submission.responses.find(
                (response) => response.field_id === Number(key)
              )!.id,
              value: formBuilderValues[key],
            })),
          },
        })
          .then(() => {
            setActiveService && setActiveService(null);
            onClose();
          })
          .catch((error) => {
            console.error(error);
            toast({
              title: "An error occurred.",
              description: error.response.data.error.message,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          });
      } else {
        createServiceRequest({
          site_id: Number(site),
          spending_authority_id: data.spending_authority?.id || null,
          spending_authority_type: data.spending_authority?.type || null,
          requesting_user_id: currentUser.id,
          service_id: service.id,
          form_submission: {
            user_id: currentUser.id,
            template_id: service.form.active_template.id,
            responses: Object.keys(formBuilderValues).map((key) => ({
              field_id: Number(key),
              value: formBuilderValues[key],
            })),
          },
        })
          .then((data) => {
            setActiveService && setActiveService(null);
            reset();
            formReset();
            onClose();
            navigate(`/services/requests/${data.id}`);
          })
          .catch((error) => {
            console.error(error);
            toast({
              title: "An error occurred.",
              description: error.response.data.error.message,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          });
      }
    }
  };

  const handleClose = () => {
    reset();
    formReset();
    setActiveService && setActiveService(null);
    onClose();
  };

  const textColor = useColorModeValue("gray.500", "gray.200");

  const template = serviceRequest
    ? serviceRequest.form_submission.template
    : service.form.active_template;

  return (
    <FormModal
      title="Create a New Request"
      isOpen={isOpen}
      onClose={handleClose}
      isLoading={isLoading}
      submitButtonDisabled={!requiredFieldsDirty()}
      size={"2xl"}
      submitButtonColorSchema="teal"
      submitButtonTitle={serviceRequest ? "Update Request" : "Create Request"}
      closeOnOverlayClick={false}
      handleSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" gap={4} width="100%" p={2}>
        <Stack direction="column" spacing={1}>
          <HStack>
            <Heading size="sm" fontWeight="bold">
              {service.name}
            </Heading>
            <Tag colorScheme="teal">by {service.team.name}</Tag>
          </HStack>
          <Text>
            {service.description.split("\n").map((line, i) => (
              <Text key={i} fontSize={["xs", "sm"]} color={textColor}>
                {line}
              </Text>
            ))}
          </Text>
        </Stack>
        <Text fontSize="sm" color={textColor} pt={2}>
          <Text as="span" color="red.500">
            *
          </Text>{" "}
          All fields marked with the asterisk are required
        </Text>
        <VStack width="100%" gap={4}>
          {template?.fields.map((field, idx) => (
            <FormFieldSubmission
              key={`field-${field.id}-${idx}`}
              formField={field}
              control={formControl}
            />
          ))}
          <HStack width="100%">
            <Controller
              name="spending_authority"
              control={control}
              render={({ field }) => (
                <FormControl isRequired={service.spending_authority_required}>
                  <FormLabel>
                    Spending Authority{" "}
                    {!service.spending_authority_required && (
                      <Box as="span" opacity="0.7" fontWeight="normal">
                        (optional)
                      </Box>
                    )}
                  </FormLabel>
                  <SpendingAuthoritySelect
                    onChange={(value) => {
                      if (value) {
                        setValue("spending_authority", value, {
                          shouldDirty: true,
                        });
                      }
                    }}
                    spendingAuthority={formBasicValues.spending_authority}
                    excludeTypes={["Service"]}
                  />
                  <WarnIfSpendingAuthorityIrrelevant
                    id={field.value?.id}
                    type={field.value?.type}
                  />
                </FormControl>
              )}
            />
            <Controller
              name="site"
              control={control}
              render={({ field }) => (
                <FormControl isRequired={true}>
                  <FormLabel>Site</FormLabel>

                  <Select
                    {...field}
                    placeholder="Select"
                    options={SITE_OPTIONS}
                    menuPlacement="auto"
                  />
                </FormControl>
              )}
            />
          </HStack>
        </VStack>
      </Flex>
    </FormModal>
  );
};
