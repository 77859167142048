import React from "react";
import { Box, Button, useDisclosure, Flex } from "@chakra-ui/react";
import {
  Header,
  SplitPage,
  useCollection,
  Facets,
  buildFacets,
} from "@sciencecorp/helix-components";
import { useSearchParams } from "react-router-dom";
import { useDebouncedSearch } from "../hooks/useDebouncedSearch";
import { useCurrentUserQuery, userHasFeatureFlag } from "../../api/user";
import { FiAlertOctagon } from "react-icons/fi";
import { BiPieChartAlt2 } from "react-icons/bi";
import { NewIncidentReportModal } from "./NewIncidentReportModal";
import { useSearchIncidentReportsQuery } from "../../api/incident_reports";
import { IncidentReportsAnalytics } from "./IncidentReportsAnalytics";
import { IncidentReportsTable } from "./IncidentReportsTable";

export const IncidentReports = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getActiveTab = () => {
    return searchParams.get("tab") || "reports";
  };

  const {
    isOpen: isOpenNewReport,
    onOpen: onOpenNewReport,
    onClose: onCloseNewReport,
  } = useDisclosure();

  const { pagination, order, onPagination, onOrder, facets, onFacets, filters } = useCollection();

  const { search, debouncedSearch } = useDebouncedSearch();

  const userQuery = useCurrentUserQuery();

  const tabs = [
    {
      label: "Analytics",
      value: "analytics",
      icon: <BiPieChartAlt2 size={20} />,
    },
    {
      label: "Reports",
      value: "reports",
      icon: <FiAlertOctagon size={20} />,
    },
  ];

  const featureFlagged = userHasFeatureFlag(userQuery, "incident_reporting");

  const { data: incidentReports, isLoading } = useSearchIncidentReportsQuery(
    {
      term: search || "*",
      pagination: {
        page: pagination.page,
        per_page: pagination.per_page,
      },
      // order: order, TODO: bring back when we have incident reports to show
    },
    featureFlagged
  );

  if (!featureFlagged) return null;

  const populatedFacets = buildFacets(incidentReports?.aggregations || {}, facets);

  return (
    <Box>
      <NewIncidentReportModal isOpen={isOpenNewReport} onClose={onCloseNewReport} />
      <Header
        title="Incident Reports"
        actions={[
          <Button colorScheme="teal" onClick={onOpenNewReport}>
            + New Incident Report
          </Button>,
        ]}
      />
      <Box overflow="auto">
        <SplitPage
          sidebarWidth="250px"
          sidebarWidthXL="300px"
          sidebar={
            <Flex direction="column" gap={2}>
              {tabs.map((tabInfo) => (
                <Button
                  key={tabInfo.value}
                  leftIcon={tabInfo.icon}
                  width="100%"
                  justifyContent="start"
                  colorScheme={getActiveTab() === tabInfo.value ? "teal" : "gray"}
                  bg={getActiveTab() === tabInfo.value ? "teal" : "transparent"}
                  onClick={() => {
                    setSearchParams({ tab: tabInfo.value });
                  }}>
                  {tabInfo.label}
                </Button>
              ))}
              <Box display={["flex", "flex", "none"]} alignSelf="end">
                <Facets
                  variant="button"
                  facets={populatedFacets}
                  onChange={onFacets}
                  search
                  background
                  debouncedSearch={debouncedSearch}
                />
              </Box>
              <Box display={["none", "none", "inline"]} width="100%" alignSelf="start">
                <Facets
                  facets={populatedFacets}
                  onChange={onFacets}
                  search
                  background
                  debouncedSearch={debouncedSearch}
                />
              </Box>
            </Flex>
          }
          main={
            <Box p={4}>
              {getActiveTab() === "reports" ? (
                <IncidentReportsTable incidentReports={incidentReports} isLoading={isLoading} />
              ) : (
                <IncidentReportsAnalytics />
              )}
            </Box>
          }
        />
      </Box>
    </Box>
  );
};
