import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  HStack,
  Text,
  Avatar,
  Tag,
  Tab,
  TabList,
  Tabs,
  Spinner,
  useToast,
  Heading,
  useColorModeValue,
  Button,
  TagLabel,
  Tooltip,
} from "@chakra-ui/react";
import { CalendarIcon, DeleteIcon } from "@chakra-ui/icons";
import { PiFolder } from "react-icons/pi";
import { Select, OptionBase } from "chakra-react-select";
import {
  ProjectEdgeShowData,
  useCreateProjectEdge,
  useDeleteProjectEdge,
  useGetProjectEdgesForProject,
} from "../../../api/planning/project_edges";
import { CollectionTable, EmptyState, RecordLink } from "@sciencecorp/helix-components";
import { DateTime } from "luxon";

interface ProjectOption extends OptionBase {
  label: string;
  value: string;
}

export const DependenciesTable = ({ selectedProject, sortedProjects }) => {
  const grayL600DAuto = useColorModeValue("gray.600", "auto");
  const grayL200DAuto = useColorModeValue("gray.200", "gray.600");

  const { mutate: createProjectEdge } = useCreateProjectEdge();
  const { mutate: deleteProjectEdge } = useDeleteProjectEdge();
  const [options, setOptions] = useState<ProjectOption[]>([]);
  const toast = useToast();

  const {
    data: projectEdges,
    isSuccess: isSuccessProjectEdges,
    isLoading,
    isError,
  } = useGetProjectEdgesForProject(selectedProject?.id);

  const handleDependencyChange = (selectedOption: ProjectOption | null) => {
    if (!selectedProject || !projectEdges || !selectedOption) {
      console.error("No selected project available.");
      return;
    }

    const ancestorId = Number(selectedOption.value);
    if (
      !projectEdges.some(
        (edge) => edge.ancestor.id === ancestorId && edge.descendant.id === selectedProject.id
      )
    ) {
      createProjectEdge({ ancestor_id: ancestorId, descendant_id: selectedProject.id });
    }
  };

  const handleRemoveDependency = (id: number) => {
    deleteProjectEdge(id, {
      onSuccess: () => {
        toast({
          title: "Dependency successfully removed",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      },
      onError: (error: unknown) => {
        return toast({
          title: "Failed to remove dependency",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      },
    });
  };

  useEffect(() => {
    if (selectedProject) {
      const invalidIds = new Set(selectedProject.invalid_dependency_ids || []);

      const newOptions = sortedProjects
        .filter((project) => project.id !== selectedProject.id && !invalidIds.has(project.id))
        .map((project) => ({
          label: project.title,
          value: project.id.toString(),
        }));

      setOptions(newOptions);
    }
  }, [sortedProjects, selectedProject, projectEdges]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <Text>Error loading dependencies</Text>;
  }

  const statusMap = [
    { label: "Draft", value: "draft", color: "gray" },
    { label: "Active", value: "active", color: "green" },
    { label: "Approved", value: "approved", color: "blue" },
    { label: "Abandoned", value: "abandoned", color: "orange" },
    { label: "Completed", value: "completed", color: "green" },
    { label: "Suspended", value: "suspended", color: "yellow" },
    { label: "Needs Approval", value: "needs_approval", color: "red" },
    { label: "Declined", value: "declined", color: "red" },
  ];

  const columns = [
    {
      label: "Project",
      weight: 6,
      render: (edge: ProjectEdgeShowData) => (
        <Box>
          <RecordLink
            maxWidth="100%"
            identifier={edge.descendant.title}
            icon={
              <Box>
                <PiFolder size={18} />
              </Box>
            }
            link={`/planning/projects/${edge.descendant.id}`}
          />
        </Box>
      ),
    },
    {
      label: "Status",
      render: (edge: ProjectEdgeShowData) => (
        <Tag
          colorScheme={statusMap.find((s) => s.value === edge.descendant.status)?.color}
          w="max-content">
          <TagLabel>{edge.descendant.status}</TagLabel>
        </Tag>
      ),
    },
    {
      label: "Date Range",
      weight: 3,
      render: (edge: ProjectEdgeShowData) => {
        const startDate = edge.descendant.start_date
          ? DateTime.fromISO(edge.descendant.start_date.toString()).toFormat("LLL dd yyyy")
          : "Unknown Start Date";

        const endDate = edge.descendant.end_date
          ? DateTime.fromISO(edge.descendant.end_date.toString()).toFormat("LLL dd yyyy")
          : "Unknown End Date";

        return (
          <HStack spacing={2} minW="max-content">
            <CalendarIcon />
            <Text textColor={grayL600DAuto}>
              {startDate} - {endDate}
            </Text>
          </HStack>
        );
      },
    },

    {
      label: "Collaborators",
      weight: 1.5,
      render: (edge: ProjectEdgeShowData) => (
        <HStack spacing={1}>
          {edge.descendant.users.slice(0, 4).map((user, index) => (
            <Avatar
              key={index}
              size="sm"
              name={user.name}
              src={user.picture_uri}
              style={{ marginLeft: index > 0 ? "-15px" : "0px" }}
            />
          ))}
          {edge.descendant.users.length > 4 && (
            <Text textColor={grayL600DAuto}>& {edge.descendant.users.length - 4} other(s)</Text>
          )}
        </HStack>
      ),
    },
    {
      label: "",
      weight: 0.5,
      render: (dependency: any) => (
        <Tooltip label="Remove Dependency" aria-label="Remove Dependency">
          <Button size="xs" onClick={() => handleRemoveDependency(dependency.id)}>
            <DeleteIcon />
          </Button>
        </Tooltip>
      ),
    },
  ];
  return (
    <Flex
      justifyContent={"space-between"}
      direction={"column"}
      w={"100%"}
      border="1px"
      borderColor={grayL200DAuto}
      p={6}
      gap={4}
      borderRadius="md"
      overflow="hidden">
      <Flex width={"100%"} flexDirection={{ base: "column", md: "row" }} gap={4} paddingBottom={4}>
        <Box pr={2} flexGrow={1}>
          <Flex alignItems={"center"}>
            <Heading size={"md"} fontWeight={"semibold"}>
              Dependencies
            </Heading>
            <Box ml={2}>
              <Tag>{projectEdges?.length || 0}</Tag>
            </Box>
          </Flex>
        </Box>
        <Box width={60}>
          <Select
            options={options}
            value={null}
            onChange={handleDependencyChange}
            placeholder="Search to Add"
            size="md"
            isSearchable
            closeMenuOnSelect
            useBasicStyles
          />
        </Box>
      </Flex>
      <HStack justifyContent="space-between" w="full" mb={4}>
        <Tabs colorScheme="teal">
          <TabList>
            <Tab>Dependent Projects</Tab>
          </TabList>
        </Tabs>
      </HStack>
      {projectEdges.length > 0 ? (
        <CollectionTable columns={columns} items={projectEdges} />
      ) : (
        <EmptyState title="No dependent projects to show" />
      )}
    </Flex>
  );
};

export default DependenciesTable;
