import { SelectProps, useCollection } from "@sciencecorp/helix-components";
import { titleize } from "inflection";
import { orderBy } from "lodash";
import { toLabel } from "../helpers";
import { useGetArchetypes } from "./archetype";
import { useCandidateRolesQuery } from "./candidates";
import {
  candidateIdentifierTypeSchema,
  compensationOfferSchema,
} from "./compensation_offer/schema";
import { useGetCredentials } from "./credentials";
import { useGetInventories } from "./inventory";
import { useGetInventoryLocations } from "./inventory_location";
import { useGetArcs } from "./planning/arcs";
import { useGetAllRoles } from "./role";
import { useGetAllServicesQuery, useGetUniqueTeamWithServicesQuery } from "./services";
import { useGetAllSitesQuery } from "./sites";
import {
  TeamData,
  teamTypeSchema,
  useAllTeamsQuery,
  useSearchTeams,
  useTeamQuery,
  useTeamsQuery,
} from "./team";
import { useActiveUsersQuery, useCurrentUserQuery, useMinimalUsersQuery } from "./user";
import { userCompensationPayTypeOptions } from "./user_compensation";
import { useVendorsQuery } from "./vendor";
import { useGetBudgetGroupOptions, useGetBudgetGroups } from "./budget_groups";
import { useGetGeneralLedgerCodes } from "./general_ledger_codes";
import { useSearchInventoryBoms } from "./inventory_boms";
import { useSearchProjects } from "./planning/projects";
import { DateTime } from "luxon";

export type UseVendorOptionsProps = {
  excludeIds?: number[];
};

export const useVendorOptions = ({ excludeIds = [] }: UseVendorOptionsProps = {}) => {
  const { data } = useVendorsQuery();
  return (
    data
      ?.filter((vendor) => !vendor.archived_at && !excludeIds?.includes(vendor.id))
      .map((vendor) => ({ label: vendor.name, value: vendor.id.toString() })) || []
  );
};

export type UseInventoryBomOptionsProps = {
  excludeIds?: number[];
  searchTerm?: string;
};

export const useInventoryBomOptions = ({
  excludeIds = [],
  searchTerm = "",
}: UseInventoryBomOptionsProps = {}) => {
  const { pagination, order, filters } = useCollection();
  const { data } = useSearchInventoryBoms({
    term: searchTerm || "*",
    pagination,
    order,
    filters,
  });
  return (
    data?.results
      .filter((inventoryBom) => inventoryBom.status !== "draft")
      .filter((inventoryBom) => !excludeIds?.includes(inventoryBom.id))
      .map((inventoryBom) => ({ label: inventoryBom.name, value: inventoryBom.id.toString() })) ||
    []
  );
};

export type UseProjectOptionsProps = {
  excludeIds?: number[];
  searchTerm?: string;
};

export const useProjectOptions = ({
  excludeIds = [],
  searchTerm = "",
}: UseProjectOptionsProps = {}) => {
  const { data } = useSearchProjects({
    term: searchTerm || "*",
    pagination: { per_page: -1 },
    filters: {},
    endDate: DateTime.now().endOf("year"),
  });
  if (!data) return [];
  return data?.results
    .filter((project) => !excludeIds?.includes(project.id))
    .map((project) => ({ label: project.title, value: project.id }));
};

export const useCurrentUserTeamOptions = (
  allTeams?: boolean,
  teamId?: number,
  topLevelTeamMember?: boolean,
  teamLead?: boolean,
  allTeamsAndSubTeams?: boolean
): SelectProps["options"] => {
  const currentUserQuery = useCurrentUserQuery();
  const { teams } = useAllTeamsQuery();
  if (!currentUserQuery?.data || !teams) return [];
  let teamList: TeamData[];

  if (allTeams) {
    teamList = teams;
  } else if (allTeamsAndSubTeams) {
    const topLevelTeams = currentUserQuery.data.team_memberships
      .map(({ team }) => team)
      .filter((team) => team.top_level_team === null);

    teamList = currentUserQuery.data?.team_memberships.map(({ team }) => team) || [];
    topLevelTeams.forEach((topLevelTeam) => {
      const teamTree = traverseTeamSubTeams(topLevelTeam);
      const uniqueSubTeams = teamTree.filter((team) => !teamList.some((t) => t.id === team.id));

      teamList.push(...uniqueSubTeams);
    });
  } else if (topLevelTeamMember) {
    const team = teams.find(
      (team) =>
        team.id ===
        (teams.find((team) => teamId != undefined && team.id === teamId)?.top_level_team?.id ||
          teamId)
    );
    teamList = traverseTeamSubTeams(team);
  } else if (teamLead) {
    const team = teams.find((team) => team.id === teamId);
    teamList = traverseTeamSubTeams(team);
  } else teamList = currentUserQuery.data?.team_memberships.map(({ team }) => team);

  return teamList.map(({ id, name, top_level_team }) => {
    const label = top_level_team ? `${name} (${top_level_team.name})` : name;

    return { label: label, value: id.toString() };
  });
};

const traverseTeamSubTeams = (team) => {
  let teamFlatList: any[] = [];

  const traverse = (team) => {
    teamFlatList.push(team);
    if (team.sub_teams.length) {
      team.sub_teams.forEach((subTeam) => traverse(subTeam));
    }
  };

  traverse(team);
  return teamFlatList;
};

export const useTeamMembersOptions = (teamId: number | undefined, excludedUserIds?: number[]) => {
  if (!teamId) return [];
  const { data } = useTeamQuery(teamId);
  if (!data) return [];
  const uniqueUsers = new Set();
  const memberships = data?.all_memberships?.filter(({ user }) => {
    if (uniqueUsers.has(user.id)) return false;
    if (!excludedUserIds?.includes(user.id)) {
      uniqueUsers.add(user.id);
      return true;
    }
  });
  return memberships?.map((membership) => membership.user);
};

export const usePayTypeOptions = () => {
  return userCompensationPayTypeOptions;
};
export const useCompensationOfferTypeOptions = () => {
  return Object.values(compensationOfferSchema.shape.offer_type.enum).map((value) => ({
    label: toLabel(value),
    value,
  }));
};
export const useCompensationCandidateIdentifierTypeOptions = () => {
  return Object.values(candidateIdentifierTypeSchema.enum).map((value) => ({
    label: toLabel(value),
    value,
  }));
};
export const useTeamTypeOptions = () => {
  return Object.values(teamTypeSchema.enum).map((value) => ({
    label: toLabel(value),
    value,
  }));
};
export const useUserOptions = (excludedList?: number[]) => {
  const { data } = useActiveUsersQuery();
  if (!data) return [];
  return data
    .map(({ id, name }) => ({
      label: name,
      value: id.toString(),
    }))
    ?.filter(({ value }) => !excludedList?.some((id) => id === +value));
};

export const useUserMentionOptions = () => {
  const { data } = useMinimalUsersQuery();
  if (!data) return [];

  return data.map((user) => ({
    label: user.name,
    value: user.id,
    appHref: user.app_href,
    modelType: "User",
  }));
};

export const useTeamOptions = (excludedIds?: number[]) => {
  const { teams } = useAllTeamsQuery();
  if (!teams) return [];

  return orderBy(teams, ["name"], ["asc"])
    .filter((team) => !excludedIds?.includes(team.id))
    .map(({ id, name }) => ({
      label: name,
      value: id,
    }));
};

export const useTeamBudgetOptions = (teamIds: number[]) => {
  // need to figure otu if most recent budget id is nullable
  const { data } = useSearchTeams({
    term: "*",
    filters: { id: teamIds },
    pagination: { per_page: -1 },
  });

  if (!data) return [];
  return data.results.map((team) => ({
    label: team.name,
    value: team.budget_id,
  }));
};

export const useCandidateRoleOptions = () => {
  const { data } = useCandidateRolesQuery();
  if (!data) return [];

  return data.map((name) => {
    if (name !== "") {
      return {
        label: name,
        value: name,
      };
    }
  });
};

export const useTeamServicesOptions = () => {
  const { data } = useGetUniqueTeamWithServicesQuery();
  if (!data) return [];

  return data.map((team) => ({ label: team.name, value: team.id.toString() }));
};

export const useSitesOptions = () => {
  const { data } = useGetAllSitesQuery();
  if (!data) return [];
  return data.map((site) => ({ label: site.name, value: site.id.toString() }));
};

export const useRolesOptions = () => {
  const { data } = useGetAllRoles();
  if (!data) return [];
  return data.map((role) => ({
    label: titleize(role.name.split("_").join(" ")),
    value: role.id.toString(),
  }));
};

export const useArchetypeOptions = () => {
  const { data } = useGetArchetypes();
  if (!data) return [];
  return data.map((archetype) => ({
    label: archetype.name,
    value: archetype.id,
  }));
};

export const useCredentialsOptions = (excludedIds?: number[]) => {
  const { data } = useGetCredentials();
  if (!data) return [];
  return data
    .filter((credential) => !excludedIds?.includes(credential.id))
    .map((credential) => {
      return { label: credential.name, value: credential.id.toString() };
    });
};

export const useArcOptions = (allowUnselect: boolean = true) => {
  const start = allowUnselect ? [{ label: "No Group", value: -1 }] : [];
  const { data } = useGetArcs();
  if (!data) return [];
  return start.concat(data.map((arc) => ({ label: arc.title, value: arc.id })));
};

export const useInventoryOptions = (excludedIds?: number[]) => {
  const { data } = useGetInventories();
  if (!data) return [];
  return data
    .filter((inventory) => !excludedIds?.includes(inventory.id))
    .map((inventory) => ({
      label: `${inventory.name} - (${inventory.sku || "N/A"})`,
      value: inventory.id,
    }));
};

export const useInventoryLocationOptions = () => {
  const { data } = useGetInventoryLocations();
  if (!data) return [];
  return data.map((location) => ({ label: location.name, value: location.id }));
};

export const useBudgetGroupOptions = (excludedIds?: number[]) => {
  const { data } = useGetBudgetGroups();

  if (!data) return [];

  return data
    .filter((budgetGroup) => !excludedIds?.includes(budgetGroup.id))
    .map((group) => ({ label: group.name, value: group.id }));
};

export const useBudgetGroupOptionsWithoutSummary = () => {
  const { data } = useGetBudgetGroupOptions();
  if (!data) return [];
  return data.map((group) => ({ label: group.name, value: group.id }));
};

export const useGeneralLedgerCodeOptions = () => {
  const { data } = useGetGeneralLedgerCodes();
  if (!data) return [];

  return data.map((generalLedgerCode) => ({
    label: `${generalLedgerCode.account_number} - ${generalLedgerCode.title}`,
    value: generalLedgerCode.id.toString(),
  }));
};

export const useServiceOptions = (serviceId?: number) => {
  const { data } = useGetAllServicesQuery();
  if (!data) return [];
  return data
    .filter((service) => service.id !== serviceId)
    .map((service) => ({ label: service.name, value: service.id }));
};

export const useMeetingNoteVisibilityOptions = () => {
  return [
    { label: "Attendees Only", value: "attendees_only", color: "green" },
    { label: "Anyone with Link", value: "anyone_with_link", color: "blue" },
  ];
};

export const useConfidentialityOptions = () => {
  return [
    {
      label: "Hidden",
      value: "hidden",
      color: "red",
      description: "Your response will not be displayed with any identifiable information.",
    },
    {
      label: "Group Based",
      value: "group_based",
      color: "blue",
      description:
        "Your response will be linked to your budget group but no other identifiable information will be visible to People Ops.",
    },
    {
      label: "Team Based",
      value: "team_based",
      color: "blue",
      description:
        "Your response will include your team information but no personal identifiers will be shown to People Ops.",
    },
    {
      label: "Identifiable",
      value: "identifiable",
      color: "green",
      description:
        "Your response will include your name, team, and budget group, making it fully identifiable to People Ops.",
    },
  ];
};

export const usePollCategoryOptions = () => {
  return [
    { label: "Job Satisfaction", value: "job_satisfaction" },
    { label: "Sense of Belonging", value: "sense_of_belonging" },
    { label: "Clarity of Success", value: "clarity_of_success" },
  ];
};

export const useServiceAnalyticsOptions = () => {
  return [
    { label: "By Category", value: "category" },
    { label: "By Priority", value: "priority" },
    { label: "By Service", value: "service" },
  ];
};
