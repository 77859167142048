import React, { useRef } from "react";
import { HStack, Tag, IconButton, Button, Text, Input } from "@chakra-ui/react";
import { SmallCloseIcon } from "@chakra-ui/icons";

type MultipleFileDisplayProps = {
  files: { name: string; file: string }[];
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDelete: (index: number) => void;
};

export const MultipleFileDisplay = ({
  files,
  handleFileChange,
  onDelete,
}: MultipleFileDisplayProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    hiddenFileInput.current?.click();
  };
  return (
    <>
      <HStack spacing={2} mb={2} wrap="wrap">
        {files.map((file, index) => (
          <Tag gap={2} key={`${file.name}-${index}`}>
            <Text> {file.name}</Text>
            <IconButton
              size="sm"
              icon={<SmallCloseIcon />}
              onClick={() => {
                onDelete(index);
              }}
              aria-label="delete file"
            />
          </Tag>
        ))}
      </HStack>
      <Input
        type="file"
        ref={hiddenFileInput}
        multiple
        onChange={handleFileChange}
        display="none"
      />
      <Button data-testid="file-upload" onClick={handleClick}>
        Choose Files
      </Button>
    </>
  );
};
