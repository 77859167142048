import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Text,
  Spinner,
  Center,
  Avatar,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FiAlertOctagon } from "react-icons/fi";
import moment from "moment";
import { CollectionTable, HelixHomeLink } from "@sciencecorp/helix-components";
import { SearchIncidentReportsResponse } from "../../types/incident_reports";

interface IncidentReportsTableProps {
  incidentReports: SearchIncidentReportsResponse | undefined;
  isLoading: boolean;
}

export const IncidentReportsTable: React.FC<IncidentReportsTableProps> = ({
  incidentReports,
  isLoading,
}) => {
  const hoverBackgroundColor = useColorModeValue("gray.50", "gray.700");

  if (isLoading) {
    return (
      <Center h="200px">
        <Spinner />
      </Center>
    );
  }

  if (!incidentReports?.results?.length) {
    return (
      <Center h="200px">
        <Text>No incident reports found</Text>
      </Center>
    );
  }

  return (
    <Box overflowX="auto">
      <CollectionTable
        items={incidentReports.results}
        columns={[
          {
            label: "Report",
            render: (item) => (
              <HelixHomeLink
                model="incident-reports"
                navigateTo={`/incident-reports/${item.id}`}
                id={item.id}
                identifier={item.id}
                icon={<FiAlertOctagon size={20} />}
                size="x-small"
              />
            ),
          },
          {
            label: "Type",
            render: (item) => item.incident_type,
          },
          {
            label: "Status",
            render: (item) => <></>, // TODO
          },
          {
            label: "Reported By",
            render: (item) => <></>, // TODO
          },
          {
            label: "Date of Incident",
            render: (item) => <></>, // TODO
          },
          {
            label: "Last Updated",
            render: (item) =>
              moment(item.timeline_events[item.timeline_events.length - 1].created_at).format(
                "MMM d, yyyy"
              ),
          },
          {
            label: "No. of Days Open",
            render: (item) => <></>, // TODO
          },
          {
            label: "Pi",
            render: (item) => <></>, // TODO
          },
        ]}
      />
    </Box>
  );
};
