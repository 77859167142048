import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FormModal, RichTextEditor, Select } from "@sciencecorp/helix-components";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { HiOutlineWrenchScrewdriver } from "react-icons/hi2";
import { TbMessageReport } from "react-icons/tb";
import { humanize } from "inflection";
import { GrPowerCycle } from "react-icons/gr";
import { useVendorOptions } from "../../../../api/options";
import { useCreateCapitalEquipmentLog } from "../../../../api/capital_equipment_log";
import { UserMinimalData } from "../../../../api/user";
import {
  CapitalEquipmentShowData,
  invalidateCapitalEquipments,
} from "../../../../api/capital_equipment";
import { UserSelect } from "../../../Users/shared/UserSelect";
import { FaToolbox } from "react-icons/fa";

export type MaintenanceModalProps = {
  isOpen: boolean;
  onClose: () => void;
  capitalEquipment: CapitalEquipmentShowData;
};

type MaintenanceLogFormValuesType = {
  logType: string;
  maintenanceDate: string;
  listOfMaintenanceActionableIds: number[];
  maintenanceLogUsers?: UserMinimalData[];
  vendorName?: string;
  maintenanceActionableType: string;
  note: string;
  rawText: string;
};

const defaultFormValues: MaintenanceLogFormValuesType = {
  logType: "",
  maintenanceDate: "",
  maintenanceLogUsers: [],
  listOfMaintenanceActionableIds: [],
  vendorName: "",
  maintenanceActionableType: "",
  note: "",
  rawText: "",
};

export const logOptions: {
  label: "maintenance" | "calibration" | "qualification" | "report_issue";
  icon: React.ReactNode;
  colorScheme: string;
}[] = [
  {
    label: "maintenance",
    icon: <HiOutlineWrenchScrewdriver />,
    colorScheme: "teal.400",
  },
  {
    label: "calibration",
    icon: <GrPowerCycle />,
    colorScheme: "purple.400",
  },
  {
    label: "qualification",
    icon: <FaToolbox />,
    colorScheme: "green.400",
  },
  { label: "report_issue", icon: <TbMessageReport />, colorScheme: "blue.400" },
];

export const MaintenanceModal = ({ isOpen, onClose, capitalEquipment }: MaintenanceModalProps) => {
  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: defaultFormValues,
  });
  const vendorOptions = useVendorOptions();
  const formValues = watch();
  const { mutateAsync: createCapitalEquipmentLog } = useCreateCapitalEquipmentLog();

  const handleClose = () => {
    reset();
    onClose();
  };

  const invalidateCapitalEquipment = invalidateCapitalEquipments(capitalEquipment.id);

  const onSubmit = (data: MaintenanceLogFormValuesType) => {
    const noteHash = JSON.parse(data.note);
    if (data.logType === "report_issue") {
      data.maintenanceActionableType = "";
      data.listOfMaintenanceActionableIds = [];
    }

    createCapitalEquipmentLog({
      capital_equipment_id: capitalEquipment.id,
      maintenance_actionable_id: data.listOfMaintenanceActionableIds,
      maintenance_actionable_type: data.maintenanceActionableType,
      maintenance_date: data.maintenanceDate,
      log_type: data.logType,
      notes: noteHash,
    }).then(() => {
      invalidateCapitalEquipment();
      handleClose();
    });
  };
  const colorTeal = useColorModeValue("teal.50", "teal.500");
  const colorTealBorder = useColorModeValue("teal.200", "teal.400");
  const checkColor = useColorModeValue("teal.500", "teal.200");

  const isDisabled =
    !formValues.logType ||
    (formValues.logType !== "report_issue" && !formValues.maintenanceActionableType) ||
    (formValues.logType !== "report_issue" &&
      formValues.listOfMaintenanceActionableIds.length === 0) ||
    !formValues.maintenanceDate ||
    formValues.rawText.length === 0;

  return (
    <FormModal
      size={"4xl"}
      closeOnOverlayClick={false}
      title="Add Log"
      isOpen={isOpen}
      submitButtonColorSchema="teal"
      onClose={handleClose}
      submitButtonDisabled={isDisabled}
      handleSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" w="100%" gap={4}>
        <Controller
          name="logType"
          control={control}
          render={({ field }) => (
            <Stack direction="column" gap={3}>
              <FormControl isRequired>
                <FormLabel>Log Type</FormLabel>
                <Grid
                  templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
                  gap={4}
                  width="100%">
                  {logOptions.map((option) => (
                    <HStack
                      w="100%"
                      key={option.label}
                      _hover={{ cursor: "pointer" }}
                      p={3}
                      bg={field.value === option.label ? colorTeal : "chakra-bg-color"}
                      border="1px"
                      borderRadius="md"
                      borderColor={
                        field.value === option.label ? colorTealBorder : "chakra-border-color"
                      }
                      onClick={() => {
                        const logType = option.label;
                        setValue("logType", logType);

                        if (logType === "report_issue") {
                          setValue("maintenanceLogUsers", []);
                          setValue("listOfMaintenanceActionableIds", []);
                        }
                      }}>
                      <Box
                        bgColor={option.colorScheme}
                        color={"white"}
                        padding={"2"}
                        borderRadius={"md"}>
                        {option.icon}
                      </Box>
                      <Text fontSize="sm" fontWeight="medium" w="max-content">
                        {humanize(option.label)}
                      </Text>
                      <Spacer />
                      {field.value === option.label ? (
                        <CheckCircleIcon color={checkColor} boxSize={7} />
                      ) : (
                        <Box
                          border="2px"
                          borderRadius="full"
                          borderColor="chakra-border-color"
                          p={3}
                        />
                      )}
                    </HStack>
                  ))}
                </Grid>
              </FormControl>
            </Stack>
          )}
        />

        <Controller
          name="maintenanceDate"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Date and Time</FormLabel>
              <Input type="datetime-local" {...field} />
            </FormControl>
          )}
        />
        {formValues.logType !== "report_issue" && (
          <Controller
            name="maintenanceActionableType"
            control={control}
            render={({ field }) => (
              <FormControl isRequired>
                <FormLabel>Carried Out By</FormLabel>
                <RadioGroup
                  onChange={(value) => {
                    setValue("maintenanceActionableType", value);
                    setValue("listOfMaintenanceActionableIds", []);
                  }}
                  value={formValues.maintenanceActionableType}
                  colorScheme={"teal"}>
                  <Stack
                    direction={["column", "row"]}
                    align="start"
                    gap={4}
                    alignItems={"center"}
                    width={"100%"}>
                    <Radio value="Vendor">External Vendor</Radio>
                    <Radio value="User">Employees at Science</Radio>
                    {formValues.maintenanceActionableType === "Vendor" && (
                      <Select
                        width={"50%"}
                        placeholder="Choose Vendor"
                        options={vendorOptions}
                        value={formValues.listOfMaintenanceActionableIds[0]?.toString() || ""}
                        onChange={(selectedOption) => {
                          setValue("listOfMaintenanceActionableIds", [Number(selectedOption)]);
                        }}
                      />
                    )}
                    {formValues.maintenanceActionableType === "User" && (
                      <Flex width="50%">
                        <UserSelect
                          alreadyAddedUsers={formValues.maintenanceLogUsers}
                          setValue={(value) => setValue("listOfMaintenanceActionableIds", value)}
                        />
                      </Flex>
                    )}
                  </Stack>
                </RadioGroup>
              </FormControl>
            )}
          />
        )}
        <Controller
          name="note"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Note</FormLabel>
              <RichTextEditor
                showSaveButton={false}
                onChange={(rich, raw) => {
                  field.onChange(rich);
                  setValue("rawText", raw || "");
                }}
              />
            </FormControl>
          )}
        />
      </Flex>
    </FormModal>
  );
};
