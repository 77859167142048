import {
  Spinner,
  Center,
  AlertIcon,
  AlertDescription,
  Alert,
  VStack,
  AlertTitle,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { Chart } from "react-google-charts";
import { useGetProjectDetails } from "../../../api/planning/projects";
import _ from "lodash";
import { EmptyState } from "@sciencecorp/helix-components";

export const ProjectGanttChart = () => {
  const {
    data: projectDetails,
    isLoading: projectDetailsIsLoading,
    isSuccess: projectDetailsIsSuccess,
  } = useGetProjectDetails();

  const columns = [
    { type: "string", label: "Task ID" },
    { type: "string", label: "Task Name" },
    { type: "string", label: "Resource" },
    { type: "date", label: "Start Date" },
    { type: "date", label: "End Date" },
    { type: "number", label: "Duration" },
    { type: "number", label: "Percent Complete" },
    { type: "string", label: "Dependencies" },
    { type: "string", role: "style" },
  ];

  const rows =
    projectDetails?.map((project) => [
      project.id.toString(),
      project.name,
      project.arc_id.toString(),
      new Date(project.start_date),
      new Date(project.end_date),
      null,
      null,
      null,
      project.id < 0 ? "stroke: #ff0000; stroke-width: 2;" : "",
    ]) || [];

  const data = [columns, ...rows];

  const options = {
    height: 800,
    gantt: {
      labelStyle: {
        fontName: "inherit",
        fontSize: 14,
        bold: true,
      },
      criticalPathEnabled: false,
      sortTasks: false,
      trackHeight: 40,
    },
  };

  return projectDetailsIsSuccess ? (
    <>
      <Alert status="info" marginBottom={4}>
        <AlertIcon />
        <VStack gap={0} alignItems="flex-start">
          <AlertTitle>Only shows projects with start and end dates</AlertTitle>
          <AlertDescription>
            If you want to see all projects, please use the list view.
          </AlertDescription>
        </VStack>
      </Alert>
      <Chart
        chartType="Gantt"
        width="100%"
        height="100%"
        options={options}
        data={data}
        chartVersion="current"
        loader={
          <Center>
            <Spinner />
          </Center>
        }
      />
    </>
  ) : (
    <EmptyState title="No projects that you have access to have a start and end date" />
  );
};
