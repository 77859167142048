import { EmptyState, Select } from "@sciencecorp/helix-components";
import {
  Box,
  Grid,
  GridItem,
  HStack,
  Progress,
  Spinner,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { PollData, useGetPollResponses } from "../../../api/polls";
import {
  useBudgetGroupOptionsWithoutSummary,
  useTeamOptions,
  useUserOptions,
} from "../../../api/options";
import { useForm } from "react-hook-form";

type PollResponsesFormValues = {
  group_type: string | null;
  group_id: number | null;
};

export const PollResponses = ({ poll, preview }: { poll: PollData; preview: boolean }) => {
  const optionsPoll = ["Form::Field::SingleSelect", "Form::Field::MultiSelect"];
  const shortAnswerPoll = ["Form::Field::ShortText", "Form::Field::LongText"];
  const defaultValues: PollResponsesFormValues = {
    group_id: null,
    group_type: null,
  };
  const budgetGroupOptions = useBudgetGroupOptionsWithoutSummary();
  const budgetGroupOptionsWithAll = [{ label: "All", value: -1 }, ...budgetGroupOptions];
  const teamOptions = useTeamOptions();
  const teamOptionsWithAll = [{ label: "All", value: -1 }, ...teamOptions];
  const userOptions = useUserOptions();
  const userOptionsWithAll = [
    { label: "All", value: -1 },
    ...userOptions.map((opt) => ({
      ...opt,
      value: Number(opt.value),
    })),
  ];
  const { watch, setValue } = useForm({ defaultValues });
  const bgColor = useColorModeValue("gray.50", "gray.600");

  const formValues = watch();
  const { data: pollResponses, isLoading: isLoadingPollResponses } = useGetPollResponses({
    poll_id: poll.id,
    group_type: formValues.group_id === -1 ? null : formValues.group_type,
    group_id: formValues.group_id === -1 ? null : formValues.group_id,
    preview_response: preview ? poll.preview_response : true,
  });

  const responses = pollResponses?.["responses"] || [];
  const totalResponses = pollResponses?.["number_of_responses"] || 0;

  const renderSelectConfidentiality = (confidentiality: string) => {
    switch (confidentiality) {
      case "identifiable":
        return (
          <HStack width="100%" justifyContent="space-between">
            <Select
              width="30%"
              name="group_id"
              placeholder="Select a user"
              options={userOptionsWithAll}
              onChange={(value) => {
                if (value) setValue("group_id", Number(value));
                setValue("group_type", "user");
              }}
              value={formValues.group_type === "user" ? formValues.group_id : null}
            />
            <Select
              width="30%"
              name="group_id"
              placeholder="Select a team"
              options={teamOptionsWithAll}
              onChange={(value) => {
                if (value) setValue("group_id", Number(value));
                setValue("group_type", "team");
              }}
              value={formValues.group_type === "team" ? formValues.group_id : null}
            />
            <Select
              width="30%"
              name="group_id"
              placeholder="Select a budget group"
              options={budgetGroupOptionsWithAll}
              onChange={(value) => {
                if (value) setValue("group_id", Number(value));
                setValue("group_type", "budget_group");
              }}
              value={formValues.group_type === "budget_group" ? formValues.group_id : null}
            />
          </HStack>
        );
      case "group_based":
        return (
          <Select
            width="50%"
            name="group_id"
            placeholder="Select a budget group"
            options={budgetGroupOptionsWithAll}
            onChange={(value) => {
              if (value) setValue("group_id", Number(value));
              setValue("group_type", "budget_group");
            }}
            value={formValues.group_id}
          />
        );
      case "team_based":
        return (
          <Select
            width="50%"
            name="group_id"
            options={teamOptionsWithAll}
            placeholder="Select a team"
            onChange={(value) => {
              if (value) setValue("group_id", Number(value));
              setValue("group_type", "team");
            }}
            value={formValues.group_id}
          />
        );
      default:
        return <></>;
    }
  };
  return (
    <Box border="1px" borderColor={"chakra-border-color"} p={4} borderRadius="md">
      <Text fontSize="lg" fontWeight="semibold" mb={4}>
        Responses
      </Text>
      {!preview && <Box mb={4}>{renderSelectConfidentiality(poll.confidentiality)}</Box>}
      {isLoadingPollResponses ? (
        <Spinner />
      ) : (
        <Box w="100%" h="100%">
          {optionsPoll.includes(poll.form.active_template?.fields[0]?.type ?? "") ? (
            responses.length > 0 ? (
              responses.map((response) => (
                <HStack width="100%" p={4} key={response.label}>
                  <Text color="gray.500" width="15%">
                    {((response.count / totalResponses) * 100).toFixed(0)}%
                  </Text>
                  <VStack width="100%">
                    <Text fontWeight="semibold" alignSelf="flex-start">
                      {response.label}
                    </Text>
                    <Progress
                      value={(response.count / totalResponses) * 100}
                      w="100%"
                      colorScheme="teal"
                      borderRadius="md"
                    />
                  </VStack>
                </HStack>
              ))
            ) : preview ? (
              <Text>Thank you for submitting your response!</Text>
            ) : (
              <EmptyState title="No responses yet. When employees respond to this poll, you'll see their responses here." />
            )
          ) : shortAnswerPoll.includes(poll.form.active_template?.fields[0]?.type ?? "") ? (
            responses.length > 0 ? (
              <Grid
                overflowY="scroll"
                templateColumns={{
                  base: "1fr",
                  md: "repeat(auto-fit, minmax(200px, 1fr))",
                }}
                gap={4}
                p={[0, 4]}
                width="100%">
                {responses.map((response) => (
                  <GridItem
                    key={response.label}
                    border="1px"
                    width="100%"
                    borderColor="chakra-border-color"
                    borderRadius="md"
                    p={4}
                    bg={bgColor}
                    maxWidth="100%">
                    <Text>{response.label}</Text>
                  </GridItem>
                ))}
              </Grid>
            ) : preview ? (
              <Text>Thank you for submitting your response!</Text>
            ) : (
              <EmptyState title="No responses yet. When employees respond to this poll, you'll see their responses here." />
            )
          ) : (
            <EmptyState title="When employees respond to this poll, you'll see their responses here." />
          )}
        </Box>
      )}
    </Box>
  );
};
