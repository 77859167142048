import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  Alert,
  AlertTitle,
  AlertDescription,
  Flex,
  VStack,
  Button,
  HStack,
  Badge,
  Text,
} from "@chakra-ui/react";
import {
  Header,
  SplitPage,
  Facets,
  useCollection,
  buildFacets,
} from "@sciencecorp/helix-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CandidateTable } from "./components/CandidateTable";
import { AddCandidate } from "./components/AddCandidate";
import { useCandidateRoleOptions } from "../../api/options";
import { useCurrentUserQuery, userHasRole } from "../../api/user";
import { CandidateFeedbackTable } from "./components/CandidateFeedbackTable";
import { useDebouncedSearch } from "../hooks/useDebouncedSearch";
import { useCandidateFeedbacksSearchQuery } from "../../api/candidate_feedback";
import { useCandidatesSearchQuery } from "../../api/candidates";
import { useSearchVacancies } from "../../api/budget_headcounts";
import { VacanciesTab } from "./VacanciesTab";
import { generateHiringCSV } from "../../api/candidates";
import { generateVacancyCSV } from "../../api/budget_headcounts";
import { CsvDownload } from "../shared/csvDownloadButton";
import { useSearchArchetypes } from "../../api/archetype";
import { NewArchetypeModal } from "./Archetypes/NewArchetypeModal";
import { ArchetypesTablePage } from "./ArchetypesPage";
import { MdWorkOutline } from "react-icons/md";
import { RiUserSettingsLine } from "react-icons/ri";
import { FaUserCheck } from "react-icons/fa";

export const Hiring = () => {
  const { tab } = useParams();
  const location = useLocation();
  const candidateRoles = useCandidateRoleOptions();
  const currentUser = useCurrentUserQuery();
  const isRecruitingAdmin = userHasRole(currentUser, "recruiting_admin");
  const isFinanceApprover = userHasRole(currentUser, "finance_approver");
  const isHrAdmin = userHasRole(currentUser, "hr_admin");
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(tab || "candidates");
  const { search, debouncedSearch } = useDebouncedSearch();
  const { pagination, onPagination, order, onOrder, filters, facets, onFacets } = useCollection({
    key: activeTab,
  });

  const tabConfig = useMemo(
    () =>
      [
        {
          label: "Candidates",
          value: "candidates",
          icon: <FaUserCheck />,
        },
        isRecruitingAdmin && {
          label: (
            <HStack>
              <Text>Vacancies</Text>
              <Badge colorScheme="teal">Admin</Badge>
            </HStack>
          ) as JSX.Element,
          value: "vacancies",
          icon: <MdWorkOutline />,
        },
      ].filter((t): t is { label: JSX.Element; value: string; icon: JSX.Element } => t !== false),
    [isRecruitingAdmin, isHrAdmin]
  );

  const queryResult =
    activeTab === "candidates"
      ? isRecruitingAdmin || isFinanceApprover
        ? useCandidatesSearchQuery({
            term: search || "*",
            aggs: ["role", "team", "stage"],
            filters: filters,
            pagination,
          })
        : useCandidateFeedbacksSearchQuery({
            term: search || "*",
            aggs: ["role", "team"],
            filters,
            pagination,
          })
      : activeTab === "roles"
      ? useSearchArchetypes({
          term: search || "*",
          aggs: [],
          pagination,
          filters,
          order,
        })
      : useSearchVacancies({
          term: search || "*",
          aggs: [
            "priority",
            "team_name",
            "project_title",
            "active",
            "hiring_manager",
            "scheduling_coordinator",
          ],
          pagination,
          filters: { ...filters, vacant: true, budgetable_type: "Team" },
          order,
        });

  const { data, isLoading } = queryResult as {
    data: any;
    isLoading: boolean;
  };

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);

  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    }
  }, [tab]);

  const buttons = [
    {
      component: <AddCandidate location="hiring" candidateRoles={candidateRoles} />,
      active: isRecruitingAdmin && activeTab === "candidates",
    },
    { component: <NewArchetypeModal />, active: isRecruitingAdmin && activeTab === "roles" },
    {
      component: (
        <CsvDownload
          title="helix-hiring.csv"
          apiCall={generateHiringCSV}
          buttonName="Download Hiring CSV"
        />
      ),
      active: isRecruitingAdmin && activeTab === "candidates",
    },
    {
      component: (
        <CsvDownload
          title="helix-vacancies.csv"
          apiCall={generateVacancyCSV}
          buttonName="Download Vacancies CSV"
        />
      ),
      active: isRecruitingAdmin && activeTab === "vacancies",
    },
  ];

  return (
    <Box>
      <Header title="Hiring" actions={buttons.filter((b) => b.active).map((b) => b.component)} />

      <Box overflowX="scroll">
        <SplitPage
          sidebarWidth="max-content"
          sidebarWidthXL="max-content"
          sidebar={
            <VStack width={["100%", "100%", "max-content"]} spacing={3}>
              {tabConfig.map((tab) => (
                <>
                  <Button
                    key={tab.value}
                    leftIcon={tab.icon}
                    width="100%"
                    justifyContent="start"
                    colorScheme={activeTab === tab.value ? "teal" : "gray"}
                    bg={activeTab === tab.value ? "teal.500" : "transparent"}
                    onClick={() => {
                      navigate(`/hiring/${tab.value}`);
                      setActiveTab(tab.value);
                    }}>
                    {tab.label}
                  </Button>
                  {tab.value === "vacancies" &&
                    (activeTab === "vacancies" || activeTab === "roles") && (
                      <Button
                        key="roles"
                        value="roles"
                        leftIcon={<RiUserSettingsLine />}
                        bg={activeTab === "roles" ? "teal" : "transparent"}
                        textColor={activeTab === "roles" ? "white" : "default"}
                        width="calc(100% - 20px)"
                        justifyContent="start"
                        marginLeft="20px"
                        onClick={() => {
                          navigate(`/hiring/roles`);
                          setActiveTab("roles");
                        }}>
                        Roles
                      </Button>
                    )}
                </>
              ))}
              <Box display={["flex", "flex", "none"]} alignSelf="end">
                <Facets
                  variant="button"
                  facets={populatedFacets}
                  defaultIndex={[]}
                  onChange={onFacets}
                  search
                  background
                  debouncedSearch={debouncedSearch}
                />
              </Box>
              <Box display={["none", "none", "inline"]} width="max-content" alignSelf="start">
                <Facets
                  defaultIndex={[]}
                  facets={populatedFacets}
                  onChange={onFacets}
                  search
                  background
                  debouncedSearch={debouncedSearch}
                />
              </Box>
            </VStack>
          }
          main={
            <>
              {activeTab === "candidates" &&
                (isRecruitingAdmin || isFinanceApprover ? (
                  <CandidateTable
                    data={data}
                    isLoading={isLoading}
                    pagination={pagination}
                    onPagination={onPagination}
                    order={order}
                    onOrder={onOrder}
                    currentUser={currentUser}
                    isRecruitingAdmin={isRecruitingAdmin}
                  />
                ) : (
                  <CandidateFeedbackTable
                    data={data}
                    isLoading={isLoading}
                    pagination={pagination}
                    onPagination={onPagination}
                    order={order}
                    onOrder={onOrder}
                    currentUser={currentUser}
                  />
                ))}
              {activeTab === "roles" && (
                <ArchetypesTablePage
                  data={data}
                  isLoading={isLoading}
                  pagination={pagination}
                  onPagination={onPagination}
                  order={order}
                  onOrder={onOrder}
                  editable={isHrAdmin || isRecruitingAdmin}
                />
              )}
              {activeTab === "vacancies" && (
                <VacanciesTab
                  data={data}
                  isLoading={isLoading}
                  pagination={pagination}
                  onPagination={onPagination}
                  order={order}
                  onOrder={onOrder}
                />
              )}
            </>
          }
        />
      </Box>
    </Box>
  );
};
