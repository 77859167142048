import { WarningTwoIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Progress,
  Spinner,
  Text,
  Tooltip,
  VStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  AttributesTable,
  EditableDate,
  EditableSelect,
  EditableText,
  Facets,
  Header,
  RecordLink,
  SplitPage,
} from "@sciencecorp/helix-components";
import { DebouncedFunc } from "lodash";
import { default as React, useCallback, useEffect, useState } from "react";
import { MdOutlineStorefront } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteFile } from "../../../api/blob_files";
import {
  invalidateContracts,
  useDeleteContract,
  useGetContract,
  useGetContractPayments,
  useGetContractTimelineEvents,
  useUpdateContract,
  useUpdateContractCurrency,
} from "../../../api/contracts";
import { useVendorOptions } from "../../../api/options";
import {
  useCreatePurchasePayment,
  useDeletePurchasePayment,
  useUpdatePurchasePayment,
} from "../../../api/purchase_payment";
import { TimelineEventData } from "../../../api/timeline_events";
import { useCurrentUserQuery, userHasRole } from "../../../api/user";
import { CurrencyProvider, useCurrency } from "../../../contexts/CurrencyContext";
import { Money } from "../../../helpers/Money";
import { useDebounced } from "../../hooks/useDebouncedUpdate";
import { BlobUploadButton } from "../../shared/BlobUploadButton";
import { DeleteableFileDownload } from "../../shared/DeleteableFileDownload";
import { SpendingAuthorityInfo } from "../../shared/SpendingAuthorityInfo";
import { TimelineTable } from "../../shared/TimelineTable";
import { PurchasePaymentList } from "../Purchase/components/PurchasePayment";
import { EditableSpendingAuthoritySelect } from "../SpendingAuthoritySelectTree";
import { LinkifyText } from "../util";
import { DateTime } from "luxon";
import { BsThreeDotsVertical } from "react-icons/bs";
import { PaymentsSpendingGraph } from "../Vendor/components/PaymentsSpendingGraph";
import { titleize } from "inflection";
import { AcknowledgePayments } from "./components/AcknowledgePayments";
import { UpdateCurrencyButton } from "../../UpdateCurrencyButton";

const commentSelectedTimelineFacets = {
  event_type: {
    options: [
      { key: "comment", value: "comment", selected: true },
      { key: "updated_payment", value: "updated_payment", selected: false },
      { key: "paid_payment", value: "paid_payment", selected: false },
      { key: "archived", value: "archived", selected: true },
    ],
  },
};

const billingPeriodOptions = ["annually", "monthly", "quarterly", "bi-annually"].map((value) => ({
  label: titleize(value),
  value,
}));

export const ContractPage = () => {
  const grayL050D700 = useColorModeValue("gray.50", "gray.700");

  const { id } = useParams();
  const currency = useCurrency();
  const navigate = useNavigate();
  const { data: contract, isLoading, isError, isSuccess } = useGetContract(Number(id));
  const { mutate: updateContract } = useUpdateContract(Number(id));
  const { mutate: deleteContract } = useDeleteContract(() => {
    navigate("/services/contracts");
  });

  const { mutateAsync: updateContractCurrency, isLoading: isLoadingCurrency } =
    useUpdateContractCurrency(Number(id));

  const { data: contractPayments } = useGetContractPayments(Number(id));

  const [timelineFacets, setTimelineFacets] = useState<Facets>(commentSelectedTimelineFacets);

  const [timelineEvents, setTimelineEvents] = useState<TimelineEventData[]>([]);
  const [session, setSession] = useState(0);

  const { mutate: updatePurchasePaymentNonDebounced, isLoading: isLoadingUpdatePurchasePayment } =
    useUpdatePurchasePayment(() => invalidateContracts(Number(id)));
  const { mutate: deletePurchasePaymentNonDebounced } = useDeletePurchasePayment(() =>
    invalidateContracts(Number(id))
  );
  const { mutate: createPurchasePaymentNonDebounced } = useCreatePurchasePayment(() =>
    invalidateContracts(Number(id))
  );
  const { mutate: deleteFile } = useDeleteFile(() => invalidateContracts(Number(id)));
  const eventsQuery = useGetContractTimelineEvents(Number(id));

  const updatePurchasePayment: DebouncedFunc<typeof updatePurchasePaymentNonDebounced> =
    useDebounced(updatePurchasePaymentNonDebounced, [id], 500);
  const deletePurchasePayment: DebouncedFunc<typeof deletePurchasePaymentNonDebounced> =
    useDebounced(deletePurchasePaymentNonDebounced, [id], 500);
  const createPurchasePayment: DebouncedFunc<typeof createPurchasePaymentNonDebounced> =
    useDebounced(createPurchasePaymentNonDebounced, [id], 500);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const currentUserQuery = useCurrentUserQuery();
  const isPurchasingAdmin = userHasRole(currentUserQuery, "purchasing_admin") || false;
  const vendorOptions = useVendorOptions();
  const [isLoadingAcknowledgePayments, setIsLoadingAcknowledgePayments] = useState(false);

  const facetOnChange = useCallback(
    (facets) => {
      setTimelineFacets(facets);
      if (eventsQuery.data) {
        let filteredEvents: TimelineEventData[] = eventsQuery.data;
        for (const facet in facets) {
          const selectedOptions = facets[facet]?.options?.filter((option) => option.selected) ?? [];

          if (selectedOptions.length > 0) {
            const selectedValues = selectedOptions.map((option) => option.value);
            filteredEvents = filteredEvents.filter((event) =>
              selectedValues.includes(event[facet])
            );
          }
        }
        setTimelineEvents(filteredEvents);
      }
    },
    [eventsQuery.data]
  );

  useEffect(() => {
    if (eventsQuery.data) {
      facetOnChange(commentSelectedTimelineFacets);
    }
  }, [eventsQuery.data]);

  if (isLoading)
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    );

  if (isError) {
    return <div>Error loading contract details</div>;
  }

  const canBeArchived = contractPayments?.purchase_payments.every(
    (payment) => payment.status === "paid"
  );

  const menuActions = (
    <>
      {contract.archived_at ? (
        // Show "Restore" if the contract is archived
        <MenuItem
          onClick={() => {
            updateContract({ id: contract.id, archived_at: null });
          }}>
          Restore
        </MenuItem>
      ) : contractPayments?.purchase_payments.length === 0 ? (
        <MenuItem
          onClick={() => {
            deleteContract(contract.id, {
              onSuccess: () => {
                navigate("/services/contracts"); // Navigate away after deleting the contract
              },
            });
          }}>
          Delete Contract
        </MenuItem>
      ) : (
        // Show "Archive" if there are payments but the contract is not archived
        <Tooltip
          isDisabled={canBeArchived}
          label="This contract cannot be archived until all payments are paid">
          <MenuItem
            isDisabled={!canBeArchived}
            onClick={() => {
              updateContract({ id: contract.id, archived_at: DateTime.now().toISO() });
            }}>
            Archive Contract
          </MenuItem>
        </Tooltip>
      )}
    </>
  );

  const isArchived = contract?.archived_at !== null;

  return (
    <>
      <Flex flexDir="column" width="100%" gap={6}>
        {contract.archived_at && (
          <Alert status="error">
            <AlertIcon />
            This contract has been archived.
          </Alert>
        )}
        <AcknowledgePayments
          isOpen={isOpen}
          onClose={onClose}
          contractPayments={contractPayments?.purchase_payments.filter(
            (payment) => payment.status === "paid"
          )}
          contractId={contract.id}
          setIsLoadingAcknowledgePayments={setIsLoadingAcknowledgePayments}
        />
        {contract.budget_group_lead?.id === currentUserQuery.data?.id &&
          contractPayments?.purchase_payments.some(
            (payment) => !payment.acknowledged_at && payment.status === "paid"
          ) && (
            <Alert status="warning" justifyContent="space-between">
              <HStack>
                <AlertIcon />
                <VStack align="start" gap={0}>
                  <AlertTitle> Action Required </AlertTitle>
                  <AlertDescription>You have payment(s) awaiting acknowledgment.</AlertDescription>
                </VStack>
              </HStack>
              <Button
                onClick={onOpen}
                colorScheme="orange"
                isLoading={isLoadingAcknowledgePayments}>
                Acknowledge Payments
              </Button>
            </Alert>
          )}

        <Header
          title={contract.name}
          crumbs={[{ label: "Contracts", url: "/services/contracts" }]}
          crumbsColor="teal.500"
          actions={[
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<Icon as={BsThreeDotsVertical} />}
                size="sm"
                isDisabled={!isPurchasingAdmin}
              />
              <MenuList>{menuActions}</MenuList>
            </Menu>,
          ]}
        />
      </Flex>
      <Box overflowX="scroll">
        <SplitPage
          sidebarWidth="350px"
          sidebarWidthXL="450px"
          breakpoint="md"
          sidebar={
            <VStack width="100%" align="start" spacing={3}>
              <Box
                width="100%"
                p={4}
                overflow="clip"
                border="1px"
                borderRadius="md"
                borderColor="chakra-border-color"
                bg={grayL050D700}>
                <AttributesTable
                  title="Contract Details"
                  attributes={[
                    {
                      label: "ID",
                      value: <Text>{contract.id}</Text>,
                    },
                    {
                      label: "Name",
                      value: (
                        <EditableText
                          defaultValue={contract.name}
                          onSubmit={(value) => {
                            value &&
                              updateContract({
                                id: contract.id,
                                name: value,
                              });
                          }}
                          disabled={!isPurchasingAdmin || isArchived}
                        />
                      ),
                    },
                    {
                      label: "Vendor",
                      value: (
                        <EditableSelect
                          preview={
                            contract.vendor.status === "approved" ? (
                              <RecordLink
                                maxWidth="15ch"
                                type=""
                                identifier={contract.vendor.name}
                                link={`/services/vendors/${contract.vendor.id}`}
                                icon={
                                  <Box ml={3} mt={1}>
                                    <Icon as={MdOutlineStorefront} />
                                  </Box>
                                }
                              />
                            ) : (
                              <Tooltip label="Unapproved Vendor" closeDelay={500}>
                                <Box bg={grayL050D700} borderRadius="md">
                                  <RecordLink
                                    maxWidth="15ch"
                                    buttonVariant={"outline"}
                                    type=""
                                    colorScheme="red"
                                    rightEl={
                                      <Flex>
                                        <WarningTwoIcon ml={2} />
                                      </Flex>
                                    }
                                    identifier={contract.vendor.name}
                                    link={`/services/vendors/${contract.vendor?.id}`}
                                    icon={
                                      <Box ml={3} mt={1}>
                                        <Icon as={MdOutlineStorefront} />
                                      </Box>
                                    }
                                  />
                                </Box>
                              </Tooltip>
                            )
                          }
                          options={vendorOptions || []}
                          selectedValue={contract.vendor.id.toString() || undefined}
                          onSubmit={(value) => {
                            value && updateContract({ id: contract.id, vendor_id: +value });
                          }}
                          disabled={!isPurchasingAdmin || isArchived}
                        />
                      ),
                    },
                    {
                      label: "Currency",
                      value: (
                        <UpdateCurrencyButton
                          total={contract.total_cost}
                          title="Update Currency"
                          model={"Contract"}
                          isDisabled={!isPurchasingAdmin || isArchived}
                          previousCurrency={contract.currency}
                          isLoading={isLoadingCurrency}
                          handleSubmit={(newCurrency: string, convertValues: boolean, onClose) => {
                            updateContractCurrency({
                              id: contract.id,
                              convert_payments: convertValues,
                              currency: newCurrency,
                            }).then(onClose);
                          }}
                        />
                      ),
                    },
                    {
                      label: "Rate",
                      value: (
                        <EditableText
                          multiline
                          defaultValue={contract.rate}
                          onSubmit={(value) => {
                            value && updateContract({ id: contract.id, rate: value });
                          }}
                          disabled={!isPurchasingAdmin || isArchived}
                        />
                      ),
                    },
                    {
                      label: "Renewal Date",
                      value: (
                        <EditableDate
                          defaultValue={contract.renewal_date?.toString() || undefined}
                          onSubmit={(value) => {
                            value &&
                              updateContract({
                                id: contract.id,
                                renewal_date: DateTime.fromISO(value),
                              });
                          }}
                        />
                      ),
                    },
                    {
                      label: "Billing Period",
                      value: (
                        <EditableSelect
                          options={billingPeriodOptions}
                          selectedValue={contract.billing_period}
                          onSubmit={(value) => {
                            value &&
                              updateContract({
                                id: contract.id,
                                billing_period: value.toString(),
                              });
                          }}
                        />
                      ),
                    },
                    {
                      label: "Spending Authority",
                      value: (
                        <EditableSpendingAuthoritySelect
                          onSubmit={(spendingAuthority) => {
                            updateContract({
                              id: contract.id,
                              spending_authority_type: spendingAuthority?.type,
                              spending_authority_id: spendingAuthority?.id,
                            });
                          }}
                          spendingAuthority={contract.spending_authority}
                        />
                      ),
                    },
                  ]}
                />
              </Box>
              <VStack
                align="start"
                gap={4}
                w="100%"
                border="1px"
                p={4}
                borderColor="chakra-border-color"
                borderRadius="md">
                <Heading size="md">Monthly Spend</Heading>
                <PaymentsSpendingGraph filters={{ payment_schedule_id: contractPayments?.id }} />
              </VStack>
            </VStack>
          }
          main={
            <VStack align="start" spacing={6}>
              <Flex gap={4} direction={{ base: "column", lg: "row" }} width="100%">
                <Flex
                  flexDir="column"
                  flex="1"
                  p={6}
                  gap={4}
                  border="1px"
                  borderRadius="md"
                  borderColor="chakra-border-color">
                  <Heading size="md">Details</Heading>
                  <Box w="100%">
                    <EditableText
                      multiline
                      disabled={!isPurchasingAdmin || isArchived}
                      persistentEdit={contract.description.length === 0}
                      defaultValue={contract.description || undefined}
                      preview={
                        <LinkifyText
                          text={contract.description || ""}
                          maxLinkChars={40}
                          maxWidth={"40ch"}
                        />
                      }
                      onSubmit={(value) => {
                        if (value) updateContract({ id: contract.id, description: value });
                      }}
                    />
                  </Box>
                  <VStack align="start">
                    <Heading size="md">Attachments</Heading>
                    <HStack maxW="100%" flexWrap="wrap">
                      {contract.uploaded_files.map((file) => (
                        <DeleteableFileDownload key={file.id} file={file} deleteFile={deleteFile} />
                      ))}
                      <BlobUploadButton
                        fileableColumn={"uploaded_files"}
                        recordId={contract.id}
                        recordType={"Contract"}
                        onSuccessCallback={() => invalidateContracts(contract.id)}
                      />
                    </HStack>
                  </VStack>
                </Flex>

                <SpendingAuthorityInfo
                  spendingAuthority={contract.spending_authority}
                  rootSpendingAuthority={contract.root_spending_authority}
                  session={session}
                  pendingAmount={Money.zero(currency)}
                />
              </Flex>

              <Flex direction="column" width="100%" gap={4}>
                <Flex
                  direction="column"
                  border="1px"
                  borderColor="chakra-border-color"
                  borderRadius="md"
                  p={4}
                  gap={4}>
                  <Heading size="md">Payments</Heading>
                  {contract.total_cost.isPos() && (
                    <VStack width="100%" align="start" pb={3}>
                      <Heading size="sm">
                        {contract.total_paid.format()} /{" "}
                        <Box as="span" fontSize="sm" fontWeight="medium" color="gray.500">
                          {contract.total_cost.format()}
                        </Box>
                      </Heading>
                      <Progress
                        width="100%"
                        size="lg"
                        value={contract.total_paid.cents
                          .div(contract.total_cost.cents)
                          .times(100)
                          .toNumber()}
                        colorScheme="teal"
                      />
                      <Text fontSize="xs">
                        <Box as="span" fontWeight="bold">
                          {contract.number_paid_installments}/{contract.number_total_installments}{" "}
                        </Box>
                        Installments Paid
                      </Text>
                    </VStack>
                  )}
                  <PurchasePaymentList
                    purchasePayments={contractPayments?.purchase_payments}
                    location="contract"
                    contract={contract}
                    paymentScheduleId={contractPayments?.id}
                    isPurchasingAdmin={isPurchasingAdmin}
                    isLoadingUpdatePurchasePayment={isLoadingUpdatePurchasePayment}
                    updatePurchasePayment={updatePurchasePayment}
                    deletePurchasePayment={deletePurchasePayment}
                    createPurchasePayment={createPurchasePayment}
                    setSession={setSession}
                    uploadFileOnSuccess={() => invalidateContracts(contract.id)}
                  />
                </Flex>
                <Box alignSelf="end">
                  <Facets variant="button" facets={timelineFacets} onChange={facetOnChange} />
                </Box>
                {eventsQuery.isSuccess ? (
                  <Box width="100%" maxHeight="100%">
                    <TimelineTable
                      timelineable_id={contract.id}
                      timelineable_type={"Contract"}
                      events={timelineEvents}
                      onComment={invalidateContracts(contract.id)}
                      disableCommentBox={isArchived}
                    />
                  </Box>
                ) : (
                  <Center>
                    <Spinner />
                  </Center>
                )}
              </Flex>
            </VStack>
          }
        />
      </Box>
    </>
  );
};
